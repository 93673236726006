<template>
  <div id='problem'>
    <h2>Lexicographic Permutations</h2>
    <a href="https://projecteuler.net/problem=24">https://projecteuler.net/problem=24</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Permutation">Wiki: permutations</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      Honestly this problem is a lot easier by hand, but if you want to write some code then have at.
    </p>
    <h3>Permutations</h3>
    <p>
      Enumerating and sorting all the permutations directly is a bit of a pain, so we instead want to directly generate
      the answer. This brings us back to combinatorics and the idea of permutations. A permutation of some data is an
      arrangement of values in some order. So if we have 3 distinct things, then they can be ordered in 6 ways (just as
      the problem spells out.)
    </p>
    <p>
      And in general, for a group of <katex-span expr="n" /> objects, there are <katex-span expr="n!" /> different
      permutations thereof. Combining this with lexicographic sorting allows us to quickly find the answer. For example,
      if we fix the leading digit as a "0", then there are <katex-span expr="9! = 362880" /> ways of arranging the
      remaining 9 numbers. Since we want the millionth number, this means that our number cannot start with 0, nor with 1,
      as those would be the lexicographically first numbers but there are only <katex-span expr="2 * 9! = 725760" />
      leading with them.
    </p>
    <p>
      So we can see that the leading digit must be a 2, and we want the <katex-span
        expr="1000000 - 725760 = 274240^{th}" /> number starting with a 2. We can repeat that logic to identify each digit
      in the final answer, being careful to keep track of each digit we've already used.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P024Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

<template>
  <div id='problem'>
    <h2>Cube Digit Pairs</h2>
    <a href="https://projecteuler.net/problem=90">https://projecteuler.net/problem=90</a>
    <h3>Thoughts</h3>
    <p>
      This is apparently the problem with the second fewest solves in the first 100. I'm not sure what the main difficulty
      is, perhaps modeling all the possible squares properly? Taking the direct solution seems to work well enough:
    </p>
    <ol>
      <li>Build all possible dice</li>
      <li>Iterate over pairs of dice</li>
      <li>Count the pairs which can form all the squares</li>
    </ol>
    <p>
      Building all the possible dice can be done in a braindead way with 6 loops, or a permutations class if one has such
      a thing. Perhaps the anathematic idea of writing 6 nested loops is what is throwing off potential solvers? At any
      rate, since there are 10 digits and we only need 6 of them per die, there are only <katex-span
        expr="\binom{10}{6} = 210" /> different dice, which is quite manageable.
    </p>
    <p>
      From there, we have <katex-span expr="\binom{210}{2} = 21945" /> different pairs of dice, which is not a prohibitive
      number to examine. At that point we can put them together to get all the possible face values, or verify that each
      square can be assembled directly by checking for the presence of digits in the dice. We have to do some fiddling
      around to handle the interchangeability of 6 and 9 here, but with either approach there are only a couple of special
      cases.
    </p>
    <h4>Minor aside on 6 and 9</h4>
    <p>
      Now, one might try to be clever and note that since 6 and 9 are interchangeable we do not need to create all
      possible dice with 0 through 9, but instead use 0 through 8 as possible face values and do a little multiplication
      to account for the potential switches. This sounds clever, but we can quickly see by playing around that it is
      possible to have both 6 and 9 on the same die — so modeling this way turns out to be a bit of a headache.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P090Page',
  components: {},
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

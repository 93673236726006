<template>
  <div id='problem'>
    <h2>Almost Equilateral Triangles</h2>
    <a href="https://projecteuler.net/problem=94">https://projecteuler.net/problem=94</a>
    <h3>Thoughts</h3>
    <p>
      First thing to note: since the triangle is isosceles, we can split it into two right triangles. At this point, we
      know that the hypotenuse is an integer, and the area of the full triangle is an integer -- which means that the
      area of the full triangle is <katex-span expr="A = \frac{h*s}{2}" />.
    </p>
    <img src="@/assets/split_triangle.png" />
    <p>
      Suppose that <katex-span expr="\frac{s}{2}" /> is not an integer (that is, suppose <katex-span expr="s" /> is odd).
      Then for <katex-span expr="A" /> to be an integer, we need <katex-span expr="h" /> to be an even integer. But then
      if we apply the Pythagorean theorem, we need <katex-span expr="\frac{s^2}{4} + h^2 = (s \pm 1)^2" />. Clearly the
      left side of this equation is a fraction, while the right is an integer, so we have a contradiction. Therefore
      <katex-span expr="s" /> is even.
    </p>
    <p>
      Following similar logic, we can confirm that <katex-span expr="h" /> must also be an integer. Which means that all
      sides of the right triangle are integers, so we know that we're looking for Pythagorean triples. Furthermore, the
      difference of 1 between base and hypotenuse means that all solutions are primitive (otherwise a side of the
      associated primitive would be fractional).
    </p>
    <p>
      Knowing that is enough for a solution. For a viable brute force approach, we can loop over the possible base
      lengths, consider each possible hypotenuse for the base, then check if the resulting height is an integer. It's a
      pretty slow approach, since we have to go up to one third of a billion for the hypotenuses, but this is <katex-span
        expr="O(N)" /> so it is good enough.
    </p>
    <p>
      We can also be more efficient by generating Pythagorean triples (as we've done previously in <a
        href="/projects/euler/39">problem 39</a> and elsewhere) then filtering for the triangles which work. An even more
      advanced step would be to check if any of the transformations preserve the side length relations we need...
    </p>
  </div>
</template>

<script>

export default {
  name: 'P094Page',
  components: {},
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

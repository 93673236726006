<template>
  <div id='problem'>
    <h2>Sum Square Difference</h2>
    <a href="https://projecteuler.net/problem=6">https://projecteuler.net/problem=6</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Triangular_number">Wiki: Triangular numbers</a></li>
      <li><a href="https://en.wikipedia.org/wiki/Square_pyramidal_number">Wiki: square pyramidal number</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      There's a trivial brute force approach where we directly calculate the sums. Since the numbers only go up to 100
      there's no need to do more than that. However, we've already discussed quickly summing consecutive numbers in <a
        href="/projects/euler/1">problem 1</a>, so we may as well look to see if we can expand on that.
    </p>
    <h3>Direct calculation</h3>
    <p>
      From problem 1, we already know the formula for the sum of consecutive numbers starting from 1:
    </p>
    <katex-div expr='1 + 2 + 3 + ... + n = \displaystyle\sum_{i=1}^n i = \frac{n * (n+1)}{2}' />
    <p>
      Which we can then square to get the second part of the question:
    </p>
    <katex-div expr='\frac{n * (n+1)}{2}^2 = \frac{n^4 + 2*n^3 + n^2}{4}' />
    <p>
      So we now need a closed form of the sum of consecutive squares:
    </p>
    <katex-div expr='\displaystyle\sum_{i=1}^n i^2' />
    <p>
      I always forget this and have to look it up, but it actually works out pretty nicely:
    </p>
    <katex-div expr='\displaystyle\sum_{i=1}^n i^2 = \frac{n * (n+1) * (2n + 1)}{6}' />
    <p>
      And from there we plug and chug. Yet again doable by hand if you so desire:
    </p>
    <katex-div expr='(1 + 2 + 3 + ... + 100)^2 - (1^2 + 2^2 + 3^2 + ... + 100^2)' />
    <br>
    <katex-div expr='= (\frac{100*(100+1)}{2})^2 - \frac{100 * (100+1) * (2*100 + 1)}{6}' />
    <br>
    <katex-div expr='= (\frac{100*101}{2})^2 - \frac{100 * 101 * 201}{6}' />
    <br>
    <katex-div expr='= (5050)^2 - 50*101*67' />
    <br>
    <katex-div expr='= 50 * 101 * (5050 - 67) = 50*101*4983' />
  </div>
</template>

<script>

export default {
  name: 'P006Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}

table {
  text-align: center;
  table-layout: fixed;
  border: 1px solid;
  border-collapse: collapse;
  margin-bottom: 3rem;
}

.skinny {
  width: 5rem;
}

.wide {
  width: 15rem;
}

th,
td {
  border: 1px solid;
}
</style>

<template>
  <div id='problem'>
    <h2>Roman Numerals</h2>
    <a href="https://projecteuler.net/problem=89">https://projecteuler.net/problem=89</a>
    <h3>Thoughts</h3>
    <p>
      It is important to read closely here and verify that the inputs are all valid, and have at most 4 consecutive
      instances of a character. With that in mind, we need to code up the appropriate transformations, apply them to each
      number in the input, then total up the differences in lengths. The valid transformations are:
    </p>
    <table>
      <colgroup>
        <col class="first" />
        <col class="second" />
      </colgroup>
      <tr>
        <th>Long form</th>
        <th>Short form</th>
      </tr>
      <tr>
        <td>IIII</td>
        <td>IV</td>
      </tr>
      <tr>
        <td>VIV</td>
        <td>IX</td>
      </tr>
      <tr>
        <td>XXXX</td>
        <td>XL</td>
      </tr>
      <tr>
        <td>LXL</td>
        <td>XC</td>
      </tr>
      <tr>
        <td>CCCC</td>
        <td>CD</td>
      </tr>
      <tr>
        <td>DCD</td>
        <td>CM</td>
      </tr>
    </table>
    <p>
      And they must be applied in order, as they may cascade — consider the example "VIIII". We could get around this by
      having additional rules to implement such transformations, but the above is sufficient.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P089Page',
  components: {},
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}

table {
  text-align: center;
  table-layout: fixed;
  /* width: 30%; */
  border: 1px solid;
  border-collapse: collapse;
  margin-bottom: 3rem;
}

th,
td {
  border: 1px solid;
}

.first {
  width: 10rem;
}

.second {
  width: 10rem;
}
</style>

<template>
  <div id='problem'>
    <h2>Monopoly Odds</h2>
    <a href="https://projecteuler.net/problem=84">https://projecteuler.net/problem=84</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Markov_chain">Wiki: Markov chain</a></li>
      <li><a href="https://en.wikipedia.org/wiki/Stochastic_matrix">Wiki: stochastic matrix</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      A problem significantly more fun than the game which inspired it! And based on the number of people who have solved
      it, this is probably one of the five to ten hardest problems in the first hundred. The obvious solution is to brute
      force a simulated playthrough of Monopoly, tracking where one winds up at the end of each turn and using the
      frequencies as probabilities. This can work, but is noisy and requires a large number of simulations. Fortunately,
      there is a better way.
    </p>
    <h3>Markov chains</h3>
    <p>
      Things to note about Monopoly and how we move around the board:
    </p>
    <ol>
      <li>There are a finite number of squares.</li>
      <li>Where we move to on a roll depends only on where we started.</li>
      <li>Movement from one square to another has a fixed probability.</li>
    </ol>
    <p>
      This is precisely the definition of a Markov chain on a finite state space. So instead of simulating the game
      directly, we can instead represent the game as a set of transition probabilities. Let's define <katex-span
        expr="P_{i, j}" /> as the probability of moving to square <katex-span expr="j" /> from a roll starting on square
      <katex-span expr="i" />. Then we can put these probabilities into a matrix to represent the transitions, and apply
      that matrix to a vector of position probabilities to find the probability of landing on each square. So the
      transition matrix is:
    </p>
    <katex-div expr="T = 
        \begin{bmatrix}
          P_{0, 0} & \cdots  & P_{0, 39} \\
          \vdots & \ddots & \ldots \\
          P_{39, 0} & \cdots  & P_{39, 39} \\
        \end{bmatrix}" />
    <p>
      We also know that for all <katex-span expr="i" />, <katex-span expr="\sum_{j=0}^{39} P_{i, j} = 1" /> since at each
      turn we move somewhere else on the board. This transition matrix is also known as the stochastic matrix for the
      Markov chain. Since we know that the probabilities of landing on each square will eventually converge (provable from
      the matrix structure, but also if this wasn't the case then we wouldn't have a problem to solve...), we are looking
      for a stationary probability vector (known as <katex-span expr="\pi" /> in this context), which by definition is a
      row eigenvector of <katex-span expr="T" />.
    </p>
    <p>
      There are a few ways to get the eigenvector, but to my mind the simplest (since we have a computer and know this
      will happen), is to repeatedly apply the matrix until it converges (since we know this will happen). This actually
      converges quite rapidly, and we can do it to any vector representing a guess at initial probabilities of being on
      each square.
    </p>
    <h3>Implementation challenges</h3>
    <p>
      With the math theory out of the way, it should be said that the problem description is confusing. Properly
      calculating the transition probabilities is a little tricky as a result. I made a few mistakes when initially
      working on this, so here are some clarifications I hope will help.
    </p>
    <h4>ROLLS not TURNS</h4>
    <p>
      The problem statement is to determine where we are after each roll, not a full turn. This means that if we roll
      doubles, we don't actually care about the subsequent roll: our location after the first roll is what matters for
      transition purposes.
    </p>
    <h4>Chained effects</h4>
    <p>
      However, the effects of cards (Chance or Community Chest) and tiles (go to jail) and triple doubles all factor into
      the final placement. So if we roll to move to a community chest then get sent to GO (tile 0), the stopping place is
      tile 0 rather than the community chest.
    </p>
    <p>
      This is most significant for rolls that send us to tile 36. Tile 36 is a Chance tile, which has a <katex-span
        expr="\frac{1}{16}" /> chance of moving us back 3 tiles to land on tile 33 — a Community Chest tile. So the final
      probability distribution of a roll that sends us to tile 36 needs to account for this chained effect, e.g. the
      <katex-span expr="\frac{1}{16}*\frac{1}{16}=\frac{1}{256}" /> possibility that we get sent to jail from the chest in
      addition to the <katex-span expr="\frac{1}{16}" /> probability of getting sent there immediately by a Chance card.
    </p>
    <h4>Triple double rolls</h4>
    <p>
      Triple doubles are a pain to implement properly. A complete solution would represent the state space by a tuple of
      tile location and number of sequential doubles rolled — increasing the state space from 40 possibilities to 120.
      While reasonable enough to do, it is annoying, particularly since the end behavior is predictable (we go to jail).
    </p>
    <p>
      In practice, I found it easiest to do a hacky simulation of triple doubles. Rather than tracking the number of
      sequential double rolls, I pretended that any time a double is rolled there was a probability that we had already
      rolled two previous doubles prior and should be sent to jail (Monopoly is a terrible board game).
    </p>
    <p>
      So for example, when we are rolling two 6-sided dice, there is a <katex-span expr="\frac{1}{6}" /> chance of rolling
      doubles. Then the probability our prior two rolls were also doubles is <katex-span
        expr="\frac{1}{6}*\frac{1}{6}=\frac{1}{36}" />. So when building my transition matrix, I accounted for it by
      increasing the probability of going to jail, and proportionally reduced all the other possible transition
      probabilities.
    </p>
    <p>
      In practice this worked very well. My predicted final per-tile probabilities were within <katex-span
        expr="0.001\%" /> of the actual probabilities. More than sufficient to get the actual answer.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P084Page',
  components: {}
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

<template>
  <header>
    <h1 class="sage">Sage</h1>
    <p>a.k.a. Mollycat's dad</p>
    <nav>
      <router-link to='/'>Home</router-link>
      <router-link to='/resume'>Résumé</router-link>
      <router-link to='/projects'>Projects</router-link>
      <router-link to='/contact'>Contact</router-link>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'HeaderComponent',
  components: {},
}
</script>

<style scoped>
body {
  margin: 0px;
}

header {
  background: #68c7ece5;
  padding: 1rem;
}

h1.sage {
  margin-top: 1rem;
  margin-bottom: 0.1rem;
}

nav {
  width: 60%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: auto;
  text-align: center;
}

p {
  font-size: 1rem;
  margin-top: 0.1rem;
}

a {
  color: black;
  font-size: 2rem;
}

a:hover {
  text-decoration: none;
  color: dimgray;
}

a:focus {
  text-decoration: none;
  color: gray;
}

a:active {
  text-decoration: none;
  color: black;
}</style>

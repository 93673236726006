<template>
  <div id='problem'>
    <h2>Number Spiral Diagonals</h2>
    <a href="https://projecteuler.net/problem=28">https://projecteuler.net/problem=28</a>
    <h3>Thoughts</h3>
    <p>
      It should be clear after a little playing around that there is a steady progression between the numbers. We can use
      that to calculate the answer efficiently without manually navigating the spiral.
    </p>
    <h3>Implementation</h3>
    <p>
      Looking at this problem, the first thing I noticed is that the top right corner of each square in the spiral is the
      square of an odd number, and the distance between the corners is one less than that odd number. That gives us a
      quick way of getting the four corners of each square. For example, in the second full square with corner 25:
    </p>
    <katex-div expr="25 + 21 + 17 + 13" />
    <katex-div expr="= 5^2 + (5^2 - (5-1)) + (5^2 - 2*(5-1)) + (5^2 - 3*(5-1))" />
    <katex-div expr="= 4*5^2 - 6*(5-1)" />
    <katex-div expr="\implies 4*k^2 - 6*(k-1)" />
    <p>
      Since the corner squares align with the spiral dimensions, we want the sum of these values for all the odd numbers
      up to
      1001:
    </p>
    <katex-div expr="1 + [4*3^2 - 6*2] + [4*5^2 - 6*4] + ... + [4*1001^2 - 6*1000]" />
    <br>
    <katex-div expr="= 1 + [4*(2*1+1)^2 - 6*(2*1)] + ... + [4*(2*500+1)^2 - 6*(2*500)]" />
    <br>
    <katex-div expr="\implies 1 + \displaystyle\sum_{k=1}^{m}\bigg(4*(2k+1)^2 - 6(2k)\bigg)" />
    <br>
    <katex-div expr="= 1 + 4\displaystyle\sum_{k=1}^{m}(4k^2 + k + 1)" />
    <p>
      And from <a href="/projects/euler/6">problem 6</a> we already know the formulas for the sum of consecutive numbers,
      and the sum of consecutive squares. Substituting those in:
    </p>
    <katex-div expr="= 1 + 4\bigg(4\frac{m(m+1)(2m+1)}{6} + \frac{m(m+1)}{2} + m \bigg)" />
    <br>
    <katex-div expr="= 1 + \frac{8m(m+1)(2m+1)}{3} + 2m(m+1) + 4m" />
    <br>
    <katex-div expr="= \frac{8m(m+1)(2m+1)}{3} + 2m^2 + 6m + 1" />
    <p>
      Plug in <katex-span expr="m=500" /> and we're done.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P028Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

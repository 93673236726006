<template>
  <div id='problem'>
    <h2>Coin Partitions</h2>
    <a href="https://projecteuler.net/problem=78">https://projecteuler.net/problem=78</a>
    <h3>Thoughts</h3>
    <p>
      Again, this is <a href="/projects/euler/76">problem 76</a>, but now without a well-define limit. The DP approach
      really doesn't scale well in this case, especially since we don't know the upper bound. Therefore the best approach
      for me was to use the recurrence relation for calculating partitions which was mentioned two problems ago. Kind of
      annoying problem really, where I just dug around until finding the appropriate formula to use.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P078Page',
  components: {},
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

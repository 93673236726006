<template>
  <div id='problem'>
    <h2>Counting Summations</h2>
    <a href="https://projecteuler.net/problem=76">https://projecteuler.net/problem=76</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Partition_function_(number_theory)">Wiki: partition function</a></li>
      <li><a href="https://mathworld.wolfram.com/PartitionFunctionP.html">Wolfram: partition function p</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      This problem is asking us to produce a value of the partition function <katex-span expr="p(n)" />. This is a famous
      function that sneaks its way into many different contexts. If you read about this problem you'll see Euler's name a
      bunch of times as well, which is always fun to see.
    </p>
    <p>
      I see two good ways to solve this: using the recurrence relation defined in Wiki/Wolfram (equation 11 there), or
      taking a classic dynamic programming approach. If we want to treat this as a dynamic programming problem, then the
      problem reduces to a marginally more complicated version of <a href="/projects/euler/31">problem 31</a>. In that
      problem we had to deal with the different coin denominations. In this case we can use the same approach but need to
      pretend that we have coins for every number from 1 to 100.
    </p>
    <!-- TODO: insert animation of the DP, going line by line and highlighting how the summation works. -->
  </div>
</template>

<script>

export default {
  name: 'P076Page',
  components: {},
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

<template>
  <div id='problem'>
    <h2>Digit Cancelling Fractions</h2>
    <a href="https://projecteuler.net/problem=33">https://projecteuler.net/problem=33</a>
    <h3>Thoughts</h3>
    <p>
      Apparently this problem previously didn't specify the fractions have to be less than one, which causes all sorts of
      confusion. The problem is much easier to interpret as currently written.
    </p>
    <p>
      Since we skip the "trivial" cases where there is a 0 digit, and both numerator and denominator are 2 digits, there
      are only <katex-span expr="{81 \choose 2} = 3240" /> possible pairs we need to consider at all, though we can
      actually restrict that a bit further. If we label the digits <katex-span expr="a" />, <katex-span expr="b" />, and
      <katex-span expr="x" /> (the last one being duplicated), we need to consider the following arrangements:
    </p>
    <katex-div expr="\frac{ax}{bx}, \frac{ax}{xb}, \frac{xa}{xb}" />
    <p>
      But we can immediately toss out the third format, as it obviously only works when <katex-span expr="a = b" />. And
      for the first format, we recall that the fraction must reduce, which implies that there exists a positive rational
      <katex-span expr="k" /> such that:
      <!-- Since the "reduced" form should simplify to the same
      result, dividing each fraction by the "reduced" form should yield one. However: -->
    </p>
    <katex-div expr="k(10a + x) = 10b + x, ka = b" />
    <katex-div expr="\implies 10(b) + kx = 10b + x" />
    <katex-div expr="\implies kx = x" />
    <katex-div expr=" \implies k = 1 \lor x = 0" />
    <p>
      But our fraction must be less than 1, so we cannot have <katex-span expr="k=1" />. And we don't care about trivial
      fractions that result from <katex-span expr="x=0" />. So neither of these work for the problem, and we only need to
      worry about the fractions like <katex-span expr="\frac{ax}{xb}" />.
    </p>
    <p>
      So between that and the fact that <katex-span expr="a < b" />, there are actually only <katex-span
        expr="{9 \choose 2} * 7 = 252" /> pairs we need to check, reducing our code nicely:
    </p>
    <highlightjs language="kotlin" :code="loops" />
    <p>
      And for checking that the initial fraction and the "reduced" version have the same ratio, we can compare fraction
      equality in the normal way:
    </p>
    <highlightjs language="kotlin" :code="equality" />
    <p>
      Which only leaves us with combining and reducing the denominator.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P033Page',
  components: {
  },
  data() {
    return {
      loops: `for (a in 1..8) for (b in (a+1)..9) {
  for (x in 1..9) {
    if (x == a || x == b) continue
    // Logic goes here.
  }
}`,
      equality: `fun fractionsAreEqual(a: Int, b: Int, x: Int) =
    (10*a + x)*b == (10*x + b)*a`
    }
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

<template>
  <div id='problem'>
    <h2>Amicable Chains</h2>
    <a href="https://projecteuler.net/problem=95">https://projecteuler.net/problem=95</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Amicable_numbers">Wiki: amicable numbers</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      Similar to <a href="/projects/euler/14">problem 14</a> and <a href="/projects/euler/92">problem 92</a> in that we
      need to make the chains. We can re-use the strategies from there (build the transformation graph, cache
      relationships) here, and the only wrinkle is that we need the sum of the proper divisors of each number. But again,
      we've previously encountered this idea in <a href="/projects/euler/21">problem 21</a>, where we used a sieve to
      calculate the divisor function en masse. Gluing together these parts gives is sufficient.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P095Page',
  components: {},
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

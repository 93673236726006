<template>
  <div id='problem'>
    <h2>Poker Hands</h2>
    <a href="https://projecteuler.net/problem=54">https://projecteuler.net/problem=54</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/List_of_poker_hands">Wiki: list of poker hands</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      All programming no math. A good exercise, but not particularly interesting nor worth discussing here when there are
      plenty of better resources out there for implementing poker hand checks.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P054Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

<template>
  <div id='problem'>
    <h2>Distinct Powers</h2>
    <a href="https://projecteuler.net/problem=29">https://projecteuler.net/problem=29</a>
    <h3>Thoughts</h3>
    <p>Generally speaking, it seems like we have two decisions to make:</p>
    <ol>
      <li>Do we want to use big integers?</li>
      <li>Do we want to generate every value for (a, b) pairs, or just find the duplicates?</li>
    </ol>
    <p>
      For this problem, we can get around the big integers by just keeping track of prime factors and the powers we're
      raising them to rather than multiplying them out.
    </p>
    <p>
      Counting the duplicates directly also isn't too bad, since the set of dupes is bound by the set of numbers which are
      multiplies of one another. E.g. powers of 2 may overlap with powers of 4, 8, 16, 32, and 64, but beyond the first
      few numbers there are only a couple of pairs which may overlap. We can actually enumerate them explicitly:
    </p>
    <table>
      <tr>
        <th><katex-span expr="n" /></th>
        <th>overlapping powers</th>
      </tr>
      <tr>
        <td><katex-span expr="2" /></td>
        <td><katex-span expr="\lbrace 4,8,16,32,64 \rbrace" /></td>
      </tr>
      <tr>
        <td><katex-span expr="3" /></td>
        <td><katex-span expr="\lbrace 9, 27, 81 \rbrace" /></td>
      </tr>
      <tr>
        <td><katex-span expr="4" /></td>
        <td><katex-span expr="\lbrace 8,16,32,64 \rbrace" /></td>
      </tr>
      <tr>
        <td><katex-span expr="5" /></td>
        <td><katex-span expr="\lbrace 25 \rbrace" /></td>
      </tr>
      <tr>
        <td><katex-span expr="6" /></td>
        <td><katex-span expr="\lbrace 36 \rbrace" /></td>
      </tr>
      <tr>
        <td><katex-span expr="7" /></td>
        <td><katex-span expr="\lbrace 49 \rbrace" /></td>
      </tr>
      <tr>
        <td><katex-span expr="8" /></td>
        <td><katex-span expr="\lbrace 16,32,64 \rbrace" /></td>
      </tr>
      <tr>
        <td><katex-span expr="9" /></td>
        <td><katex-span expr="\lbrace 27, 81 \rbrace" /></td>
      </tr>
      <tr>
        <td><katex-span expr="10" /></td>
        <td><katex-span expr="\lbrace 100 \rbrace" /></td>
      </tr>
      <tr>
        <td><katex-span expr="16" /></td>
        <td><katex-span expr="\lbrace 32, 64 \rbrace" /></td>
      </tr>
      <tr>
        <td><katex-span expr="27" /></td>
        <td><katex-span expr="\lbrace 81 \rbrace" /></td>
      </tr>
      <tr>
        <td><katex-span expr="32" /></td>
        <td><katex-span expr="\lbrace 64 \rbrace" /></td>
      </tr>
    </table>
    <p>
      I've included later rows like 27-81 to ensure that cases like <katex-span expr="27^{80} = 81^{60}" /> are
      considered, as these exceed <katex-span expr="3^{100}" /> and <katex-span expr="9^{100}" />. Careful counting can
      quickly identify the duplicates from these sets.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P029Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}

table {
  text-align: center;
  table-layout: fixed;
  /* width: 30%; */
  border: 1px solid;
  border-collapse: collapse;
  margin-bottom: 3rem;
}

th,
td {
  border: 1px solid;
}
</style>

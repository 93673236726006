<template>
  <div id='problem'>
    <h2>Powerful Digit Counts</h2>
    <a href="https://projecteuler.net/problem=63">https://projecteuler.net/problem=63</a>
    <h3>Thoughts</h3>
    <p>
      So we need to figure out which numbers to exponentiate, and then how to actually check them.
    </p>
    <h3>Bounding</h3>
    <p>
      Consider <katex-span expr="10^n" />. Each multiplication by 10 appends a zero at the end of the number, so
      <katex-span expr="10^n" /> always has <katex-span expr="n+1" /> digits. This means that we never need to check
      numbers 10 or greater, and that we can use <katex-span expr="log_{10}" /> to count the number of digits a number has
      (as mentioned in <a href="/projects/euler/25">problem 25</a>).
    </p>
    <p>
      Therefore, we only need to test the numbers 1 through 9 as bases for exponentiating. We're looking for <katex-span
        expr="x^n" /> such that the number has <katex-span expr="n" /> digits, which means that:
    </p>
    <katex-div expr="10^{n-1} \leq x^n < 10^n" />
    <p>
      If we're willing to use big integers that is enough for us to solve, but we can also take the logarithm of each part
      of that inequality to keep the numbers nice and small (note that using <katex-span expr="log_{10}" /> gives us
      number of digits, but the inequalities will be true regardless of our choice of base):
    </p>
    <katex-div expr="log_{10}(10^{n-1}) \leq log_{10}(x^n) < log_{10}(10^n)" />
    <katex-div expr="\implies n-1 \leq n*log_{10}(x) < n" />
    <katex-div expr="\implies 0 = \lfloor n*log_{10}(x) \rfloor - (n-1)" />
    <p>
      So now we have a simple, explicit equation to check that we have the correct number of digits. And since we only
      have to check one-digit numbers and each of those raised to the 1 has one digit, our solution is just two loops: a
      for-loop going over the single-digit numbers, wrapping a while-loop that checks if the equality holds.
    </p>
    <h3>By hand</h3>
    <p>
      After establishing that we only need to check 1 through 9, one could also tackle this problem by punching in the
      powers of those number successively into a calculator and seeing what works. The above equation makes it even
      simpler if we don't want to spend time counting digits. For example, let's look at 7.
    </p>
    <table>
      <colgroup>
        <col class="first" />
        <col class="second" />
        <col class="third" />
      </colgroup>
      <tr>
        <th><katex-span expr="n" /></th>
        <th><katex-span expr="7^n" /></th>
        <th><katex-span expr="n*log_{10}(7) - n" /></th>
      </tr>
      <tr>
        <td><katex-span expr="1" /></td>
        <td><katex-span expr="7" /></td>
        <td><katex-span expr="0.845098" /></td>
      </tr>
      <tr>
        <td><katex-span expr="2" /></td>
        <td><katex-span expr="49" /></td>
        <td><katex-span expr="0.690196" /></td>
      </tr>
      <tr>
        <td><katex-span expr="3" /></td>
        <td><katex-span expr="343" /></td>
        <td><katex-span expr="0.535294" /></td>
      </tr>
      <tr>
        <td><katex-span expr="4" /></td>
        <td><katex-span expr="2401" /></td>
        <td><katex-span expr="0.380392" /></td>
      </tr>
      <tr>
        <td><katex-span expr="5" /></td>
        <td><katex-span expr="16807" /></td>
        <td><katex-span expr="0.225490" /></td>
      </tr>
      <tr>
        <td><katex-span expr="6" /></td>
        <td><katex-span expr="117649" /></td>
        <td><katex-span expr="0.070588" /></td>
      </tr>
      <tr>
        <td><katex-span expr="7" /></td>
        <td><katex-span expr="823543" /></td>
        <td><katex-span expr="-0.084314" /></td>
      </tr>
    </table>
    <p>
      Looking at the column on the right, the values are between 0 and 1 for all numbers where the exponent equals the
      number of digits, until we hit <katex-span expr="7^7" /> where the value goes negative. So for a base of 7, we have
      six solutions. Repeating this for all of 1 through 9 gives us our answer.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P063Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}

table {
  text-align: center;
  table-layout: fixed;
  /* width: 30%; */
  border: 1px solid;
  border-collapse: collapse;
  margin-bottom: 3rem;
}

th,
td {
  border: 1px solid;
}

.first {
  width: 6rem;
}

.second {
  width: 10rem;
}

.third {
  width: 15rem;
}
</style>

<template>
  <div id='moody'>
    <h3>Moody</h3>
    <h4>A personal project to track my mood with daily texts</h4>
    <br>
    <p>
      Shortly after moving to NYC in 2017 I was (1) feeling adrift while settling in and (2) was worrying I'd forget
      how to work with AWS since I was no longer working on it daily. Moody was a small side project of mine to keep
      myself learning about AWS and allow me to track my mood.
    </p>
    <p>
      Everything I'd worked on professionally up to that point either followed a traditional service model or operated on
      a message queue (think AWS SQS) styled processing model. To get exposure to an alternative approach, I opted to go
      fully serverless and see what I could do with Lambda.
    </p>
    <p>
      Despite my inexperience at the time, this wound up being shockingly easy. I was able to quickly throw together a few
      functions for processing response texts, and glued the message triggering/receiving together with a combination of
      Pinpoint and SNS. The biggest hurdle I ran into was getting SMS set up, but the core of the project was done in a
      couple of hours.
    </p>
    <br>
    <figure>
      <img src="@/assets/MoodyFlow.svg" alt="Architecture diagram for Moody showing AWS service dependencies." />
      <figcaption>Architecture diagram for Moody messaging, showing the basic data flow.</figcaption>
    </figure>
    <h4>Update 2023</h4>
    <p>
      There used to be some fun diagrams here graphing my mood over time and showing weekly trends. Buuuut I honestly felt
      kinda weird discussing numeric measures of my daily vibes with strangers so they're no longer available.
    </p>
    <p>
      I stopped collecting data during the pandemic, but it was pretty cool to have this kind of info available for
      self-review. I'd highly recommend using one of the existing tools out there if you want to do the same — if only
      because SMS is way more expensive than it should be through AWS, and it is plenty easy to just write the numbers in
      a journal or spreadsheet.
    </p>
  </div>
</template>

<script>

export default {
  name: 'MoodyPage',
  components: {
  },
}
</script>

<style scoped>
div#moody {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  width: 50%;
  margin-top: 3rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

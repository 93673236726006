<template>
  <div id='problem'>
    <h2>Highly Divisible Triangular Number</h2>
    <a href="https://projecteuler.net/problem=12">https://projecteuler.net/problem=12</a>
    <h3>Thoughts</h3>
    <p>
      Back to factorization. We'll aim to do better than brute force here.
    </p>
    <h3>How many factors?</h3>
    <p>
      The core of this problem is figuring out how many distinct factors an integer has. Similar to primality testing, we
      <em>could</em> identify the factors via trial division, but this is quite costly.
    </p>
    <p>
      It helps to take a step back and think about how we can go from a number to its factors. In general, if we have a
      number n, and we know that 2 divides it, then we can be sure that <katex-span expr="n/2" /> is both an integer and a
      factor of n. 2 isn't special here — what's special is that if we know factors of a number, we can generate other
      factors. Going all the way back to <a href="/projects/euler/3">problem 3</a>, we know that each number has a unique
      prime factorization. We can use these prime factorizations to find out how many factors a number has!
    </p>
    <katex-div expr="n = p_0^{i_0} * p_1^{i_1} * ... * p_k^{i_k} = \prod_{j=1}^k p_j^{i_j}" />
    <p>
      Consider some arbitrary <katex-span expr="p_j" />. If this prime divides into n <katex-span expr="i" /> times,
      then we immediately have j+1 factors of n: <katex-span expr="1, p_i^{1}, p_i^{2}, ..., p_j^{i}" />. Now what happens
      if we consider another prime factor? Then for each multiple of that prime, we can multiply it by each of the factors
      we already found to generate more factors! So if we have <katex-span expr="i+1" /> factors from <katex-span
        expr="p_j" />, and the next prime divides n a total of <katex-span expr="l" /> times, then combining these two we
      have <katex-span expr="(i+1)(l+1)" /> distinct factors of n.
    </p>
    <p>
      From this we have a relationship between the powers of the primes in the prime factorization of n and the total
      number of factors. As a concrete example, consider the number <katex-span expr="120 = 2^3*3^1*5^1" />. It has 12
      factors: <katex-span expr="[1,2,3,4,5,6,8,10,12,15,20,24,30,40,60,120]" />. This tally matches the product of the
      powers (with 1 added to each): <katex-span expr="(3+1) * (1+1) * (1+1) = 4*2*2=16" />. So using the factorization
      definition from before, we can say that the number of factors of n is:
    </p>
    <katex-div expr="\prod_{l=0}^k (i_l + 1)" />
    <p>
      So if we have all the prime factorizations, then we can find the number of factors quickly. At this point we recall
      the sieve of Eratosthenes from <a href="/projects/euler/7">problem 7</a> and <a href="/projects/euler/10">problem
        10</a>. We can apply it here to get a set of primes, and go from there to prime factorizations quite quickly.
      After that, all we need to do is generate the triangular numbers (we can do this iteratively or using the formula
      from <a href="/projects/euler/1">problem 1</a>) and check them until we find our answer.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P012Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

<template>
  <div id='problem'>
    <h2>Pandigital Products</h2>
    <a href="https://projecteuler.net/problem=32">https://projecteuler.net/problem=32</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Pandigital_number">Wiki: pandigital number</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      For this problem, one could take the extremely lazy approach of iterating over all 9-digit numbers and skipping
      everything that isn't pandigital, but that means having to check 900,000,000 numbers. Which isn't the end of the
      world, but it isn't anything to be proud of for this problem.
    </p>
    <p>
      Seems like the main issue is how to generate pandigitals, as checking whether something is pandigital isn't terribly
      hard.
    </p>
    <h3>Generating pandigitals</h3>
    <p>
      There are a total of <katex-span expr="9! = 362880" /> nine-digit pandigitals. A pretty small number, which gives us
      some flexibility. If your language of choice has a permute function, use that. If not, then the main two ways I can
      think of are:
    </p>
    <ol>
      <li>Recursive generation</li>
      <li>Iterative incrementation</li>
    </ol>
    <h4>Recursively</h4>
    <p>
      The recursive approach is straightforward. Following is a rough description, then a non-performant implementation in
      Kotlin (which also doesn't handle duplicates...):
    </p>
    <ol>
      <li>If our input list has zero or one elements, return it.</li>
      <li>For each element in the list:
        <ol>
          <li>Make a recursive call on the remaining elements.</li>
          <li>Prepend or append each of the returned lists with our element.
            <ul>
              <li>
                Note that either prepending or appending works. The choice is a question of how efficient adding to the
                list is, and whether you care about the final output order of the permutations.
              </li>
            </ul>
          </li>
          <li>Add all these lists to our set of permutations</li>
        </ol>
      </li>
      <li>Return all found permutations.</li>
    </ol>
    <highlightjs language="kotlin" :code="recursivelyPermute" />
    <p>
      While this is the simplest to implement, it involves a lot of copies, and runs into scaling problems when there are
      a large number of permutations (few computers will be able to hold 20! lists in memory...)
    </p>
    <h4>Iteratively</h4>
    <p>
      The iterative approach is more complicated, and requires some internal sense of ordering to go from one permutation
      to the successive one. In our case that isn't a problem since there is a natural ordering (numeric) that makes
      sense.
    </p>
    <p>
      The central idea is that we work from the right of the number/list, and find the first digit/entry (call it x) which
      is smaller than all values following it. We then swap the position of x with the least element to x's right which is
      greater than x, and sort everything to the right of x's old position. For a quick example, let's apply this logic a
      couple of times to:
    </p>
    <katex-div expr="123479856" />
    <ol>
      <li>Working from the right, we start with the digit 6. It's at the end, so we can ignore it.</li>
      <li>Second from the right is the digit 5. It is smaller than 6 (the only element right of 5, so we swap them).</li>
      <li>5 is one digit, so it is trivially sorted:</li>
    </ol>
    <katex-div expr="\implies 123479865" />
    <p>Let's look at one more iteration:</p>
    <ol>
      <li>Working from the right, we start with the digit 5. It's at the end, so we can ignore it.</li>
      <li>Second from the right is the digit 6. It is bigger than 5 (the only digit right of 5) so we move on.</li>
      <li>Third from the right is the digit 8. It is bigger than both 5 and 6 so we move on.</li>
      <li>Fourth from the right is the digit 9. It is bigger than 5, 6, and 8 so we move on.</li>
      <li>Fifth from the right is the digit 7. It is smaller than 8 and 9.</li>
      <li>Since 7 has smaller digits to its right, we find the smallest digit bigger than 7 to the right of it. In this
        case, that's 8.</li>
      <li>Swap 8 and 7, giving us <katex-span expr="123489756" />.</li>
      <li>We now sort the numbers right of 8 (5, 6, 7, 9), leaving us with our successor number:</li>
    </ol>
    <katex-div expr="\implies 123485679" />
    <p>
      This approach allows us to bypass the memory overhead associated with the recursive approach, though it is a tad
      complicated. It's generally useful to know how to produce a lexicographic successor, but pretty rare that it is
      needed (if you need to iterate over all permutations but can't store them in memory, the number you have to check is
      likely already computationally problematic).
    </p>
    <h3>Breaking down the pandigital</h3>
    <p>
      An alternative to generating all the pandigitals and decomposing them is to consider all the possible <katex-span
        expr="(a, b, c)" /> which satisfy the problem constraints (that is, <katex-span expr="ab = c" />, and between them
      we have each digit 1-9 once).
    </p>
    <p>
      Going this way requires a little thought as to the limits on each of <katex-span expr="a" />, <katex-span
        expr="b" />, and <katex-span expr="c" />, though we should be considering those limits when analyzing the
      generated pandigitals as well. With a little thought, we can quickly see that:
    </p>
    <ol>
      <li><katex-span expr="c" /> must be the number with the most digits, so three or more.</li>
      <li><katex-span expr="c" /> cannot have three digits, else <katex-span expr="a*b" /> would have at least five
        digits.</li>
      <li><katex-span expr="c" /> cannot have six or more digits, else <katex-span expr="a*b" /> would have at most three
        digits.</li>
    </ol>
    <p>
      So <katex-span expr="c" /> is a four or five digit number. Either way, both of the other numbers may be between one
      and four digits.
    </p>
    <p>
      With that in mind, <katex-span expr="2 \leq a \leq 9876" /> and <katex-span expr="a \lt b \leq 98765 \div a" />. We
      are able to limit the second number's range because we know the maximum value of the third number, and we know that
      the first two must multiply to equal the third. This opens up another approach for us, wherein we loop over the
      possible values of <katex-span expr="a, b" /> with these limits and check if the resulting triple satisfies our
      requirements.
    </p>
    <h4>Aside: checking if a number is pandigital</h4>
    <p>
      There are a few obvious ways to do this, but I really like the idea from kevingong in the solution thread. I won't
      spoil it, and you should go look at it once you've solved the problem. Especially since there are a number of
      problems ahead using pandigitals.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P032Page',
  components: {
  },
  data() {
    return {
      recursivelyPermute: `fun recursivelyPermute(xs: List<Any>): List<List<Any>> {
if (xs.size <= 1) return listOf(xs)
val permutations = mutableListOf<List<Any>>()
for (x in xs) {
  val others = xs.filter { it != x }
  val subpermutations = recursivelyPermute(others)
  permutations.addAll(subpermutations.map { it + x })
}
return permutations`
    }
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

<template>
  <div id='problem'>
    <h2>Largest Prime Factor</h2>
    <a href="https://projecteuler.net/problem=3">https://projecteuler.net/problem=3</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Prime_number">Wiki: prime number</a></li>
      <li><a href="https://en.wikipedia.org/wiki/Composite_number">Wiki: composite number</a></li>
      <li><a href="https://en.wikipedia.org/wiki/Integer_factorization">Wiki: integer factorization</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      Quick demo: click on a box in the grid to see which numbers are prime factors (in green) or composite factors (light
      blue).
    </p>
    <canvas id="factorization" width="575" height="575"></canvas>
    <p>
      Time for an intro to primes and factorization. If a number is not prime, it is composite. If it is composite, then
      it has a unique prime factorization.
    </p>
    <katex-div expr="n = p_0^{i_0} * p_1^{i_1} * p_2^{i_2} * ... * p_k^{i_k}" />
    <p>
      So this problem is asking us to find the largest <katex-span expr="p_j" /> for <katex-span expr="600851475143" />.
      So what's the best way to go about finding this?
    </p>
    <h3>Process</h3>
    <p>
      The obvious approach is to attempt to divide our starting number by every integer from 2 on up, and return the
      largest one which divides it evenly and is prime. Since our number is 12 digits long, this will take a bit of time.
      It also leaves us with the issue of determining which numbers are prime, though that can be easily checked using the
      same approach (see if none of two through the prime minus one divide the prime).
    </p>
    <p>
      This seems like a bit of a hassle, let's focus on checking primality first. For now, the simplest way to check
      primality is still through trial division: divide <katex-span expr="p" /> by <katex-span expr="2,3,...,p-1" /> and
      verify they all leave a remainder.
    </p>
    <highlightjs language="kotlin" :code="is_prime" />
    <h4>Digression: optimizing the <katex-span expr="isPrime" /> function</h4>
    <p>
      This code already has one optimization (exiting immediately if we find a factor of the input). Though it could be
      improved further. Consider some <katex-span expr="q" /> such that <katex-span expr="\frac{p}{2} < q < p" />. Then
      clearly <katex-span expr="2 * q > p" />, so <katex-span expr="q" /> cannot be a factor. So that means we don't have
      to check any numbers which are more than half of our input.
    </p>
    <p>
      But we can extend that idea further! When <katex-span expr="q | p" /> we know for sure that <katex-span
        expr="\frac{p}{q}" /> is an integer, and that <katex-span expr="\frac{p}{q}" /> must also be a factor of p. From
      this we know that factors come in pairs, so we only need to check up to the max possible value of the smaller part
      of the pairs. The smaller part is maximized when the elements of the pair are equal, which occurs
      at <katex-span expr="\sqrt{p}" />. Therefore, we only need to check if our input is divisible by any of
      <katex-span expr="2, 3, ..., \lfloor\sqrt{p}\rfloor" />.
    </p>
    <h3>Idea: reducing the input</h3>
    <p>
      Going back to our original problem, we want to find the single largest prime factor of a number. But if we check
      each number less than it, we'll wind up with a large pool we'll have to filter down. It'd be great if we could (1)
      know immediately if a number is prime and discard it or (2) figure out early if there are no numbers we need to
      check. Fortunately for us, we can do both of those things! Let's go back to our factorization:
    </p>
    <katex-div expr="n = p_0^{i_0} * p_1^{i_1} * p_2^{i_2} * ... * p_k^{i_k}" />
    <p>
      And let's say for simplicity that for all <katex-span expr="i, j" /> such that <katex-span expr="i < j" /> we have
      <katex-span expr="p_i < p_j" />. Then <katex-span expr="p_k" /> is the largest prime factor. But more importantly,
      we can see that both <katex-span expr="n = p_0^{i_0} * p_1^{i_1} * p_2^{i_2} * ... * p_k^{i_k}" /> and <katex-span
        expr="m = p_1^{i_1} * p_2^{i_2} * ... * p_k^{i_k}" /> have the same largest prime factor. Removing the other prime
      factors does not affect our answer!
    </p>
    <p>
      We can exploit this. Each time we find a prime number <katex-span expr="p" /> that divides <katex-span expr="n" />,
      we can divide by that factor to simplify our problem. Then whenever we find a number that divides our input until
      we're left with <katex-span expr="1" />, we're done! Let's walk through an example:
    </p>
    <katex-div expr="252 = 2^2 * 3^2 * 7" />
    <p>We first check if two is a factor. Since it is, we divide:</p>
    <katex-div expr="252 \div 2 = 126" />
    <p>Still divisible, so we repeat:</p>
    <katex-div expr="126 \div 2 = 63" />
    <p>Two is no longer divisible, so we move onto three:</p>
    <katex-div expr="63 \div 3 = 21" />
    <katex-div expr="21 \div 3 = 7" />
    <p>Again, done with three. We then iterate a few more times, finding that 4, 5, 6 don't go in, but 7 does!</p>
    <katex-div expr="7 \div 7 = 1" />
    <p>
      And at this point we're done. Note that in doing this, the divisions we performed directly encoded the prime
      factorization (2 twos, 2 threes, 1 seven), and we were able to stop as soon as we found our answer. Significantly,
      while 4 and 6 are factors of 252, we didn't need to take any action for them since we had already factored out the
      prime factors (2 and 3) which comprise them. This idea that we can use smaller primes to filter composites and
      thereby reduce the set of numbers we need to scan undergirds prime sieving algorithms, which will definitely come up
      later.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P003Page',
  components: {
  },
  data() {
    return {
      rectDim: 50,
      padding: 5,
      is_prime: `// Returns true IFF the input number is prime.
def isPrimeImperative(n: Int): Boolean {
  if (n < 2) return false
  if (n == 2) return true
  for (i in 2 until n) {
    if (n % i == 0) return false
  }
  return true
}

def isPrimeFunctional(n: Int): Boolean {
  return n == 2 || (n > 2 && (2 until n).none { n % it == 0 })
}`,
    };
  },
  mounted() {
    var canvas = document.getElementById("factorization");
    var ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, 575, 575);
    ctx.font = "20px Courier New";
    ctx.textAlign = "center";

    var x = 10;
    var y = x;
    let boxes = []
    for (let row = 1; row <= 10; row++) {
      for (let col = 1; col <= 10; col++) {
        let r = new Path2D();
        let coords = { x: x + this.padding, y: y + this.padding };
        r.rect(coords.x, coords.y, this.rectDim, this.rectDim);
        ctx.stroke(r);
        let num = (10 * (row - 1) + col);
        ctx.strokeText(num.toString(), coords.x + 25, coords.y + 32, this.rectDim);
        boxes.push({ rect: r, num: num, coords: coords });

        x += this.rectDim + this.padding;
      }
      x = 10;
      y += this.rectDim + this.padding;
    }

    // Set up a single event listener to redraw the grid, highlighting the box which is hovered over
    // and all multiples of the hovered box's number.
    canvas.addEventListener('click', (e) => {
      var ctx = canvas.getContext("2d");
      ctx.font = "20px Courier New";
      ctx.textAlign = "center";
      ctx.clearRect(0, 0, 575, 575);
      // Is this loop a performance problem? I have to imagine adding more event listeners would be
      // worse than iterating over the set of boxes. Though we are blocking UI?
      const highlit = new Set();
      for (let i = boxes.length - 1; i >= 0; i--) {
        const box = boxes[i];
        // Note that we want the offset from the canvas, not the absolute position.
        if (ctx.isPointInPath(box.rect, e.offsetX, e.offsetY)) {
          for (let j = 0; j < i; j++) {
            if (box.num % boxes[j].num == 0) {
              highlit.add(j);
            }
          }
          ctx.fillStyle = 'aqua';
          ctx.fill(box.rect);
        } else if (highlit.has(i)) {
          ctx.fillStyle = 'green';
          for (let p = 2; p < i + 1; p++) {
            if ((i + 1) % p == 0) {
              ctx.fillStyle = 'lightblue';
            }
          }
          if (i == 0) {
            ctx.fillStyle = 'lightblue';
          }
          ctx.fill(box.rect);
        } else {
          ctx.stroke(box.rect);
        }
        ctx.strokeText(box.num.toString(), box.coords.x + 25, box.coords.y + 32, this.rectDim);
      }
    });
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}

table {
  text-align: center;
  table-layout: fixed;
  width: 30%;
  border: 1px solid;
  border-collapse: collapse;
  margin-bottom: 3rem;
}

th,
td {
  border: 1px solid;
}</style>

<template>
  <div id='problem'>
    <h2>Totient Permutation</h2>
    <a href="https://projecteuler.net/problem=70">https://projecteuler.net/problem=70</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Euler%27s_totient_function">Wiki: Euler's totient function</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      Continuing on from <a href="/projects/euler/69">problem 69</a> we have another problem about the totient function.
      In this case, however, we're looking to minimize rather than maximize the function <katex-span
        expr="\frac{n}{\phi(n)}" />.
    </p>
    <p>
      In the prior problem we found that the function is maximized when <katex-span expr="n" /> has many distinct prime factors.
      Conversely, the function will be minimized when there are as few prime factors as possible. Our answer cannot be
      prime itself, since then all numbers less than it are relatively prime, so <katex-span expr="\phi(n) = n-1" /> which
      will never be a permutation.
    </p>
    <p>
      Therefore we need there to be at least two primes which are factors of <katex-span expr="n" /> (ideally just two).
      And obviously, we'd like those prime factors to be as large as possible so that <katex-span expr="\phi(n)" /> is
      maximized. So in an ideal world, we want <katex-span expr="n = p_1 * p_2" /> where both primes are right around
      <katex-span expr="\sqrt{10^7}" />.
    </p>
    <p>
      This gives us a simple strategy to generate possibilities quickly:
    </p>
    <ol>
      <li>Generate all primes up through <katex-span expr="k * \sqrt{10^7} = 1000k\sqrt{10}" />.</li>
      <li>Multiply pairs of these primes from the largest on down.</li>
      <li>Filter for <katex-span expr="\phi(n)" /> which are permutations of <katex-span expr="n" />.</li>
      <li>Keep track of the largest.
        <ul>
          <li>Break out when products are less than the largest so far.</li>
        </ul>
      </li>
    </ol>
    <p>
      This approach means we don't have to generate quite as many primes so our sieve will be very quick, and our
      conditions are well set to find the answer quickly so we can exit fast.
    </p>
    <h3>Alternative approach</h3>
    <p>
      Rather than doing trial based generation for the best case scenario, a more thorough approach would be to quickly
      calculate <katex-span expr="\phi(n)" /> for all numbers in range. This is doable using a modified sieve approach, as
      discussed in <a href="/projects/euler/47">problem 47</a>. Rather than eliminating multiples, we keep an array of
      lists of prime factors for each number. Then once the list is populated we use it to calculate the totient function
      as we did in the prior problem.
    </p>
    <p>
      This will be slower than the other approach but will be more complete, and requires no assumptions about the
      structure of the answer. It likely will take a few seconds to a minute on most hardware.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P070Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

<template>
  <div id='background'>
    <h3>Guides</h3>
    <table>
      <tr>
        <td><a href="/projects/euler/1">1</a></td>
        <td><a href="/projects/euler/2">2</a></td>
        <td><a href="/projects/euler/3">3</a></td>
        <td><a href="/projects/euler/4">4</a></td>
        <td><a href="/projects/euler/5">5</a></td>
        <td><a href="/projects/euler/6">6</a></td>
        <td><a href="/projects/euler/7">7</a></td>
        <td><a href="/projects/euler/8">8</a></td>
        <td><a href="/projects/euler/9">9</a></td>
        <td><a href="/projects/euler/10">10</a></td>
      </tr>
      <tr>
        <td><a href="/projects/euler/11">11</a></td>
        <td><a href="/projects/euler/12">12</a></td>
        <td><a href="/projects/euler/13">13</a></td>
        <td><a href="/projects/euler/14">14</a></td>
        <td><a href="/projects/euler/15">15</a></td>
        <td><a href="/projects/euler/16">16</a></td>
        <td><a href="/projects/euler/17">17</a></td>
        <td><a href="/projects/euler/18">18</a></td>
        <td><a href="/projects/euler/19">19</a></td>
        <td><a href="/projects/euler/20">20</a></td>
      </tr>
      <tr>
        <td><a href="/projects/euler/21">21</a></td>
        <td><a href="/projects/euler/22">22</a></td>
        <td><a href="/projects/euler/23">23</a></td>
        <td><a href="/projects/euler/24">24</a></td>
        <td><a href="/projects/euler/25">25</a></td>
        <td><a href="/projects/euler/26">26</a></td>
        <td><a href="/projects/euler/27">27</a></td>
        <td><a href="/projects/euler/28">28</a></td>
        <td><a href="/projects/euler/29">29</a></td>
        <td><a href="/projects/euler/30">30</a></td>
      </tr>
      <tr>
        <td><a href="/projects/euler/31">31</a></td>
        <td><a href="/projects/euler/32">32</a></td>
        <td><a href="/projects/euler/33">33</a></td>
        <td><a href="/projects/euler/34">34</a></td>
        <td><a href="/projects/euler/35">35</a></td>
        <td><a href="/projects/euler/36">36</a></td>
        <td><a href="/projects/euler/37">37</a></td>
        <td><a href="/projects/euler/38">38</a></td>
        <td><a href="/projects/euler/39">39</a></td>
        <td><a href="/projects/euler/40">40</a></td>
      </tr>
      <tr>
        <td><a href="/projects/euler/41">41</a></td>
        <td><a href="/projects/euler/42">42</a></td>
        <td><a href="/projects/euler/43">43</a></td>
        <td><a href="/projects/euler/44">44</a></td>
        <td><a href="/projects/euler/45">45</a></td>
        <td><a href="/projects/euler/46">46</a></td>
        <td><a href="/projects/euler/47">47</a></td>
        <td><a href="/projects/euler/48">48</a></td>
        <td><a href="/projects/euler/49">49</a></td>
        <td><a href="/projects/euler/50">50</a></td>
      </tr>
      <tr>
        <td><a href="/projects/euler/51">51</a></td>
        <td><a href="/projects/euler/52">52</a></td>
        <td><a href="/projects/euler/53">53</a></td>
        <td><a href="/projects/euler/54">54</a></td>
        <td><a href="/projects/euler/55">55</a></td>
        <td><a href="/projects/euler/56">56</a></td>
        <td><a href="/projects/euler/57">57</a></td>
        <td><a href="/projects/euler/58">58</a></td>
        <td><a href="/projects/euler/59">59</a></td>
        <td><a href="/projects/euler/60">60</a></td>
      </tr>
      <tr>
        <td><a href="/projects/euler/61">61</a></td>
        <td><a href="/projects/euler/62">62</a></td>
        <td><a href="/projects/euler/63">63</a></td>
        <td><a href="/projects/euler/64">64</a></td>
        <td><a href="/projects/euler/65">65</a></td>
        <td><a href="/projects/euler/66">66</a></td>
        <td><a href="/projects/euler/67">67</a></td>
        <td><a href="/projects/euler/68">68</a></td>
        <td><a href="/projects/euler/69">69</a></td>
        <td><a href="/projects/euler/70">70</a></td>
      </tr>
      <tr>
        <td><a href="/projects/euler/71">71</a></td>
        <td><a href="/projects/euler/72">72</a></td>
        <td><a href="/projects/euler/73">73</a></td>
        <td><a href="/projects/euler/74">74</a></td>
        <td><a href="/projects/euler/75">75</a></td>
        <td><a href="/projects/euler/76">76</a></td>
        <td><a href="/projects/euler/77">77</a></td>
        <td><a href="/projects/euler/78">78</a></td>
        <td><a href="/projects/euler/79">79</a></td>
        <td><a href="/projects/euler/80">80</a></td>
      </tr>
      <tr>
        <td><a href="/projects/euler/81">81</a></td>
        <td><a href="/projects/euler/82">82</a></td>
        <td><a href="/projects/euler/83">83</a></td>
        <td><a href="/projects/euler/84">84</a></td>
        <td><a href="/projects/euler/85">85</a></td>
        <td><a href="/projects/euler/86">86</a></td>
        <td><a href="/projects/euler/87">87</a></td>
        <td><a href="/projects/euler/88">88</a></td>
        <td><a href="/projects/euler/89">89</a></td>
        <td><a href="/projects/euler/90">90</a></td>
      </tr>
      <tr>
        <td><a href="/projects/euler/91">91</a></td>
        <td><a href="/projects/euler/92">92</a></td>
        <td><a href="/projects/euler/93">93</a></td>
        <td><a href="/projects/euler/94">94</a></td>
        <td><a href="/projects/euler/95">95</a></td>
        <td><a href="/projects/euler/96">96</a></td>
        <td><a href="/projects/euler/97">97</a></td>
        <td><a href="/projects/euler/98">98</a></td>
        <td><a href="/projects/euler/99">99</a></td>
        <td><a href="/projects/euler/100">100</a></td>
      </tr>
    </table>
    <h3>About</h3>
    <p>
      <a href="https://projecteuler.net">Project Euler</a> is a longstanding series of math and
      programming challenges. The questions start simple and build in difficulty, and frequently
      provide an accessible, motivating introduction to particular branches of math. Check out the
      "About" page on the actual site for all the details, but a key point to keep in mind is that
      solution programs should aim to take less than a minute to complete on any reasonable hardware.
    </p>
    <p>
      I started doing Project Euler as a way to learn Java way back in 2012, and have repeatedly
      cycled through forgetting about it, rediscovering it for a month to solve a few problems,
      then forgetting it again. I usually use it as a way to learn a new language, or as a source
      of fun challenges.
    </p>
    <p>
      At this point I've solved a little over 250 problems. Since the goal of the project is to
      allow people to discover and learn fun branches of math, publishing solutions is verboten.
      However, there is an exception for the first 100 problems if you focus on methods instead of
      giving people an easy way to cheat (themselves of a lot of fun) by copy-pasting code or
      answers. To that end, this is (<i>yet another</i>) attempt to instruct those interested in
      learning methods for solving the first 100! I've most recently worked on them in Kotlin, so
      that's what you'll see in the code snippets.
    </p>
    <p>
      I started uploading these in early 2023, so if an explanation isn't up yet I'll hopefully get to it soon.
    </p>
    <img alt="Sage's ProjectEuler profile (SageEuler)" src="https://projecteuler.net/profile/SageEuler.png" />
  </div>
</template>

<script>

export default {
  name: 'PEBackgroundPage',
  components: {
  },
}
</script>

<style scoped>
div#background {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}

td {
  width: 3rem;
  text-align: center;
}
</style>

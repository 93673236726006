<template>
  <div id='problem'>
    <h2>Quadratic Primes</h2>
    <a href="https://projecteuler.net/problem=27">https://projecteuler.net/problem=27</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Lucky_numbers_of_Euler">Wiki: lucky numbers of Euler</a></li>
      <li>
        <a href="https://mathworld.wolfram.com/Prime-GeneratingPolynomial.html">Wolfram: prime-generating polynomial</a>
      </li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      The Wolfram link above calls out the link between the two polynomials given in the problem description, which is
      interesting. I didn't really know what to make of it, so I didn't take advantage personally. For me, this came down
      to brute forcing it with some small optimizations.
    </p>
    <h3>Restricting possibilities</h3>
    <p>
      As a starting point, since the sequence of primes starts at n=0, we know that b must be prime. If we want to go back
      to basic test guessing strategies, the fact that a and b are allowed to be negative but b is positive means we
      should expect a to be negative. Since there are a bit under 200 primes less than 1000, these two points cut the
      possibilities to check from 4,000,000 to 200,000.
    </p>
    <p>
      Since we need to check primes and we want b to be prime, we can also speed things up by generating a set of primes
      (with a sieve) to work from. Combining the range restrictions above with that easily gets us to a sub one second
      solution.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P027Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

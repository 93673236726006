<template>
  <div id='problem'>
    <h2>Arranged Probability</h2>
    <a href="https://projecteuler.net/problem=100">https://projecteuler.net/problem=100</a>
    <h3>Thoughts</h3>
    <p>
      Ending with a whimper here, when I solved this the first time I did a little algebra to narrow down possibilities,
      then generated some initial solutions, and looked at them to come up with a recurrence. This brings me great shame
      as there are some really cool ideas underlying this problem — it is very much worth looking through the solution
      thread. Anyway, I narrowed down possibilities like so:
    </p>
    <katex-div expr="\frac{b}{t}\frac{b-1}{t-1} = 1/2" />
    <br>
    <katex-div expr="\implies \frac{b(b-1)}{t(t-1)} = 1/2" />
    <br>
    <katex-div expr="\implies 2b(b-1) = t(t-1)" />
    <br>
    <katex-div expr="\implies 0 = t^2 - t - 2b(b-1) " />
    <p>
      Which is a quadratic equation, so we can use the quadratic formula, and find that the solutions occur when:
    </p>
    <katex-div expr="t = \frac{1 \pm \sqrt{1 + 8b(b-1)}}{2}" />
    <p>
      And since we want <katex-span expr="t" /> to be an integer, that means that the part under the radical must be a
      square. Therefore we can quickly iterate through the small numbers and test numbers to see which ones are usable
      numerators, along with the associated denominator. I then examined these fractions and found a recurrence to
      iteratively generate valid results until I hit the answer.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P100Page',
  components: {},
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

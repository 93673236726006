<template>
  <div id='content'>
    <h3>Projects</h3>
    <p>
      I don't write much code outside of work, usually restricting myself to math/puzzle solving projects and some
      personal ergonomic tools. But I enjoyed working on these and am happy to discuss them.
    </p>
    <br>
    <a href="/projects/euler">Project Euler Explanations</a>
    <br>
    <a href="https://github.com/sageprice/puzzles/tree/master/src/adventofcode">Advent of Code solutions (Github)</a>
    <br>
    <a href="/projects/moody">Moody: SMS-based mood tracking</a>
  </div>
</template>

<script>

export default {
  name: 'ProjectsPage',
  components: {
  },
}
</script>

<style scoped>
div#content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

<template>
  <div id='problem'>
    <h2>Consecutive Prime Sum</h2>
    <a href="https://projecteuler.net/problem=50">https://projecteuler.net/problem=50</a>
    <h3>Thoughts</h3>
    <p>
      There is a potential pitfall here where one could assume that the answer starts at the smallest prime (2). While
      this is likely a safe assumption, it isn't guaranteed, so a fully rigorous solution would need to consider ranges
      starting at 3, 5, 7, etc.
    </p>
    <p>
      But at the end of the day this is once again a case where we should use a sieve to generate all the primes in range
      (at minimum, up to 1000000 / 7 so our sum won't exceed the limit) and then use the generated list of primes to speed
      things up.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P050Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

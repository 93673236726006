<template>
  <div id='problem'>
    <h2>Prime Summations</h2>
    <a href="https://projecteuler.net/problem=77">https://projecteuler.net/problem=77</a>
    <h3>Thoughts</h3>
    <p>
      This is the exact same as <a href="/projects/euler/76">the previous problem</a>, but with a restriction on what
      numbers can go in the sum. Again, the solution from <a href="/projects/euler/31">problem 31</a> generalizes nicely
      — this time we build the list of primes, and use those as our "coins". Easy peasy.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P077Page',
  components: {},
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

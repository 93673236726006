<template>
  <div id='problem'>
    <h2>Coded Triangle Numbers</h2>
    <a href="https://projecteuler.net/problem=42">https://projecteuler.net/problem=42</a>
    <h3>Thoughts</h3>
    <p>
      Not much to say here. We can pre-calculate the triangle numbers and stick them in a set to check word values
      against. Pretty mechanical problem though, nothing interesting to note from a math perspective.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P042Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

<template>
  <div id='problem'>
    <h2>Large sum</h2>
    <a href="https://projecteuler.net/problem=13">https://projecteuler.net/problem=13</a>
    <h3>Thoughts</h3>
    <p>
      More a programming puzzle than a math one, which is not the case for later problems. Many approaches possible here,
      let's go through a few.
    </p>
    <h3>Lazy approach: BigInts</h3>
    <p>
      Most modern languages have some class that supports arbitrarily large integers. Python is great with these since it
      doesn't constrain number size, but in Kotlin and Java we have the less-good but still usable BigInteger class. Using
      such classes immediately trivializes the problem. Note that in general it may not — these just happen to be pretty
      small numbers as far as "big" ints go.
    </p>
    <h3>Sliding window</h3>
    <p>
      As with <a href="/projects/euler/8">problem 8</a>, we can imagine a sliding window that only keeps track of the
      required number of digits. With this mental model, we can add by digit columns rather than adding the entire number,
      dropping the trailing digits as the total digit count in our total exceeds 10.
    </p>
    <p>
      This is a nicely scalable approach, with the only real complexity keeping track of how many powers of ten we've
      dropped so we know where to add each column of digits as we progress.
    </p>
    <h3>Analysis</h3>
    <p>
      We can also consider the parameters of the problem itself in our solution. The problem notes that there are 100
      numbers to add. This means that if we have an entire column of 9s, that column would only sum to 900. Which means
      that each column can only affect the output column up to two digits left of it. Consequently, there is no need for
      us to add all the columns of digits — worst case we'd only need the left-most 12. And since the numbers don't lead
      with 0s, we know at least 2 digits will be added to the front of the sum, so we actually only need to add the first
      11 digits of each number.
    </p>
    <p>
      Taking this route allows us to again skip big integers: since we have at most 13 digits, we can easily get away with
      a long.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P013Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}

</style>

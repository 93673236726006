<template>
  <div id='problem'>
    <h2>Digit Factorial Chains</h2>
    <a href="https://projecteuler.net/problem=74">https://projecteuler.net/problem=74</a>
    <h3>Thoughts</h3>
    <p>
      This problem is the natural extension of <a href="/projects/euler/34">problem 34</a>. The main sticking point is
      that we need to search all starting numbers under 1000000, which means we risk duplicating effort.
      It helps to visualize this, so here is a subgraph showing only the chains starting from numbers less than
      100 which terminate in the [1454 → 169 → 363601] loop, and which have a chain length of 10 or less.
    </p>
    <img src="@/assets/digit_factorial_chains.svg"
      alt="Subgraph of chains starting at x <= 100, ending at 169 loop, with length less than or equal to 10." />
    <p>
      We can turn this problem into a graph traversal problem by constructing all the edges, then recursively searching
      the graph until we hit a loop. From there we can cache the lengths of the routes we've seen so far to minimize
      duplication. Properly implementing this approach means we only ever calculate successors once, and only traverse
      each link once.
    </p>
    <p>
      Recursively traversing is a little finicky here because we need to check if we've seen a link in the
      chain previously, which means passing the chain we've constructed along, then properly caching the cycle as well as
      passing the correct length up the stack.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P074Page',
  components: {},
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

<template>
  <div id='problem'>
    <h2>Digit Factorials</h2>
    <a href="https://projecteuler.net/problem=34">https://projecteuler.net/problem=34</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Factorion">Wiki: factorion</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      If your google-fu is strong and you discovered the term "factorion" then congrats, you've got the list of them right
      there. If not, then the only work is to figure out an appropriate upper bound — just like we had to do in <a
        href="/projects/euler/30">problem 30</a>.
    </p>
    <p>
      As with problem 30, we can figure out the upper bound by considering the maximal case when all digits are 9.
      <katex-span expr="9! = 362880" />, so we'll have to go a little higher this time:
    </p>
    <table>
      <tr>
        <th># digits</th>
        <th>max number</th>
        <th>sum of factorials</th>
      </tr>
      <tr>
        <td><katex-span expr="2" /></td>
        <td><katex-span expr="99" /></td>
        <td><katex-span expr="725760" /></td>
      </tr>
      <tr>
        <td><katex-span expr="3" /></td>
        <td><katex-span expr="999" /></td>
        <td><katex-span expr="1088640" /></td>
      </tr>
      <tr>
        <td><katex-span expr="4" /></td>
        <td><katex-span expr="9999" /></td>
        <td><katex-span expr="1451520" /></td>
      </tr>
      <tr>
        <td><katex-span expr="5" /></td>
        <td><katex-span expr="99999" /></td>
        <td><katex-span expr="1814400" /></td>
      </tr>
      <tr>
        <td><katex-span expr="6" /></td>
        <td><katex-span expr="999999" /></td>
        <td><katex-span expr="2177280" /></td>
      </tr>
      <tr>
        <td><katex-span expr="7" /></td>
        <td><katex-span expr="9999999" /></td>
        <td><katex-span expr="2540160" /></td>
      </tr>
    </table>
    <p>
      So looks like this time we have to go up to 7 digits instead of 6, and tap out at 2540160. As with problem 30, we
      should cache the "value" of each digit to save ourselves from re-computing the factorials.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P034Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}

table {
  text-align: center;
  table-layout: fixed;
  /* width: 30%; */
  border: 1px solid;
  border-collapse: collapse;
  margin-bottom: 3rem;
}

th,
td {
  border: 1px solid;
}

</style>

<template>
  <div id='problem'>
    <h2>Triangular, Pentagonal, and Hexagonal</h2>
    <a href="https://projecteuler.net/problem=45">https://projecteuler.net/problem=45</a>
    <h3>Thoughts</h3>
    <p>
      We can quickly see that all hexagonal numbers are triangular by substituting <katex-span expr="n=2k-1" /> into the
      formula for triangular numbers:
    </p>
    <katex-div expr="T_n = \frac{n(n+1)}{2} = \frac{(2k-1)(2k)}{2} = k(2k-1)" />
    <p>
      Which means that we only need to worry about the hexagonal and pentagonal constraints. From there we just iterate
      through them, incrementing either the hexagonal or the pentagonal depending on which is lower until we arrive at a
      pair that are equal. This gives us a quick and easy <katex-span expr="O(n)" /> solution.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P045Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

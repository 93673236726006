<template>
  <div id='problem'>
    <h2>Spiral Primes</h2>
    <a href="https://projecteuler.net/problem=58">https://projecteuler.net/problem=58</a>
    <h3>Thoughts</h3>
    <p>
      This is the same spiral from <a href="/projects/euler/28">problem 28</a> going counter-clockwise rather than
      clockwise. The change of direction doesn't really change things for us though, so we can use the logic from there to
      identify the corners in each ring quickly (start with side length squared, then subtract one less than that length
      repeatedly). We also know we don't have to check the final corner since it is always a square. From there it becomes
      an issue of checking primality for the other corners.
    </p>
    <p>
      Since we don't know how big the answer is, defining an appropriately sized sieve to look things up may be
      unreasonable. So we likely need to use trial division if we exceed reasonable sieve sizes, or some probabilistic
      approach to check for primes. We can be a little clever if we want to go the trial division route by noting that for
      any number, we only have to test factors up to the square root of the number in question. This means that if we know
      the primes up through the side length of the square, we just need to check if any of those primes are factors. We
      could go back to finding such primes through a sieve, thus allowing us to test the minimal set of primes as factors,
      while not requiring us to scale up the sieve to accommodate the full contents of the spiral.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P058Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}</style>

<template>
  <div id='problem'>
    <h2>Anagramic Squares</h2>
    <a href="https://projecteuler.net/problem=98">https://projecteuler.net/problem=98</a>
    <h3>Thoughts</h3>
    <p>
      I found this pretty painful to implement. At a high-level I grouped up the words which are anagrams of one another,
      paired off the words with squares of the same length, and checked which substitutions worked. This was pretty
      finicky, but there isn't much here to do from the math side of things to simplify it.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P098Page',
  components: {},
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

<template>
  <div id='problem'>
    <h2>Counting Fractions in a Range</h2>
    <a href="https://projecteuler.net/problem=73">https://projecteuler.net/problem=73</a>
    <h3>Thoughts</h3>
    <p>
      If the range for the denominator were something larger this would probably be one of the hardest problems in the
      first 100. Though as it is the problem can be brute forced in the obvious way:
    </p>
    <ol>
      <li>Iterate over the denominators</li>
      <li>Calculate the numerator floor: <katex-span expr="n = \Big \lceil \frac{d}{3} \Big \rceil" /></li>
      <li>Calculate the numerator ceiling: <katex-span expr="n = \Big \lfloor \frac{d}{2} \Big \rfloor" /></li>
      <li>Count numerators in the range such that <katex-span expr="gcd(n, d) = 1" /></li>
    </ol>
    <p>
      Presumably using the Euclidean algorithm or some such to get the greatest common divisor. That said, there are
      significantly more clever ways of solving this problem, and there is an overview of such solutions available once
      you have the answer. I couldn't come up with anything better than the brute force <katex-span expr="O(n^2)" />
      solution on my own, so I'm not going to spoil those approaches here.
    </p>
    <p>
      A particularly lazy person could also re-use the strategy from <a href="/projects/euler/72">problem 72</a>, then
      assume that the answer is close to one sixth of the total there (assuming an even distribution) to get a good
      initial guess. This yields an answer pretty close to the actual one, and searching around this estimate would yield
      the answer pretty quickly.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P073Page',
  components: {},
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

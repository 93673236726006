<template>
  <div id='problem'>
    <h2>Square Root Convergents</h2>
    <a href="https://projecteuler.net/problem=57">https://projecteuler.net/problem=57</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Continued_fraction#Infinite_continued_fractions_and_convergents">Wiki:
          continued fraction — infinite continued fractions and convergents</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      Still using big integers, but at least there is a little thinking here.
    </p>
    <h3>Pattern recognition</h3>
    <p>
      If we look at the initial terms (or the wiki link above) we can quickly spot a pattern:
    </p>
    <katex-div
      expr="\frac{3}{2} \rightarrow \frac{7}{5} \rightarrow \frac{17}{12} \rightarrow \frac{41}{29} \rightarrow \frac{99}{70} \rightarrow \dots" />
    <br>
    <katex-div
      expr="\frac{3}{2} \rightarrow \frac{3 + 2*2}{3 + 2} \rightarrow \frac{7 + 2*5}{7 + 5} \rightarrow \frac{17 + 2*12}{17 + 12} \rightarrow \frac{41 + 2*29}{41+29} \rightarrow \dots" />
    <p>
      We can confirm this with a quick substitution. Let's call the <katex-span expr="i^{th}" /> term in the expansion
      <katex-span expr="f_i" />, and suppose we've already solved for <katex-span expr="f_{i-1}" />. Then we can
      substitute <katex-span expr="f_{i-1}" /> into <katex-span expr="f_{i}" /> like so:
    </p>
    <katex-div
      expr="f_i = 1 + \frac{1}{2 + \frac{1}{2 + ...}} = 1 + \frac{1}{1 + (1 + \frac{1}{2 + (1 + ...)})} = 1 + \frac{1}{1 + f_{i-1}}" />
    <p>
      Now if we define <katex-span expr="f_{i-1} = \frac{n}{d}" /> and substitute:
    </p>
    <katex-div
      expr="f_i = 1 + \frac{1}{1 + f_{i-1}} = 1 + \frac{1}{1 + \frac{n}{d}} = 1 + \frac{d}{d + n} = \frac{2d + n}{d + n}" />
    <p>
      So we have a nice formula to find successive iterations of the fraction. Now we can just use big integers to
      represent the successive d and n since they grow quickly, and count up whenever the length of these numbers differ.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P057Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

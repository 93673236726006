import { createWebHistory, createRouter } from 'vue-router'
import ContactPage from '@/components/ContactPage'
import HomePage from '@/components/HomePage'
import P001Page from '@/components/euler/P001.vue'
import P002Page from '@/components/euler/P002.vue'
import P003Page from '@/components/euler/P003.vue'
import P004Page from '@/components/euler/P004.vue'
import P005Page from '@/components/euler/P005.vue'
import P006Page from '@/components/euler/P006.vue'
import P007Page from '@/components/euler/P007.vue'
import P008Page from '@/components/euler/P008.vue'
import P009Page from '@/components/euler/P009.vue'
import P010Page from '@/components/euler/P010.vue'
import P011Page from '@/components/euler/P011.vue'
import P012Page from '@/components/euler/P012.vue'
import P013Page from '@/components/euler/P013.vue'
import P014Page from '@/components/euler/P014.vue'
import P015Page from '@/components/euler/P015.vue'
import P016Page from '@/components/euler/P016.vue'
import P017Page from '@/components/euler/P017.vue'
import P018Page from '@/components/euler/P018.vue'
import P019Page from '@/components/euler/P019.vue'
import P020Page from '@/components/euler/P020.vue'
import P021Page from '@/components/euler/P021.vue'
import P022Page from '@/components/euler/P022.vue'
import P023Page from '@/components/euler/P023.vue'
import P024Page from '@/components/euler/P024.vue'
import P025Page from '@/components/euler/P025.vue'
import P026Page from '@/components/euler/P026.vue'
import P027Page from '@/components/euler/P027.vue'
import P028Page from '@/components/euler/P028.vue'
import P029Page from '@/components/euler/P029.vue'
import P030Page from '@/components/euler/P030.vue'
import P031Page from '@/components/euler/P031.vue'
import P032Page from '@/components/euler/P032.vue'
import P033Page from '@/components/euler/P033.vue'
import P034Page from '@/components/euler/P034.vue'
import P035Page from '@/components/euler/P035.vue'
import P036Page from '@/components/euler/P036.vue'
import P037Page from '@/components/euler/P037.vue'
import P038Page from '@/components/euler/P038.vue'
import P039Page from '@/components/euler/P039.vue'
import P040Page from '@/components/euler/P040.vue'
import P041Page from '@/components/euler/P041.vue'
import P042Page from '@/components/euler/P042.vue'
import P043Page from '@/components/euler/P043.vue'
import P044Page from '@/components/euler/P044.vue'
import P045Page from '@/components/euler/P045.vue'
import P046Page from '@/components/euler/P046.vue'
import P047Page from '@/components/euler/P047.vue'
import P048Page from '@/components/euler/P048.vue'
import P049Page from '@/components/euler/P049.vue'
import P050Page from '@/components/euler/P050.vue'
import P051Page from '@/components/euler/P051.vue'
import P052Page from '@/components/euler/P052.vue'
import P053Page from '@/components/euler/P053.vue'
import P054Page from '@/components/euler/P054.vue'
import P055Page from '@/components/euler/P055.vue'
import P056Page from '@/components/euler/P056.vue'
import P057Page from '@/components/euler/P057.vue'
import P058Page from '@/components/euler/P058.vue'
import P059Page from '@/components/euler/P059.vue'
import P060Page from '@/components/euler/P060.vue'
import P061Page from '@/components/euler/P061.vue'
import P062Page from '@/components/euler/P062.vue'
import P063Page from '@/components/euler/P063.vue'
import P064Page from '@/components/euler/P064.vue'
import P065Page from '@/components/euler/P065.vue'
import P066Page from '@/components/euler/P066.vue'
import P067Page from '@/components/euler/P067.vue'
import P068Page from '@/components/euler/P068.vue'
import P069Page from '@/components/euler/P069.vue'
import P070Page from '@/components/euler/P070.vue'
import P071Page from '@/components/euler/P071.vue'
import P072Page from '@/components/euler/P072.vue'
import P073Page from '@/components/euler/P073.vue'
import P074Page from '@/components/euler/P074.vue'
import P075Page from '@/components/euler/P075.vue'
import P076Page from '@/components/euler/P076.vue'
import P077Page from '@/components/euler/P077.vue'
import P078Page from '@/components/euler/P078.vue'
import P079Page from '@/components/euler/P079.vue'
import P080Page from '@/components/euler/P080.vue'
import P081Page from '@/components/euler/P081.vue'
import P082Page from '@/components/euler/P082.vue'
import P083Page from '@/components/euler/P083.vue'
import P084Page from '@/components/euler/P084.vue'
import P085Page from '@/components/euler/P085.vue'
import P086Page from '@/components/euler/P086.vue'
import P087Page from '@/components/euler/P087.vue'
import P088Page from '@/components/euler/P088.vue'
import P089Page from '@/components/euler/P089.vue'
import P090Page from '@/components/euler/P090.vue'
import P091Page from '@/components/euler/P091.vue'
import P092Page from '@/components/euler/P092.vue'
import P093Page from '@/components/euler/P093.vue'
import P094Page from '@/components/euler/P094.vue'
import P095Page from '@/components/euler/P095.vue'
import P096Page from '@/components/euler/P096.vue'
import P097Page from '@/components/euler/P097.vue'
import P098Page from '@/components/euler/P098.vue'
import P099Page from '@/components/euler/P099.vue'
import P100Page from '@/components/euler/P100.vue'
import PEBackgroundPage from '@/components/euler/PEBackground.vue'
import MoodyPage from '@/components/MoodyPage'
import ProjectsPage from '@/components/ProjectsPage'
import NotFoundPage from '@/components/NotFoundPage'
import ResumePage from '@/components/ResumePage'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomePage,
    },
    {
        path: '/resume',
        name: 'Resume',
        component: ResumePage
    },
    {
        path: '/contact',
        name: 'Contact',
        component: ContactPage,
    },
    {
        path: '/projects',
        name: 'Projects',
        component: ProjectsPage,
    },
    {
        path: '/projects/moody',
        name: 'Moody',
        component: MoodyPage,
    },
    {
        path: '/projects/euler',
        name: 'Project Euler',
        component: PEBackgroundPage,
    },
    {
        path: '/projects/euler/:pathMatch(0*)1',
        name: 'Project Euler: Problem 1',
        component: P001Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)2',
        name: 'Project Euler: Problem 2',
        component: P002Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)3',
        name: 'Project Euler: Problem 3',
        component: P003Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)4',
        name: 'Project Euler: Problem 4',
        component: P004Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)5',
        name: 'Project Euler: Problem 5',
        component: P005Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)6',
        name: 'Project Euler: Problem 6',
        component: P006Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)7',
        name: 'Project Euler: Problem 7',
        component: P007Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)8',
        name: 'Project Euler: Problem 8',
        component: P008Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)9',
        name: 'Project Euler: Problem 9',
        component: P009Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)10',
        name: 'Project Euler: Problem 10',
        component: P010Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)11',
        name: 'Project Euler: Problem 11',
        component: P011Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)12',
        name: 'Project Euler: Problem 12',
        component: P012Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)13',
        name: 'Project Euler: Problem 13',
        component: P013Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)14',
        name: 'Project Euler: Problem 14',
        component: P014Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)15',
        name: 'Project Euler: Problem 15',
        component: P015Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)16',
        name: 'Project Euler: Problem 16',
        component: P016Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)17',
        name: 'Project Euler: Problem 17',
        component: P017Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)18',
        name: 'Project Euler: Problem 18',
        component: P018Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)19',
        name: 'Project Euler: Problem 19',
        component: P019Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)20',
        name: 'Project Euler: Problem 20',
        component: P020Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)21',
        name: 'Project Euler: Problem 21',
        component: P021Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)22',
        name: 'Project Euler: Problem 22',
        component: P022Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)23',
        name: 'Project Euler: Problem 23',
        component: P023Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)24',
        name: 'Project Euler: Problem 24',
        component: P024Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)25',
        name: 'Project Euler: Problem 25',
        component: P025Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)26',
        name: 'Project Euler: Problem 26',
        component: P026Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)27',
        name: 'Project Euler: Problem 27',
        component: P027Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)28',
        name: 'Project Euler: Problem 28',
        component: P028Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)29',
        name: 'Project Euler: Problem 29',
        component: P029Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)30',
        name: 'Project Euler: Problem 30',
        component: P030Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)31',
        name: 'Project Euler: Problem 31',
        component: P031Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)32',
        name: 'Project Euler: Problem 32',
        component: P032Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)33',
        name: 'Project Euler: Problem 33',
        component: P033Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)34',
        name: 'Project Euler: Problem 34',
        component: P034Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)35',
        name: 'Project Euler: Problem 35',
        component: P035Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)36',
        name: 'Project Euler: Problem 36',
        component: P036Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)37',
        name: 'Project Euler: Problem 37',
        component: P037Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)38',
        name: 'Project Euler: Problem 38',
        component: P038Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)39',
        name: 'Project Euler: Problem 39',
        component: P039Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)40',
        name: 'Project Euler: Problem 40',
        component: P040Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)41',
        name: 'Project Euler: Problem 41',
        component: P041Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)42',
        name: 'Project Euler: Problem 42',
        component: P042Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)43',
        name: 'Project Euler: Problem 43',
        component: P043Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)44',
        name: 'Project Euler: Problem 44',
        component: P044Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)45',
        name: 'Project Euler: Problem 45',
        component: P045Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)46',
        name: 'Project Euler: Problem 46',
        component: P046Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)47',
        name: 'Project Euler: Problem 47',
        component: P047Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)48',
        name: 'Project Euler: Problem 48',
        component: P048Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)49',
        name: 'Project Euler: Problem 49',
        component: P049Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)50',
        name: 'Project Euler: Problem 50',
        component: P050Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)51',
        name: 'Project Euler: Problem 51',
        component: P051Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)52',
        name: 'Project Euler: Problem 52',
        component: P052Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)53',
        name: 'Project Euler: Problem 53',
        component: P053Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)54',
        name: 'Project Euler: Problem 54',
        component: P054Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)55',
        name: 'Project Euler: Problem 55',
        component: P055Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)56',
        name: 'Project Euler: Problem 56',
        component: P056Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)57',
        name: 'Project Euler: Problem 57',
        component: P057Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)58',
        name: 'Project Euler: Problem 58',
        component: P058Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)59',
        name: 'Project Euler: Problem 59',
        component: P059Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)60',
        name: 'Project Euler: Problem 60',
        component: P060Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)61',
        name: 'Project Euler: Problem 61',
        component: P061Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)62',
        name: 'Project Euler: Problem 62',
        component: P062Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)63',
        name: 'Project Euler: Problem 63',
        component: P063Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)64',
        name: 'Project Euler: Problem 64',
        component: P064Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)65',
        name: 'Project Euler: Problem 65',
        component: P065Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)66',
        name: 'Project Euler: Problem 66',
        component: P066Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)67',
        name: 'Project Euler: Problem 67',
        component: P067Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)68',
        name: 'Project Euler: Problem 68',
        component: P068Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)69',
        name: 'Project Euler: Problem 69',
        component: P069Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)70',
        name: 'Project Euler: Problem 70',
        component: P070Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)71',
        name: 'Project Euler: Problem 71',
        component: P071Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)72',
        name: 'Project Euler: Problem 72',
        component: P072Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)73',
        name: 'Project Euler: Problem 73',
        component: P073Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)74',
        name: 'Project Euler: Problem 74',
        component: P074Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)75',
        name: 'Project Euler: Problem 75',
        component: P075Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)76',
        name: 'Project Euler: Problem 76',
        component: P076Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)77',
        name: 'Project Euler: Problem 77',
        component: P077Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)78',
        name: 'Project Euler: Problem 78',
        component: P078Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)79',
        name: 'Project Euler: Problem 79',
        component: P079Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)80',
        name: 'Project Euler: Problem 80',
        component: P080Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)81',
        name: 'Project Euler: Problem 81',
        component: P081Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)82',
        name: 'Project Euler: Problem 82',
        component: P082Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)83',
        name: 'Project Euler: Problem 83',
        component: P083Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)84',
        name: 'Project Euler: Problem 84',
        component: P084Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)85',
        name: 'Project Euler: Problem 85',
        component: P085Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)86',
        name: 'Project Euler: Problem 86',
        component: P086Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)87',
        name: 'Project Euler: Problem 87',
        component: P087Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)88',
        name: 'Project Euler: Problem 88',
        component: P088Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)89',
        name: 'Project Euler: Problem 89',
        component: P089Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)90',
        name: 'Project Euler: Problem 90',
        component: P090Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)91',
        name: 'Project Euler: Problem 91',
        component: P091Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)92',
        name: 'Project Euler: Problem 92',
        component: P092Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)93',
        name: 'Project Euler: Problem 93',
        component: P093Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)94',
        name: 'Project Euler: Problem 94',
        component: P094Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)95',
        name: 'Project Euler: Problem 95',
        component: P095Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)96',
        name: 'Project Euler: Problem 96',
        component: P096Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)97',
        name: 'Project Euler: Problem 97',
        component: P097Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)98',
        name: 'Project Euler: Problem 98',
        component: P098Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)99',
        name: 'Project Euler: Problem 99',
        component: P099Page,
    },
    {
        path: '/projects/euler/:pathMatch(0*)100',
        name: 'Project Euler: Problem 100',
        component: P100Page,
    },
    {
        path: '/:catchAll(.*)*',
        name: 'NotFound',
        component: NotFoundPage
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
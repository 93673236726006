<template>
  <div id='problem'>
    <h2>Large Mersenne Prime</h2>
    <a href="https://projecteuler.net/problem=97">https://projecteuler.net/problem=97</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Modular_arithmetic">Wiki: modular arithmetic</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      Since we only care about the last 10 digits, we can do this with a long working in mod <katex-span
        expr="10^{10}" />.
      Nice and easy. One trying hard to be efficient could repeatedly square rather than multiplying by 2 each time to
      save some steps, but the exponent is small enough that it's fine either way.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P097Page',
  components: {},
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

<template>
  <div id="app">
    <p class="four-oh-four">¯\_(ツ)_/¯</p>
    <p class="four-oh-four">404</p>
    <p class="four-oh-four">¯\_(ツ)_/¯</p>
    <p>Sorry, not sure what you're looking for.</p>
    <p class="four-oh-four"></p>
  </div>
</template>

<script>

export default {
  name: 'NotFoundPage',
  components: {
  },
}
</script>

<style scoped>
body {
  margin: 0px;
}
p {
  font-size: 2rem;
}
.four-oh-four {
  font-size: 8rem;
}
</style>

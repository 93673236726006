<template>
  <div id='problem'>
    <h2>1000-digit Fibonacci Number</h2>
    <a href="https://projecteuler.net/problem=25">https://projecteuler.net/problem=25</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Fibonacci_sequence">Wiki: Fibonacci sequence</a></li>
      <li>
        <a
          href="https://math.stackexchange.com/questions/231742/proof-how-many-digits-does-a-number-have-lfloor-log-10-n-rfloor-1">Math
          Stackexchange: relation between log10 and number of digits</a>
      </li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      Throwback alllll the way to <a href="/projects/euler/2">problem 2</a>. If we don't want to abuse big integer (which
      once again trivializes the problem), then we need to use the formula from there for direct calculation and some
      cleverness.
    </p>
    <h3>Ratio of consecutive terms</h3>
    <p>
      From problem 2, we already know a formula for individual terms:
    </p>
    <katex-div expr="F_n = \frac{\phi^\mathit{n} - (1-\phi)^\mathit{n}}{\sqrt{5}}" />
    <p>
      Now if we use that directly, we'd still need to store the full number, which won't fly. However, that formula does
      tell us the crucial fact that as we go further out in the sequence, the <katex-span expr="\phi^n" /> term starts to
      dominate. This means that consecutive terms in the sequence are roughly in the ratio of <katex-span expr="\phi" />.
    </p>
    <p>
      Knowing that, we can essentially discard the other part of the expression, and approximate the terms like:
    </p>
    <katex-div expr="F_n = \frac{\phi^\mathit{n}}{\sqrt{5}}" />
    <p>
      Using that and taking <katex-span expr="\log_{10}" /> (since a number in that base tells us the number of digits)
      allows us to skip big integer classes altogether.
    </p>
    <h4>A little bit of algebra</h4>
    <p>
      Alternatively, we can get the value explicitly. So what we want is the smallest <katex-span expr="n" /> such that:
    </p>
    <katex-div expr="\frac{\phi^\mathit{n}}{\sqrt{5}} \geq 10^{999}" />
    <katex-div expr="\Rightarrow \log\frac{\phi^\mathit{n}}{\sqrt{5}} \geq \log10^{999}" />
    <katex-div expr="\Rightarrow n*\log\phi - \frac{\log5}{2} \geq 999*\log10" />
    <katex-div expr="\Rightarrow n*\log\phi \geq 999*\log10 + \frac{\log5}{2}" />
    <katex-div expr="\Rightarrow n = \bigg\lceil\frac{1998*\log10 + \log5}{2 * \log\phi}\bigg\rceil" />
    <p>
      Which can be plugged into a calculator.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P025Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

<template>
  <div id='problem'>
    <h2>Self powers</h2>
    <a href="https://projecteuler.net/problem=48">https://projecteuler.net/problem=48</a>
    <h3>Thoughts</h3>
    <p>
      Another case of big integers destroying the challenge. However, we can also use modular arithmetic once again!
    </p>
    <p>
      In Java/Kotlin, the max value of an Integer is 2,147,483,647 (a Mersenne prime!), which is unfortunately too small
      for us to work with (that is, we'll overflow if any of the numbers exceed it... which they do). The Long class,
      however, goes up to 9,223,372,036,854,775,807. This is more than enough space to prevent overflow. All we need to do
      is manually perform the exponentiation (as in, multiply repeatedly) rather than using the math library function. So
      our exponentiation looks something like this:
    </p>
    <highlightjs language="kotlin" :code="exponentiate" />
  </div>
</template>

<script>

export default {
  name: 'P048Page',
  components: {
  },
  data() {
    return {
      exponentiate: `// Returns x to the xth power, mod 10^10
fun raiseToSelf(x: Long): Long {
  if (x < 1) error("I'm not dealing with this: $x")
  var t = x
  for (i in 2..x) {
    t = t*x
    t %= 10_000_000_000L
  }
  return t
}`
    }
  }
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

<template>
  <div id='problem'>
    <h2>Amicable Numbers</h2>
    <a href="https://projecteuler.net/problem=21">https://projecteuler.net/problem=21</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Divisor_function">Wiki: divisor function (sigma)</a></li>
      <li><a href="https://oeis.org/A000203">OEIS: A000203</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      The main issue here is figuring out the sum of proper divisors of n. One could brute force this, but unsurprisingly
      there is a quick way to do it and it once again relies on prime factorizations.
    </p>
    <h3>Sigma function</h3>
    <p>
      The operation of getting the sum of the divisors of some number is common enough to be known as <katex-span
        expr="\sigma(n)" />, the <em>sigma function</em>. This function normally includes the number itself as a divisor,
      but we can easily subtract that number out.
    </p>
    <p>
      Let's recall the prime factorization of n:
    </p>
    <katex-div expr="n = p_{0}^{i_0}p_{1}^{i_1}p_{2}^{i_2}...p_{k}^{i_k}" />
    <p>
      For some number f to be a factor of n, it must be true that:
    </p>
    <katex-div expr="f = p_{0}^{j_0}p_{1}^{j_1}p_{2}^{j_2}...p_{k}^{j_k}" />
    <p>
      where <katex-span expr="j_l < i_l" /> for all <katex-span expr="l \in \Set{0, 1, 2, ..., k}" />.
    </p>
    <p>
      Less formally, every factor of n is the result of multiplying the prime factors of n in some configuration. In fact,
      every combination of the prime factors (and their multiples) yields a factor of n. So if we wanted to generate all
      the factors of n, we just need to find all the ways of combining the prime factors.
    </p>
    <p>
      We can think of this as doing a full outer join of each set of multiples of a prime, e.g. forming all possible pairs
      from the sets:
    </p>
    <katex-div expr="\Set{p_{0}^0, p_{0}^1, ..., p_{0}^{j_0}}, \Set{p_{1}^0, p_{1}^1, ..., p_{1}^{j_1}}" />
    <p>
      There is a clear analogy here to an associative application of multiplication, and we can in fact use multiplication
      to quickly pair up all the elements in these sets and compute their sums:
    </p>
    <katex-div expr="(p_{0}^0 + p_{0}^1 + ... + p_{0}^{j_0}) *(p_{1}^0 + p_{1}^1 + ... + p_{1}^{j_1})" />
    <p>
      Repeating this for every prime factor (and its powers) allows us to quickly calculate the sum of all factors of n.
      For example, the problem uses 220.
    </p>
    <katex-div expr="220 = 2^2 * 5 * 11" />
    <p>
      So the sum of all factors of 220 is:
    </p>
    <katex-div expr="(1 + 2^1 + 2^2)(1 + 5^1)(1 + 11^1) = (7)(6)(11) = 504" />
    <p>
      But that includes 220 itself! So we subtract that and arrive at a total of 284, as expected. So with that, we now
      know how to quickly compute the sigma function once we have the prime factorization of that number.
    </p>
    <p>
      And from <a href="/projects/euler/12">problem 12</a> we already know how to find the prime factors of a number using
      a sieve. Gluing these together quickly yields our answer.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P021Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

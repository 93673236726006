<template>
    <HeaderComponent />
    <router-view />
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent";

export default {
    name: 'App',
    components: {
        HeaderComponent
    }
}
</script>

<style>
#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

/* Credit to https://www.w3schools.com/howto/howto_css_hide_scrollbars.asp */
/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
    /* IE and Edge */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;

    /* Remove padding to ensure header covers width of page. */
    margin: 0px;
    /* Stop the "bounce" when scrolling to the top of a page. */
    overscroll-behavior: none;
}

h1 {
    font-size: 6rem;
}

h2 {
    font-size: 4rem;
    text-align: center;
}

h3 {
    font-size: 3rem;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    text-align: center;
}

h4 {
    font-size: 2rem;
    margin-top: 0.5rem;
    margin-bottom: 0.25rem;
    text-align: center;
}

a {
    text-decoration: none;
    font-size: 1.5rem;
}

ul,
ol,
li {
    font-family: inherit;
    font-size: inherit;
}

p {
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
</style>

<template>
  <div id="home">
    <p>Hi, thanks for visiting. Say hello to Mollycat.</p>
    <img id="molly" src="@/assets/molly-u-wot.jpg" alt="u wot" />
  </div>
</template>

<script>

export default {
  name: 'HomePage',
  components: {
  },
}
</script>

<style>

div#home {
  margin-top: 3rem;
  margin-bottom: 10rem;
}

img#molly {
  width: 700px;
  height: "auto"; 
}

</style>

<template>
  <div id='problem'>
    <h2>Cyclical Figurate Numbers</h2>
    <a href="https://projecteuler.net/problem=61">https://projecteuler.net/problem=61</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Polygonal_number">Wiki: polygonal number</a></li>
      <li><a href="https://en.wikipedia.org/wiki/Figurate_number">Wiki: figurate number</a></li>
      <li><a href="https://en.wikipedia.org/wiki/Depth-first_search">Wiki: depth-first search</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      Turns out the triangular numbers are part of a more general class of numbers, nice to get introduced to them! This
      problem is mostly about implementing the search, and unfortunately there isn't much to say about that. We again use
      depth-first search (as we just did in <a href="/projects/euler/60">problem 60</a>), though this time we need to:
    </p>
    <ol>
      <li>Track which types of polygonal numbers we haven't used.</li>
      <li>For the next in the sequence, only look at candidates whose first 2 digits match the last 2 of the prior term.
      </li>
      <li>For the last in the sequence, check explicitly for it by combining the prior term and the first term in the
        sequence.</li>
    </ol>
    <p>
      For efficiency reasons, we again should pre-calculate all the relevant polygonal numbers and stick them in a map (I
      had an enum of the types I used as key, with the value in the list as a list of ints). Then just be careful
      implementing the recursive search. My DFS logic was all in a helper function with signature like:
    </p>
    <highlightjs language="kotlin" code="private fun searchForCycle(
  typeMap: Map<PolygonType, List<Int>>,
  priorTerms: List<Pair<Int, PolygonType>>
): List<Pair<Int, PolygonType>>? {" />
    <p>
      Where the `PolygonType` enum is trivially defined:
    </p>
    <highlightjs language="kotlin" code="private enum class PolygonType {
  TRIANGLE,
  SQUARE,
  PENTAGONAL,
  HEXAGONAL,
  HEPTAGONAL,
  OCTAGONAL
}" />
  </div>
</template>

<script>

export default {
  name: 'P061Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

<template>
  <div id='problem'>
    <h2>Prime Digit Replacements</h2>
    <a href="https://projecteuler.net/problem=51">https://projecteuler.net/problem=51</a>
    <h3>Thoughts</h3>
    <p>
      The back half of the first 100 is a bit harder, and starts nicely. I believe this is the first problem with a
      difficulty rating over 5%, which seems fair. It's not bad on the math side, but a bit tricky to code up.
    </p>
    <h3>Masking</h3>
    <p>
      My approach to this was to generate all the primes up to some power of 10, then for each prime generate "masks" for
      it where we replace all repetitions of one digit with a wildcard indicator. We keep track of the count of these
      masks, then return the first time we see a mask 8 times. To elaborate on masking, consider the number 123412. The
      masks (as I'm describing this) are:
    </p>
    <p>Replace all 1s: ▮234▮2</p>
    <p>Replace all 2s: 1▮341▮</p>
    <p>Replace all 3s: 12▮412</p>
    <p>Replace all 4s: 123▮12</p>
    <p>
      Unfortunately this doesn't quite work — what happens if the wildcard is replaced with another character present in
      the string? Then the mask generation described here wouldn't catch it. That is, the last mask (replacing 4s)
      wouldn't be generated from any of 123112, 123212, or 123312.
    </p>
    <p>
      This leaves us in an awkward position where we can either:
    </p>
    <ol>
      <li>Generate all the masks which don't replace every instance of a digit in our original.</li>
      <li>
        Add some additional logic to check for replacing the wildcards in each mask by characters explicitly present in
        the mask.
      </li>
    </ol>
    <p>
      The amount of possibilities for option 1 aren't onerous, but it is a bit of a pain to generate the partial masks. So
      I went with option 2, adding logic to check for the "shared digit" replacements the first time I encounter a mask.
      This worked nicely. Since we only have to pass through the primes once after finding them all and building the masks
      is <katex-span expr="O(d)" /> where d is the number of digits in our biggest number, the non-sieve part of the
      solution is <katex-span expr="O(n*d)" /> where n is the upper bound for the primes we generate.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P051Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

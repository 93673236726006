<template>
  <div id='problem'>
    <h2>Sub-string Divisibility</h2>
    <a href="https://projecteuler.net/problem=43">https://projecteuler.net/problem=43</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Divisibility_rule">Wiki: divisibility rule</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      I wrote some extremely ugly code here. Better to not look at every possible pandigital since we're hitting the
      millions of them, though one could do it if needed. What's interesting is that the rules are so restrictive that
      this problem is doable by hand.
    </p>
    <h3>Restrictions</h3>
    <p>
      Divisibility rules do some heavy lifting here. The rule for 5 is the most restrictive, so we'll start from there.
    </p>
    <p>
      <katex-span expr="d_4d_5d_6" /> must be divisible by 5, so <katex-span expr="d_6 \in \lbrace 0,5 \rbrace" />. Then
      there are way too many numbers divisible by 7 with a 0 or 5 in the tens places, so skip that for now.
    </p>
    <p>
      <katex-span expr="d_6d_7d_8" /> must be divisible by 11. If <katex-span expr="d_6 = 0" /> then the last two digits
      must be equal, which cannot happen in a pandigital. So <katex-span expr="d_6 = 5" /> which leaves us with only a
      few possible strings: <katex-span expr="d_6d_7d_8 \in \lbrace 506, 517, 528, 539, 561, 572, 583, 594 \rbrace" />
      (note that we omit 550 for the duplicated 5).
    </p>
    <p>
      <katex-span expr="d_7d_8d_9" /> must be divisible by 13. Since we know that <katex-span
        expr="d_7d_8 \in \lbrace 06, 17, 28, 39, 61, 72, 83, 94 \rbrace" />, we can complete the numbers: <katex-span
        expr="d_7d_8d_9 \in \lbrace 065, 17x, 289, 390, 611, 728, 832, 949 \rbrace" />. From there we rule out 065 since
      we've placed 5 already, and all the numbers which double a digit or cannot be completed. This leaves us with
      <katex-span expr="d_6d_7d_8d_9 \in \lbrace 5286, 5390, 5728, 5832 \rbrace" />.
    </p>
    <p>
      <katex-span expr="d_8d_9d_{10}" /> must be divisible by 17. We know the first 2 digits, which leaves us with:
      <katex-span expr="d_6d_7d_8d_9d_{10} \in \lbrace 52867, 53901, 57289, 58323 \rbrace" />. Last one has two 3s, so we
      drop it.
    </p>
    <p>
      Going back now, <katex-span expr="d_5d_6d_7" /> must be divisible by 7. We've got the last two digits, so there
      are only a couple of possibilities for <katex-span expr="d_5" />: <katex-span
        expr="d_5d_6d_7 \in \lbrace 252, 952, 553, 357 \rbrace" />. After dropping cases with repeated digits we're left
      with only two ways to assemble the last six digits:
      <katex-span expr="d_5d_6d_7d_8d_9d_{10} \in \lbrace 952867, 357289 \rbrace" />.
    </p>
    <p>
      At this point we have to place <katex-span expr="\lbrace 0, 1, 3, 4 \rbrace" /> before 952867 and <katex-span
        expr="\lbrace 0, 1, 4, 6 \rbrace" /> before 357289. <katex-span expr="d_4" /> is even, and <katex-span
        expr="d_3 + d_4" /> must be divisible by 3 since <katex-span expr="d_5" /> is a multiple of 3 in either case. That
      leaves us with <katex-span expr="d_3d_4 = 30" /> and <katex-span expr="d_3d_4 \in \lbrace 06, 60 \rbrace" />
      respectively.
    </p>
    <p>
      At this point we're left to place 1 and 4 freely. Sum up all 6 possibilities and we've got our answer.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P043Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

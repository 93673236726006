<template>
  <div id='problem'>
    <h2>Magic 5-gon Ring</h2>
    <a href="https://projecteuler.net/problem=68">https://projecteuler.net/problem=68</a>
    <h3>Thoughts</h3>
    <p>
      Another where it is probably easiest to do it by hand. Though we can also code this up with a depth-first search
      after logically placing some initial values.
    </p>
    <p>
      The first thing to note is that we want a 16-digit string. Since all the numbers in the inner ring appear twice,
      this means that 10 must be on the outside. We want to maximize the solution string, which means we should put the
      largest numbers (6, 7, 8, 9, 10) on the outside if possible.
    </p>
    <p>
      With those in place, we have to put 1 through 5 on the interior ring. Each of these will get counted as part of two
      sequences of three, so the sum of all sequences in the magic n-gon is:
    </p>
    <katex-span expr="2*(1+2+3+4+5) + 6+7+8+9+10 = 70" />
    <p>
      Divide by 5 to get the sum of each sequence of three, and now we know that each sequence sums to 14.
    </p>
    <p>
      With that we can start placing the interior ring. 10 is outside, and must be paired with two numbers summing to 4,
      which means the sequence <katex-span expr="(10,3,1)" /> or <katex-span expr="(10,1,3)" /> is locked in. Given that
      we're looking for the maximal string, we'll opt for the former of the two and see if it works out.
    </p>
    <img src="@/assets/10-3-1_ngon.png" alt="n-gon diagram with 10,3,1 triple placed" />
    <p>
      Similarly, the number 6 must be paired with two numbers summing to 8, which only allows 5 and 3. Therefore the 3
      must be shared with the 10-triple, and we have the sequence <katex-span expr="(6,5,3)" />
    </p>
    <img src="@/assets/10-3-1_6-5-3_ngon.png" alt="n-gon diagram with 6,5,3 triple added" />
    <p>
      With those in place, we're left with only 2 and 4 to place on the inner ring. Since these sum to 6, they must be
      paired with the number 8, so the triple is either <katex-span expr="(8,4,2)" /> or <katex-span expr="(8,2,4)" />.
      Additionally, we can see that 4 cannot be paired 5, so the 4 must go adjacent to the 1 on the inner ring.
    </p>
    <img src="@/assets/almost_ngon.png" alt="n-gon diagram with all but 7 and 9 in place" />
    <p>
      Which leaves us with 7 and 9 to place, and their positions fixed due to the inner ring.
    </p>
    <img src="@/assets/complete_ngon.png" alt="Finished diagram with all the numbers in place" />
    <p>
      Note that once we decided on using <katex-span expr="(10,3,1)" /> rather than <katex-span expr="(10,1,3)" /> every
      placement afterwards was forced. If we had taken the other route, we would have wound up with a valid n-gon (shown
      below), but it would not be maximal. If I had been more clever I would have started with placing the 6 instead of
      the 10 for this reason — since 6 will certainly be the starting digit and it must be paired with 5 or 3, going with
      6-5-3 as our starting choice ensures the max result. Here's what it looks like if we had gone the other way:
    </p>
    <img src="@/assets/wrong_ngon.png" alt="Finished n-gon, but not maximal one" />
  </div>
</template>

<script>

export default {
  name: 'P068Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

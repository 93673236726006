<template>
  <div id='problem'>
    <h2>Circular Primes</h2>
    <a href="https://projecteuler.net/problem=35">https://projecteuler.net/problem=35</a>
    <h3>Thoughts</h3>
    <p>
      We could be a little clever about generating possibilities (none of the digits can ever be even, nor 5), but the
      bound is only 1M so it's frankly a lot easier to just take whatever sieve you wrote for any of the previous problems
      which used one (7, 10, 12, 21, 27) and then use the discovered primes from it as both your test cases and your means
      of validation.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P035Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}

table {
  text-align: center;
  table-layout: fixed;
  /* width: 30%; */
  border: 1px solid;
  border-collapse: collapse;
  margin-bottom: 3rem;
}

th,
td {
  border: 1px solid;
}
</style>

<template>
  <div id='problem'>
    <h2>Totient Maximum</h2>
    <a href="https://projecteuler.net/problem=69">https://projecteuler.net/problem=69</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Euler%27s_totient_function">Wiki: Euler's totient function</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      Great problem, and somehow the first time we've seen the totient function come up. There's a few approaches that
      build nicely on each other, so let's go from best to worst.
    </p>
    <h3>Brute force</h3>
    <p>
      This is not the way to go, but I'm describing it for completeness. We can calculate the totient function by looking
      at each pair of numbers and checking if their greatest common divisor (gcd, as referenced and calculated in <a
        href="/projects/euler/5">problem 5</a>) is 1. However, doing so is pretty expensive, as we need to look at all
      pairs of positive integers less than or equal to 1,000,000, which is <katex-span
        expr="\binom{1000000}{2} = 499999500000" /> pairs. Obviously out of our computer's comfort zone.
    </p>
    <p>
      We could speed this up a little by being clever about how we check for relatively prime pairs. Instead of using gcd,
      we can generate prime factorizations for all numbers using a sieve like we discussed in <a
        href="/projects/euler/47">problem 47</a>. Then we can just compare the sets of prime factors and see if the
      intersection is empty. However, we still have to look at all the pairs, which again seems unreasonable.
    </p>
    <h3>Totient function calculation</h3>
    <p>
      The key insight here is that we don't need to directly examine each pair of numbers. Let's say we want to find the
      totient of:
    </p>
    <katex-div expr="n = p_0^{i_0} * p_1^{i_1} * p_2^{i_2} * ... * p_k^{i_k}" />
    <p>
      Since we have the prime factorization, we can directly calculate the totient function:
    </p>
    <katex-div
      expr="\phi(n) = n * \displaystyle \prod_{i=1}^{k} 1 - \frac{1}{p_i} = n * \prod_{i=1}^{k} \frac{p_i - 1}{p_i}" />
    <p>
      I'll skip the proof, but this should make some intuitive sense. If a number is divisible by 2, then half the numbers
      less than it will also be divisible by 2. The same argument goes for 3, 5, 7, etc. So when we calculate totient in
      this manner, we're essentially counting off the numbers which share a factor, then dropping them.
    </p>
    <p>
      With that, our solution can be improved: we get the prime factorization of all numbers in our range, then calculate
      totient and see what is biggest. This is much more tractable than the other approaches.
    </p>
    <h3>One step further</h3>
    <p>
      However, we can reach an even better solution by thinking about this definition of <katex-span expr="\phi(n)" />. We
      want to maximize the value of <katex-span expr="\frac{n}{\phi(n)}" /> in our range. Then this is maximized when
      <katex-span expr="\phi(n)" /> is as small as possible compared to <katex-span expr="n" />. From the definition,
      <katex-span expr="\phi(n)" /> shrinks relative to <katex-span expr="n" /> for each prime factor of <katex-span
        expr="n" />. So what we really want to find are numbers with as many prime factors as possible, and which have all
      of the smallest primes (as those will be a common factor with more numbers than larger primes) in their
      factorization.
    </p>
    <p>
      We also don't want any of our prime factors to appear more than once in the prime factorization, since that
      increases the size of <katex-span expr="n" /> without increasing the ratio to <katex-span expr="\phi(n)" />. That
      means that we can skip all of the calculations, and instead generate potential answers by multiplying successive
      primes to find the largest product less than our limit of one million. So our answer is the largest <katex-span
        expr="n" /> such that:
    </p>
    <katex-div expr="n = 2*3*5*...*p_k \leq 1000000" />
    <p>
      Which can quickly be found with a calculator.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P069Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

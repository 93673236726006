<template>
  <div id='problem'>
    <h2>Longest Collatz Sequence</h2>
    <a href="https://projecteuler.net/problem=14">https://projecteuler.net/problem=14</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Collatz_conjecture">Wiki: Collatz conjecture</a></li>
    </ul>
    <h3>Thoughts</h3>
    <img src="@/assets/collatz.svg" alt="Depiction of the Collatz sequence graph for a few small numbers."/>
    <p>
      More a programming puzzle than a math one, which is not the case for later problems. There's a straightforward brute
      force approach here, the question is how to optimize it.
    </p>
    <h3>Brute force</h3>
    <p>
      The most obvious approach is to iterate from one to a million, calculating the length of each sequence.
    </p>
    <highlightjs language="kotlin" :code="recursive_collatz" />
    <p>
      This will yield the right answer, but is extremely slow. It should be clear that taking this approach leads to a lot
      of unnecessary repetition, due to numbers appearing repeatedly in other numbers' sequences.
    </p>
    <h3>Caching</h3>
    <p>
      As usual when we're wasting time on repeated effort, caching comes to our rescue. If we instead modify our solution
      to cache the Collatz sequence length whenever we first encounter a number, and check the cache before each recursive
      call, we can dramatically cut down our runtime (to under a second on my computer).
    </p>
    <h3>Other optimizations</h3>
    <p>
      The structure of the Collatz transformations means that we can make a few other obvious improvements. Any number
      <katex-span expr="x" /> such that <katex-span expr="22x < 1000000" /> is obviously not the solution, since
      <katex-span expr="2x" /> will have a sequence one longer. Thus we can rule out everything below 500000.
    </p>
    <p>
      Furthermore, consider what happens when x is odd. Whenever this happens, we'll multiply by 3 then add 1, and be left
      with an even number, which we'll then immediately divide by 2. So there really isn't a need for us to make a
      recursive call for the initial operation — we know that the sequence will reach <katex-span
        expr="\frac{3x+1}{2}" /> in two steps, so we can modify our recursion to jump there:
    </p>
    <highlightjs language="kotlin" :code="skipping_collatz" />
    <p>
      This isn't necessary to get to an answer efficiently, but does reduce the number of operations required.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P014Page',
  components: {
  },
  data() {
      return {
        recursive_collatz: `// Recursively returns the length of the Collatz sequence starting at x.
fun collatzLength(x: Int): Int {
  if (x == 1) return 1
  return 1 +
      if (x.and(1) == 1) collatzLength(3*x + 1)
      else collatzLength(x / 2)
}`,
        skipping_collatz: `// Recursively returns the length of the Collatz sequence starting at x.
fun collatzLength(x: Int): Int {
  if (x == 1) return 1
  return
      if (x.and(1) == 1) 2 + collatzLength(3*x + 1)
      else 1 + collatzLength(x / 2)
}`
    }
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

<template>
  <div id='problem'>
    <h2>Powerful Digit Sum</h2>
    <a href="https://projecteuler.net/problem=56">https://projecteuler.net/problem=56</a>
    <h3>Thoughts</h3>
    <p>
      Big integers go brrrr (again).
    </p>
    <p>
      Given that we want to maximize digits, it is probably safe to set high minimums (like, 85+ ?) and skip all
      <katex-span expr="(a, b)" /> where either is lower than that. Also obviously we can skip all <katex-span expr="a" />
      ending in 0.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P056Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

<template>
  <div id='problem'>
    <h2>Counting Sundays</h2>
    <a href="https://projecteuler.net/problem=19">https://projecteuler.net/problem=19</a>
    <h3>Thoughts</h3>
    <p>
      Keep track of the date mod 7, add the length of the month, account for leap years, blah blah blah.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P019Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

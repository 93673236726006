<template>
  <div id='problem'>
    <h2>Integer Right Triangles</h2>
    <a href="https://projecteuler.net/problem=39">https://projecteuler.net/problem=39</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Tree_of_primitive_Pythagorean_triples">Wiki: tree of primitive
          Pythagorean triples</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      Two approaches, one easy to write, one better for later problems. I wrote the brute force solution when I was
      originally attempting this problem and it works fine, but I found out later that it is possible to directly generate
      Pythagorean triples. The latter approach scales significantly better.
    </p>
    <h3>Brute force</h3>
    <p>
      The obvious brute force approach is basically: for each possible perimeter, loop over the possible leg lengths,
      calculate the hypotenuse from them, and tally up the valid triples.
    </p>
    <p>
      The relationships between the sides of right triangles allow us to nicely restrict the set of triples we need to
      check for each possible perimeter length. The main optimizations to me are:
    </p>
    <ol>
      <li>The hypotenuse is less than the sum of the other two sides.
        <ul>
          <li>Implying we can break when <katex-span expr="c < a+b" /></li>
        </ul>
      </li>
      <li>One leg is shorter than the other.
        <ul>
          <li>Implying we can WLOG assume <katex-span expr="a < b" />, and avoid dealing with duplicates.</li>
        </ul>
      </li>
      <li>The shorter leg must be less than a third of the perimeter.</li>
      <li>The longer leg must be less than half of the perimeter.</li>
    </ol>
    <p>
      These restrictions give us some bounds that keep the <katex-span expr="O(n^3)" /> runtime of the aproach manageable
      (though we'd be screwed if the limit we need to check were any higher).
    </p>
    <highlightjs language="kotlin" :code="bf" />
    <h3>Pythagorean triple tree</h3>
    <p>
      There are a few ways of generating Pythagorean triples, but applying linear transformations is probably the easiest.
      Check the wiki link above for full explanation and proofs. The central idea is that we can multiply a column vector
      of a Pythagorean triple on the left by a well-chosen matrix to create a new vector preserving the property that
      <katex-span expr="a^2 + b^2 = c^2" />. Wiki provides the following three matrices:
    </p>
    <katex-div expr="
          A = \begin{bmatrix} 1 & -2 & 2 \\ 2 & -1 & 2 \\ 2 & -2 & 3 \end{bmatrix},
          B = \begin{bmatrix} 1 & 2 & 2 \\ 2 & 1 & 2 \\ 2 & 2 & 3 \end{bmatrix},
          C = \begin{bmatrix} -1 & 2 & 2 \\ -2 & 1 & 2 \\ -2 & 2 & 3 \end{bmatrix}" />
    <p>
      Applying all of these matrices repeatedly on the triangle <katex-span expr="(3, 4, 5)" /> eventually generates all
      primitive triples. So for our problem, we use these matrices to generate triples until we hit triangles with
      perimeter > 1000. Once we have all the primitive triangles, we can go through them and multiply the side lengths to
      generate the non-primitive triples of appropriate perimeter. Tally up the most common perimeters from there, and
      we're all done. As far as I know this is the fastest way to find every Pythagorean triple.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P039Page',
  components: {
  },
  data() {
    return {
      bf: `// Count up the Pythagorean triples with perimeter p
var count = 0
for (a in 3..(p/3)) {
  for (b in a + 1..(p / 2)) {
    val c = p - a - b
    if (c < b) break
    if (a + b < c) continue
    if (a*a + b*b == c*c) count++
  }
}`
    }
  }
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}</style>

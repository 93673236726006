<template>
  <div id='problem'>
    <h2>Lattice Paths</h2>
    <a href="https://projecteuler.net/problem=15">https://projecteuler.net/problem=15</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Lattice_path">Wiki: lattice paths</a></li>
      <li><a href="https://en.wikipedia.org/wiki/Binomial_coefficient">Wiki: binomial coefficient</a></li>
      <li><a href="https://en.wikipedia.org/wiki/Pascal%27s_triangle">Wiki: Pascal's triangle</a></li>
    </ul>
    <h3>Thoughts</h3>
    <img id="diagram" src="@/assets/LatticePaths.png"
      alt="Diagram showing the number of paths to each point in a 6x6 grid" />
    <p>
      Counting paths on a lattice is a classic introduction to combinatorics, and a good chance to discuss binomial
      coefficients.
    </p>
    <h3>Approaches</h3>
    <h4>Directly counting</h4>
    <p>
      One option is to explicitly enumerate the number of routes. This winds up being pretty computationally expensive,
      but we can do this for a smaller problem to get inspiration. The diagram on the Project Euler problem page is
      actually super helpful here for the 2x2 case. For reference, we'll enumerate the points from left to right and top
      to bottom starting from 0. So the starting point is (0, 0), and the point at the upper right corner is (2, 0).
    </p>
    <p>
      With that notation out of the way, we can count how many paths go through each point on the grid:
    </p>
    <table>
      <colgroup>
        <col class="skinny" />
        <col class="wide" />
      </colgroup>
      <tr>
        <th>point</th>
        <th># paths through</th>
      </tr>
      <tr>
        <td><katex-span expr="(1, 0)" /></td>
        <td><katex-span expr="1" /></td>
      </tr>
      <tr>
        <td><katex-span expr="(2, 0)" /></td>
        <td><katex-span expr="1" /></td>
      </tr>
      <tr>
        <td><katex-span expr="(0, 1)" /></td>
        <td><katex-span expr="1" /></td>
      </tr>
      <tr>
        <td><katex-span expr="(1, 1)" /></td>
        <td><katex-span expr="2" /></td>
      </tr>
      <tr>
        <td><katex-span expr="(2, 1)" /></td>
        <td><katex-span expr="3" /></td>
      </tr>
      <tr>
        <td><katex-span expr="(0, 2)" /></td>
        <td><katex-span expr="1" /></td>
      </tr>
      <tr>
        <td><katex-span expr="(1, 2)" /></td>
        <td><katex-span expr="3" /></td>
      </tr>
      <tr>
        <td><katex-span expr="(2, 2)" /></td>
        <td><katex-span expr="6" /></td>
      </tr>
    </table>
    <p>
      Which may be easier to process graphically, where each square in the following table corresponds to a point in the
      2x2 grid:
    </p>
    <table>
      <colgroup>
        <col class="even" />
        <col class="even" />
        <col class="even" />
      </colgroup>
      <tr>
        <td><katex-span expr="start" /></td>
        <td><katex-span expr="1" /></td>
        <td><katex-span expr="1" /></td>
      </tr>
      <tr>
        <td><katex-span expr="1" /></td>
        <td><katex-span expr="2" /></td>
        <td><katex-span expr="3" /></td>
      </tr>
      <tr>
        <td><katex-span expr="1" /></td>
        <td><katex-span expr="3" /></td>
        <td><katex-span expr="6" /></td>
      </tr>
    </table>
    <p>
      Looking at this table, it jumps out to us that the count in each cell is the sum of the cells immediately above and
      left of it. We can treat everything outside of the grid as a 0, and use this relation to implement an iterative
      solution.
    </p>
    <h4>Binomial coefficients</h4>
    <p>
      While that solution is great, we should probably try to understand where the recurrence is coming from. Logically,
      if we can only move through the lattice by stepping down and right, then every path to a point in the lattice has to
      pass through the point above or to the left.
    </p>
    <p>
      Stepping back, it may help to think of a path as a sequence of steps right or down. So the path which hugs the top
      then drops down the right side of the lattice can be written as RRDD: 2 steps right followed by 2 steps down. If we
      spell out all the paths like this, we immediately see that each of them consists of 2 Rs and 2Ds in some order. And
      in fact, when we write out those paths we enumerate every string that consists of 2 Rs and 2 Ds.
    </p>
    <p>
      With that in mind we can instead address the problem in 2 steps. Step 1: figure out how many steps we need to take
      to the Right, and how many steps we need to take Down. Step 2: figure out how many ways there are of writing out
      those steps.
    </p>
    <p>
      The former is clear: the number of steps right and down match the grid dimensions (2 in the example, 20 in the
      problem). The latter is classic combinatorics. We have a string of length 4 (or 40), of which 2 (or 4) characters
      need to be Rs.
    </p>
    <p>
      If all the characters were distinct, there would be <katex-span expr="4! = 4*3*2*1" /> ways of making the string,
      but since the Rs and Ds are all interchangeable we need to divide to account for duplicates. If we focus on the Rs
      only, they can be ordered in <katex-span expr="2! = 2*1 = 2" /> ways. Since the same goes for the Ds, this leaves us
      with:
    </p>
    <katex-div expr="\frac{4!}{2! 2!} = \frac{4*3*2}{2*2} = 6" />
    <p>
      As PE stated. This way of counting arrangements is common enough to have a shorthand expression which can be read as
      "n choose k", with the resulting numbers often called "binomial coefficients" from their relation to the binomial
      theorem:
    </p>
    <katex-div expr="\binom{n}{k} = \frac{n!}{k!(n-k)!} = \prod_{i=1}^{k} \frac{n-i+1}{i}" />
    <p>
      Famously, these binomial coefficients also arise in Pascal's triangle — the analog between this problem and
      Pascal's triangle should become clear if you rotate the above grid <katex-span expr="45\degree" />. Notably, we
      should remember the following relationship between a point in the triangle and the row above it since it comes up
      frequently (and we derived it earlier):
    </p>
    <katex-div expr="\binom{n}{k} = \binom{n-1}{k-1} + \binom{n-1}{k}" />
  </div>
</template>

<script>

export default {
  name: 'P015Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}

img#diagram {
  width: 700px;
  height: 700px;
}

table {
  text-align: center;
  table-layout: fixed;
  border: 1px solid;
  border-collapse: collapse;
  margin-bottom: 3rem;
}

.skinny {
  width: 8rem;
}

.wide {
  width: 15rem;
}

.even {
  width: 5rem;
}

th,
td {
  border: 1px solid;
}
</style>

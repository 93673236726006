<template>
  <div id='problem'>
    <h2>Path Sum: Three Ways</h2>
    <a href="https://projecteuler.net/problem=82">https://projecteuler.net/problem=82</a>
    
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Heap_(data_structure)">Wiki: heap (data structure)</a></li>
      <li><a href="https://en.wikipedia.org/wiki/Dijkstra%27s_algorithm">Wiki: Dijkstra's algorithm</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      Same idea as <a href="/projects/euler/81">the previous problem</a>, but with the wrinkle that we can go up and down,
      and start from anywhere on the left. The same sort of approach works here with a small tweak. Instead of passing
      through the matrix once, we now need to support going up/down multiple times. To do this, we can either try going
      up/down repeatedly as we traverse across the columns, or we can go through a loop of testing all moves up, right,
      and down in a cycle until we hit stability.
    </p>
    <h3>Another (better) approach: Dijkstra's algo</h3>
    <p>
      Dijkstra's algorithm came up in the prior problem, and it is arguably even more applicable here. The fact that we
      can go up and down for this variant means that we can't just linearly pass through one time to find an optimal
      solution. However, we still only need to check each point in the matrix once if we use Dijkstra.
    </p>
    <p>
      We can do this by using a heap (Java's implementation is confusingly called <a
        href="https://docs.oracle.com/en/java/javase/20/docs/api/java.base/java/util/PriorityQueue.html">PriorityQueue</a>,
      and is internally implemented with a priority heap). A min-heap allows us to keep track of which nodes we've visited
      so far that have the lowest cost associated with them. Then we can pop (poll?) from the heap to get our next node to
      check, see if we can update any of it's neighbors, and add them to the heap when updated. This is an efficient way
      to implement Dijkstra's algorithm: the min value in the heap cannot possibly get a lower cost, since any cheaper
      path would have to go through more expensive nodes to reach it. So if we are storing our costs and minimal paths in
      a couple of 2D arrays, the main loop looks roughly like this:
    </p>
    <highlightjs language="kotlin" :code="heap" />
    <p>
      If we really want a minimal solution, we can also exit the loop as soon as we hit the rightmost column. Since we're
      always working from the minimal cost paths, as soon as we reach the right we know it is the lowest cost way there.
    </p>
    <p>
      There is a slight twist here in that we have to initialize the heap to contain the indices of each entry in the
      first column, but beyond that there is little else to do. Note that this solution would work just as well for the
      previous problem, requiring only that we change the initial entries in the heap, and we change the neighbors to
      check.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P082Page',
  components: {},
  data() {
    return {
      heap: `while (heap.isNotEmpty()) {
  val (r, c) = heap.poll()
  // Easy way to define the neighbors
  for ((dr, dc) in listOf(Pair(-1, 0), Pair(1, 0), Pair(0, 1))) {
    // Check that neighbor is not out of bounds
    if (r + dr in pathSums.indices && c + dc in pathSums.first().indices &&
      pathSums[r + dr][c + dc] > costs[r + dr][c + dc] + pathSums[r][c]
    ) {
      pathSums[r + dr][c + dc] = costs[r + dr][c + dc] + pathSums[r][c]
      heap.add(Pair(r + dr, c + dc))
    }
  }
}`
    }
  }
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

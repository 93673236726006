<template>
  <div id='problem'>
    <h2>Number Letter Counts</h2>
    <a href="https://projecteuler.net/problem=17">https://projecteuler.net/problem=17</a>
    <h3>Thoughts</h3>
    <p>
      Very mechanical. You can be smart with some loops here, but there isn't anything interesting to discuss.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P017Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

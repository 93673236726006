<template>
  <div id='problem'>
    <h2>Factorial Digit Sum</h2>
    <a href="https://projecteuler.net/problem=20">https://projecteuler.net/problem=20</a>
    <h3>Thoughts</h3>
    <p>
      Flashback to <a href="/projects/euler/16">problem 16</a>. Another case where if you want to use a big integer class
      there is really no work to do here, though if you already implemented your own multiplication/addition with a list
      class then you can apply it here.
    </p>
    <p>
      One could make this a little easier by noting that you can ignore all the trailing zeros, which can be found by
      pairing up the 5s and 2s in the factorial. There should be a total of 24, so this reduces the storage and
      computation cost only a little in practice.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P020Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

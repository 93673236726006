<template>
  <div id='problem'>
    <h2>Counting Rectangles</h2>
    <a href="https://projecteuler.net/problem=85">https://projecteuler.net/problem=85</a>
    <h3>Resources</h3>
    <h3>Thoughts</h3>
    <p>
      So the immediate question is to figure out how many rectangles there are in a grid based on the grid's dimensions. A
      rectangle is formed by two parallel lines running vertically, and two parallel lines running horizontally. So the
      number of rectangles is equivalent to the number of ways of picking those lines.
    </p>
    <p>
      Let's say that the grid is <katex-span expr="m" /> squares wide by <katex-span expr="n" /> squares tall. Then there
      are <katex-span expr="m+1" /> vertical lines and <katex-span expr="n+1" /> horizontal lines in the grid, and we need
      to pick two of each. As we saw in <a href="/projects/euler/53">problem 53</a> we have a compact notation for this,
      and the total way of picking the lines is simply:
    </p>
    <katex-span expr="\binom{m+1}{2} \binom{n+1}{2} = \frac{m(m+1)}{2} \frac{n(n+1)}{2} = \frac{m(m+1)n(n+1)}{4}" />
    <p>
      Always good to sanity check, and we can quickly verify that this works for the trivial case of a 1 by 1 grid since
      <katex-span expr="\binom{2}{2}*\binom{2}{2} = 1" />, and that the problem's example 3 by 2 is also satisfied by
      <katex-span expr="\binom{4}{2} \binom{3}{2} = 6*3 = 18" />.
    </p>
    <p>
      So with a quick way of counting up the number of rectangles in a grid, we then have to find the grid which will get
      us closest to 2,000,000 rectangles. We can define the upper bound for grid size as the smallest length <katex-span
        expr="x" /> such that <katex-span expr="\binom{x+1}{2} \geq 2*10^6" />, then loop over all combinations of
      <katex-span expr="m, n" /> such that <katex-span expr="1 \leq m < n \leq x" />. This is extremely simple to write,
      and while it is an <katex-span expr="O(n^2)" /> algorithm, the upper bound is only in the thousands so it should be
      plenty fast. We could also cache the binomial terms, though the number is still small enough where this isn't
      significantly impactful.
    </p>
    <h3>Getting to <katex-span expr="O(n)" /></h3>
    <p>
      It doesn't matter here, but we can achieve an <katex-span expr="O(n)" /> algorithm by instead starting with
      <katex-span expr="m=1, n=x" />, and cleverly iterating. For example, suppose that <katex-span
        expr="\binom{m+1}{2} \binom{n+1}{2} > 2*10^6" />. Then increasing either of <katex-span expr="m, n" /> will yield
      a number further away from our target, so we should instead decrement <katex-span expr="n" /> until the number of
      rectangles is less than our target. And once we reach that point the opposite is true — we need to increase
      <katex-span expr="m" /> to bring us closer to the goal.
    </p>
    <p>
      That gives us a simple algorithm where we iteratively increase <katex-span expr="m" /> or decrease <katex-span
        expr="n" /> depending on the number of rectangles in our grid, and can stop once the two side lengths are equal.
      This means that we are doing at most <katex-span expr="x" /> iterations, an overall algorithm of <katex-span
        expr="O(n)" />.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P085Page',
  components: {}
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

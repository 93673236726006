<template>
  <div id='problem'>
    <h2>Prime Pair Sets</h2>
    <a href="https://projecteuler.net/problem=60">https://projecteuler.net/problem=60</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Depth-first_search">Wiki: depth-first search</a></li>
    </ul>
    <h3>Thoughts</h3>
    <!-- TODO: add an animation showing the DFS for a smaller version of the problem (set size 3 maybe?) -->
    <p>
      Back to the more difficult problems, where we finally get to talk about depth first search (DFS). Interesting
      challenge with no obvious shortcuts. We can break this down into a few major components to solve.
    </p>
    <ol>
      <li>Getting all the (necessary primes).</li>
      <li>Finding compatible pairs of primes.</li>
      <li>Building a minimal set of 5 from the pairs.</li>
    </ol>
    <h3>Primes to consider</h3>
    <p>
      Obviously we need to build a set large enough to hold all the primes which are candidates for our group of five.
      However, we need to keep in mind that to check that a pair of primes is compatible, we need to concatenate them.
      That means we need to test primality for numbers double the length of our maximum prime we're considering.
    </p>
    <p>
      Unfortunately, I don't see a great way to bound the set of primes to consider. It's probably best to just start with
      a low threshold (say 1000? which would mean we need primes through 10001000 for checking) and increment the
      threshold until we find the solution.
    </p>
    <h3>Prime pair compatibility</h3>
    <p>
      Assuming we generated a large enough set to check the max concatenated results against, this part is fairly
      straightforward. We just need to consider all pairs of primes in our lower range, concatenate them each way, and
      look them up in the list. This winds up being <katex-span expr="O(n^2)" />, which is expensive but not prohibitive
      on most computers if the max of our prime range is somewhere in the thousands.
    </p>
    <h3>Searching</h3>
    <p>
      Once we have all the pairs of compatible primes, we need to combine them to form a set of 5 which are mutually
      compatible. To attack this problem, we can treat it as a graph-construction problem.
    </p>
    <p>
      With this perspective, the nodes are the prime numbers, and the edges are connections indicating compatibility under
      concatenation. From there, the problem is equivalent to finding a fully-connected subgraph of size 5. The key
      reasoning is that once we pick a starting node, we use the edges from there to find candidates directly, and
      traverse until either (1) the sum of nodes we're checking exceeds our best graph so far or (2) we run out of
      potential neighbors to check.
    </p>
    <p>
      Note that we prefer depth-first search here because we want to find solutions quickly to enable pruning. To that
      end, we should always explore the smallest neighbors first before considering larger ones. We really need to be able
      to break quickly because the performance of the search is bounded by the number of edges, which is at worst
      <katex-span expr="O(n)" /> (n being the number of primes), making the DFS <katex-span expr="O(n^5)" />. Pruning does
      a lot of work!
    </p>
  </div>
</template>

<script>

export default {
  name: 'P060Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

<template>
  <div id='problem'>
    <h2>10001st prime</h2>
    <a href="https://projecteuler.net/problem=7">https://projecteuler.net/problem=7</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Prime_number">Wiki: prime number</a></li>
      <li><a href="https://en.wikipedia.org/wiki/Sieve_of_Eratosthenes">Wiki: sieve of Eratosthenes</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      And we're back to prime numbers. The main issue we have now is efficiently checking if many numbers are prime rather
      than checking only a single number. Seems like a good opportunity to talk about prime sieving.
    </p>
    <h3>Sieving</h3>
    <p>
      The sieve of Eratosthenes is a famous way of quickly finding all the prime numbers up to some limit. It works by
      iterating from the start of the range to the end, identifying primes, and then "crossing off" all the multiples of
      that prime. Starting from the smallest numbers means that we quickly eliminate the vast majority of numbers, and
      means that (by construction) any number we reach which has not already been removed is automatically prime. No need
      for trial division! It helps to see a visual of the algorithm in practice, so here is a demonstration for 1-100:
    </p>
    <canvas id="sieve" width="575" height="575"></canvas>
    <p>
      The main use here is to get all primes in a set, but the general idea is worth knowing so it can be modified where
      appropriate. For this problem, a simple approach could be to write a sieve, find all primes up to some number, then
      count until the 10001st. However, we don't need to find every prime — we can stop as soon as we hit the number we
      care about.
    </p>
    <p>
      The same strategy can be used to find the prime factorization of a large set of numbers very quickly, or we can use
      it to generate a set of primes to speed up trial division prime-testing for other numbers. There are also a few
      micro optimizations that can be made to speed up the base algorithm. A nice variant on the sieve of Eratosthenes is
      the sieve of Euler, where instead of checking each multiple we only check multiples of numbers which have not
      already been eliminated — bringing algorithmic efficiency down significantly if we're clever with how we keep track
      of the other numbers.
    </p>
    <p>
      Sieving and prime number generation come up frequently in PE, so it's a good idea to write a reusable and adaptable
      implementation.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P007Page',
  components: {
  },
  data() {
    return {
      rectDim: 50,
      padding: 5,
      sieveStatus: {
        state: "initial",
        boxes: [],
        prime: null,
        multiples: [],
        wait: 0,
      }
    };
  },
  mounted() {
    const sieveCanvas = document.getElementById("sieve");
    this.resetSieveCanvas(sieveCanvas);
    setInterval(this.draw, 750);
  },
  methods: {
    initCanvas: function (c, rows, cols) {
      var ctx = c.getContext("2d");
      ctx.clearRect(0, 0, 575, 575);
      ctx.font = "20px Courier New";
      ctx.textAlign = "center";

      var x = 10;
      var y = x;
      let boxes = []
      for (let row = 1; row <= rows; row++) {
        for (let col = 1; col <= cols; col++) {
          let r = new Path2D();
          let coords = { x: x + this.padding, y: y + this.padding };
          r.rect(coords.x, coords.y, this.rectDim, this.rectDim);
          ctx.stroke(r);
          let num = (10 * (row - 1) + col);
          ctx.strokeText(num.toString(), coords.x + 25, coords.y + 32, this.rectDim);
          boxes.push({ rect: r, num: num, coords: coords });

          x += this.rectDim + this.padding;
        }
        x = 10;
        y += this.rectDim + this.padding;
      }
      return boxes;
    },
    resetSieveCanvas: function (canvas) {
      let sieveBoxes = this.initCanvas(canvas, 10, 10);
      const one = sieveBoxes.shift();
      canvas.getContext("2d").clearRect(one.coords.x, one.coords.y, this.rectDim, this.rectDim);
      this.sieveStatus = {
        state: "initial",
        boxes: sieveBoxes,
        prime: null,
        multiples: [],
        wait: 0,
      };
    },
    highlightPrime: function (ctx) {
      // highlight the first prime
      let p = this.sieveStatus.boxes.at(0);
      this.sieveStatus.prime = p.num;
      ctx.clearRect(p.coords.x, p.coords.y, this.rectDim, this.rectDim);
      ctx.fillStyle = 'red';
      ctx.fill(p.rect);
      ctx.strokeText(p.num.toString(), p.coords.x + 25, p.coords.y + 32, this.rectDim);
      this.sieveStatus.state = "lit-prime";

    },
    highlightMultiples: function (ctx) {
      this.sieveStatus.state = "lit-multiples";
      for (let i = 0; i < this.sieveStatus.boxes.length; i++) {
        const box = this.sieveStatus.boxes.at(i);
        if (box.num != this.sieveStatus.prime && ((box.num % this.sieveStatus.prime) == 0)) {
          this.sieveStatus.multiples.push(box);
          ctx.clearRect(box.coords.x, box.coords.y, this.rectDim, this.rectDim);
          ctx.fillStyle = 'salmon';
          ctx.fill(box.rect);
          ctx.strokeText(box.num.toString(), box.coords.x + 25, box.coords.y + 32, this.rectDim);
        }
      }
    },
    clearMultiples: function (ctx) {
      for (let i = 0; i < this.sieveStatus.multiples.length; i++) {
        const box = this.sieveStatus.multiples[i];
        ctx.clearRect(box.coords.x, box.coords.y, this.rectDim, this.rectDim);
        const idx = this.sieveStatus.boxes.indexOf(box);
        this.sieveStatus.boxes.splice(idx, 1);
      }
      if (this.sieveStatus.multiples.length == 0) {
        this.sieveStatus.state = "done";
      } else {
        this.sieveStatus.state = "initial";
      }
      this.sieveStatus.multiples = []
      const lastPrime = this.sieveStatus.boxes.shift();
      ctx.clearRect(lastPrime.coords.x, lastPrime.coords.y, this.rectDim, this.rectDim);
      ctx.stroke(lastPrime.rect);
      ctx.strokeText(lastPrime.num.toString(), lastPrime.coords.x + 25, lastPrime.coords.y + 32, this.rectDim);
      this.sieveStatus.prime = null;
    },
    waitAndReset: function (canvas) {
      if (this.sieveStatus.wait < 5) {
        this.sieveStatus.wait++;
      } else {
        this.resetSieveCanvas(canvas);
      }
    },
    draw: function () {
      const sieveCanvas = document.getElementById("sieve");
      let ctx = sieveCanvas.getContext("2d");
      switch (this.sieveStatus.state) {
        case "initial":
          this.highlightPrime(ctx);
          break;
        case "lit-prime":
          this.highlightMultiples(ctx);
          break;
        case "lit-multiples":
          this.clearMultiples(ctx);
          break;
        default:
          this.waitAndReset(sieveCanvas);
          break;
      }
    },
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}

table {
  text-align: center;
  table-layout: fixed;
  border: 1px solid;
  border-collapse: collapse;
  margin-bottom: 3rem;
}

.skinny {
  width: 5rem;
}

.wide {
  width: 15rem;
}

th,
td {
  border: 1px solid;
}

canvas {
  border: 1px solid #000000;
}
</style>

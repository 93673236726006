<template>
  <div id='problem'>
    <h2>Maximum Path Sum II</h2>
    <a href="https://projecteuler.net/problem=67">https://projecteuler.net/problem=67</a>
    <h3>Thoughts</h3>
    <p>
      The long promised sequel to <a href="/projects/euler/18">problem 18</a>. I already went over the solution there, so
      no need to repeat it here.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P067Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

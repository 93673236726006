<template>
  <div id='problem'>
    <h2>Square Root Digital Expansion</h2>
    <a href="https://projecteuler.net/problem=80">https://projecteuler.net/problem=80</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Newton%27s_method">Wiki: Newton's method</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      There are a few ways to go about this one, I went with using Newton's method to find the roof of the equation
      <katex-span expr="y = x^2 - a" />. Newton's method is a root-finding algorithm, where all we need to have is some
      guess, and to be able to take the derivative of the function. Thankfully simple here:
    </p>
    <katex-div expr="\frac{dy}{dx} = \frac{d}{dx}(x^2 - a) = 2x" />
    <p>
      Newton's method is to successively evaluate:
    </p>
    <katex-div expr="x_{n+1} = x_n - \frac{f(x_n)}{f\prime(x_n)}" />
    <p>
      We can always use 1 as an initial guess, and we know <katex-span expr="f\prime(x) = 2x" /> so this simplifies to:
    </p>
    <katex-div expr="x_{n+1} = x_n - \frac{f(x_n)}{f\prime(x_n)} = x_n - \frac{x_n^2 - a}{2x_n}" />
    <katex-div expr="\implies x_{n+1} = \frac{x_n^2 + a}{2x_n}" />
    <p>
      From there we repeatedly apply Newton's method until convergence (using BigDecimal, which is a pain) and skip any
      numbers which are squares themselves.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P080Page',
  components: {},
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

<template>
  <div id='problem'>
    <h2>Right Triangles with Integer Coordinates</h2>
    <a href="https://projecteuler.net/problem=91">https://projecteuler.net/problem=91</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Dot_product#Geometric_definition">Wiki: Dot Product</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      We can come at this using the definition of a line, or go slightly higher level and take the angle between vectors.
      First, the line-based approach.
    </p>
    <h3>Intersecting lines</h3>
    <p>
      Since we have three points, we can consider any pair of them to make a line that is one side of the triangle. We
      then find the slope of these lines and check that they are at right angles. The classic definition of a line is:
    </p>
    <katex-div expr="y = mx + b" />
    <p>
      Where <katex-span expr="m" /> is the slope of the line and <katex-span expr="b" /> is the y-axis intercept. Two
      lines with slopes <katex-span expr="m_1, m_2" /> are perpendicular when <katex-span expr="m_1 = \frac{-1}{m_2}" />.
      This follows from the definition of slope as change in <katex-span expr="y" /> over change in <katex-span
        expr="x" />. So if we have points <katex-span expr="p_1 = (x_1, y_1), p_2 = (x_2, y_2), p_3 = (x_3, y_3)" />, then
      the lines <katex-span expr="p_1p_2, p_2 p_3" /> have slopes:
    </p>
    <katex-div expr="m_{1,2} = \frac{y_2-y_1}{x_2 - x_1}, m_{2,3} = \frac{y_3-y_2}{x_3 - x_2}" />
    <p>
      So we have perpendicular lines whenever:
    </p>
    <katex-div expr="m_{1,2} = \frac{-1}{m_{2,3}} \implies \frac{y_2-y_1}{x_2 - x_1} = -1 * \frac{x_3 - x_2}{y_3-y_2}" />
    <katex-div expr="\implies (y_2-y_1)(y_3-y_2) = -(x_2-x_1)(x_3-x_2)" />
    <p>
      Note that breaking apart the slopes into the deltas is also a little easier from a programming perspective, as we do
      not need to do anything special to handle lines which are parallel to the y-axis.
    </p>
    <h3>Vectors</h3>
    <p>
      The vector approach is essentially the same at a higher level of abstraction. The important thing to know is that
      the dot-product of two vectors has a geometric meaning capturing the angle between the vectors:
    </p>
    <katex-div expr="a \cdot b = \|a\|\|b\|cos(\theta)" />
    <p>
      And we have a right angle when <katex-span expr="\theta = 90\degree \implies cos(\theta) = 0" />. Which can only
      happen when either vector has 0 magnitude (they're the same point, so we ignore these) or when the dot-product is 0.
      Working out the math with the definition of a 2D vector will yield the same result as above.
    </p>
    <h3>Final steps</h3>
    <p>
      So when we have three points we can check the angles between them are right using that formula. All that is left
      then is to generate the set of points, loop over the pairs of points to form triangles with the origin, and check
      which ones have a right angle. Since there are only <katex-span expr="50*50 - 1 = 2499" /> points to consider
      excluding the origin, this <katex-span expr="O(n^2)" /> algorithm should be perfectly sufficient.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P091Page',
  components: {},
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

<template>
  <div id='problem'>
    <h2>Coin Sums</h2>
    <a href="https://projecteuler.net/problem=31">https://projecteuler.net/problem=31</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Change-making_problem">Wiki: change-making problem</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      Another classic problem frequently used as an intro to dynamic programming. Can also be solved with recursion
      (improved by memoization), but DP is the most efficient and well-known way so we'll look at that.
    </p>
    <h3>Two-dimensional DP</h3>
    <p>
      The main insight here — which I don't think we've encountered yet-- is that there are two dimensions to consider
      when constructing the cases. In addition to knowing the number of ways to make change for an amount, we also need to
      know what coins we've used in that calculation.
    </p>
    <p>
      For instance, let's pretend that we've only kept track of the number of ways of making change up to some amount
      <katex-span expr="k" /> pence, and we want to figure out how many ways there are of making change equal to
      <katex-span expr="k+1" />. Normally, we'd do something like this:
    </p>
    <katex-div expr="ways(k+1) = ways(k+1 - 1) + ways(k+1 - 2) + ways(k+1 - 5) + ... + ways(k+1 - 200)" />
    <br>
    <katex-div expr="\implies ways(k+1) = \displaystyle\sum_{p \in \lbrace coins \rbrace}ways(k+1 - p)" />
    <p>
      But since we haven't kept track of which coins were used to make the prior sums, we are duplicating results. For
      instance, <katex-span expr="ways(k+1-2)" /> will include the case where we make change with <katex-span
        expr="k-1" /> 1p coins, and <katex-span expr="ways(k+1-1)" /> will include the case where we make change with
      one 2p coin, and <katex-span expr="k-2" /> 1p coins. Adding a 2p coin to the first way and a 1p coin to the second
      results in the same set of coins!
    </p>
    <p>
      As noted, we can work around this by tracking which coins we've used so far. In particular, if we build from the
      smallest coin denomination up to the largest we can additionally minimize storage space by re-using the same array
      and sweeping through it for each denomination of coins.
    </p>
    <p>
      Functionally, this algorithm is equivalent to adding a second parameter to the ways function tracking the maximum
      coin used so far, so we have:
    </p>
    <katex-div expr="ways(k+1, p) = \displaystyle\sum_{q \in \lbrace coins \rbrace, q \leq p}ways(k+1 - q)" />
  </div>
</template>

<script>

export default {
  name: 'P031Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

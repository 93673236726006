<template>
  <div id='problem'>
    <h2>Passcode Derivation</h2>
    <a href="https://projecteuler.net/problem=79">https://projecteuler.net/problem=79</a>
    <h3>Thoughts</h3>
    <p>
      Nice to get a break from the partition function! The key is simple, and there are few enough codes that this is
      another problem where doing it by hand is likely easiest. That said, writing code to replicate the by hand behavior
      isn't terrible.
    </p>
    <p>
      After looking through the keylogs, I made one big assumption in solving this: that no digit repeats. If there was a
      repetition then this approach wouldn't work. The main idea is that we can place the characters successively from the
      start to the end. First thing we do: look through all the keylogs and identify the set of characters in the
      password. This leaves us with:
    </p>
    <katex-div expr="\Set{ 0, 1, 2, 3, 6, 7, 8, 9 }" />
    <p>
      So we want to place one of these. Since we're assuming no duplicate characters, we can place the first character by
      looking through the characters, then seeing which one only appears at the beginning of a code, or does not appear in
      the code at all. Once we've found that character, we remove it from all of the codes, and repeat the process for
      each character until we've exhausted our set.
    </p>
    <p>
      This process could go in the opposite direction (from end to beginning) just as easily.
    </p>
    <h3>Demo</h3>
    <div id="demo">
      <p>(drag and drop to re-order appropriately)</p>
      <div id="dragzone">
        <draggable
          :list="list"
          :disabled="!enabled"
          item-key="name"
          class="list-group"
          ghost-class="ghost"
          @start="dragging = true"
          @end="onListUpdate"
        >
          <template #item="{ element }">
            <div class="list-group-item" :class="{ 'not-draggable': !enabled }">
              {{ element.name }}
            </div>
          </template>
        </draggable>
      </div>
      <table>
        <tr>
          <td class="keylog">319</td>
          <td class="keylog">680</td>
          <td class="keylog">180</td>
          <td class="keylog">690</td>
          <td class="keylog">129</td>
          <td class="keylog">620</td>
          <td class="keylog">762</td>
          <td class="keylog">689</td>
          <td class="keylog">318</td>
          <td class="keylog">368</td>
          <td class="keylog">710</td>
        </tr>
        <tr>
          <td class="keylog">720</td>
          <td class="keylog">629</td>
          <td class="keylog">168</td>
          <td class="keylog">160</td>
          <td class="keylog">716</td>
          <td class="keylog">731</td>
          <td class="keylog">736</td>
          <td class="keylog">729</td>
          <td class="keylog">316</td>
          <td class="keylog">769</td>
          <td class="keylog">290</td>
        </tr>
        <tr>
          <td class="keylog">719</td>
          <td class="keylog">389</td>
          <td class="keylog">162</td>
          <td class="keylog">289</td>
          <td class="keylog">718</td>
          <td class="keylog">790</td>
          <td class="keylog">890</td>
          <td class="keylog">362</td>
          <td class="keylog">760</td>
          <td class="keylog">380</td>
          <td class="keylog">728</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: 'P079Page',
  components: {
    draggable,
  },
  data() {
    return {
      enabled: true,
      list: [
        { name: "0", id: 0 },
        { name: "1", id: 1 },
        { name: "2", id: 2 },
        { name: "3", id: 3 },
        { name: "6", id: 6 },
        { name: "7", id: 7 },
        { name: "8", id: 8 },
        { name: "9", id: 9 },
      ],
      dragging: false
    }
  },
  mounted() {
    this.onListUpdate();
  },
  methods: {
    onListUpdate: function() {
      this.dragging = false;
      let code = this.list.map(x => x.name).join('');
      document.getElementsByClassName("keylog").forEach(element => {
        let c0Idx = code.indexOf(element.innerText[0]);
        let c1Idx = code.indexOf(element.innerText[1]);
        let c2Idx = code.indexOf(element.innerText[2]);
        if (c0Idx < c1Idx && c1Idx < c2Idx) {
          element.style.color = "green";
        } else {
          element.style.color = "red";
        }
      });
    }
  }
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}

div#demo {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
}

td {
  padding: 1rem;
}

/* Style for draggable taken from https://github.com/SortableJS/vue.draggable.next/blob/master/example/components/transition-example-2.vue */
.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
  float: left;
  padding: 2rem;
  border: 1px;
  border-style: double;
}

.list-group-item i {
  cursor: pointer;
}
</style>

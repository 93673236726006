<template>
  <div id='problem'>
    <h2>Largest Product in a Series</h2>
    <a href="https://projecteuler.net/problem=8">https://projecteuler.net/problem=8</a>
    <h3>Historical note</h3>
    <p>Apparently this problem was updated in 2014 to require 13 digits rather than 5.</p>
    <h3>Thoughts</h3>
    <p>
      Not much to say about this one. We can make some small improvements, but it is largely mechanical. The brute force
      approach is to iterate through the indices, multiplying out every 13 digits in a row to find the largest product.
      We could be more efficient with a "sliding window" style algorithm, though the data is small enough that it doesn't
      really matter. Some easy gains:
    </p>
    <ul>
      <li>
        <p>Skip ahead whenever we encounter a zero.</p>
      </li>
      <li>
        <p>When we slide the window, divide by the removed element then multiply by the added one.</p>
      </li>
    </ul>
    <p>
      This latter point is the crux of most sliding window algorithms, taking performance from <katex-span
        expr="O(n * k)" /> to <katex-span expr="O(n)" />. Beyond that, I'm not sure what else to say.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P008Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}

table {
  text-align: center;
  table-layout: fixed;
  border: 1px solid;
  border-collapse: collapse;
  margin-bottom: 3rem;
}

.skinny {
  width: 5rem;
}

.wide {
  width: 15rem;
}

th,
td {
  border: 1px solid;
}
</style>

<template>
  <div id='problem'>
    <h2>Smallest Multiple</h2>
    <a href="https://projecteuler.net/problem=5">https://projecteuler.net/problem=5</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Least_common_multiple">Wiki: least common multiple</a></li>
      <li><a href="https://en.wikipedia.org/wiki/Greatest_common_divisor">Wiki: greatest common divisor</a></li>
      <li><a href="https://en.wikipedia.org/wiki/Euclidean_algorithm">Wiki: Euclidean algorithm</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      Nice to see a problem that can be done by hand! We're asked to find the least common multiple (commonly abbreviated
      as lcm) of the numbers 1 through 20. There are a few common ways of computing lcm, so let's go through them.
    </p>
    <h3>LCM</h3>
    <h4>Using prime factorization</h4>
    <p>
      So, what is the relationship between <katex-span expr="a, b" /> and <katex-span expr="lcm(a,b)" />? By definition,
      the lcm is the smallest number that can be evenly divided by each of <katex-span expr="a" /> and <katex-span
        expr="b" />. This means it is a multiple of each of them, and that every factor of these numbers must also be a
      factor of the lcm.
    </p>
    <p>
      Going back to <a href="/projects/euler/3">problem 3</a>, we know that every positive integer has a unique prime
      factorization. Since the lcm is a multiple, each element of the prime factorizations for <katex-span expr="a, b" />
      must therefore also divide the lcm. Furthermore, the lcm must have no additional prime factors beyond those present
      in <katex-span expr="a, b" /> — otherwise we could just divide by that factor and get a smaller multiple.
    </p>
    <p>
      From that, we can directly construct the lcm by considering the factorizations of <katex-span expr="a, b" /> and
      picking the largest powers of each prime present in the factorizations. Let's walk through a simple example:
    </p>
    <katex-div expr="a = 12 = 2^2 * 3^1" />
    <katex-div expr="b = 14 = 2^1 * 7^1" />
    <p>
      In this case, we see that 2, 3, and 7 must all be factors of the lcm. Just multiplying these primes together won't
      get us there though: <katex-span expr="2*3*7 = 42" /> which cannot be divided evenly by <katex-span expr="12" />.
    </p>
    <p>
      Since there is a factor of <katex-span expr="2^2 = 4" /> in 12, we need to multiply again by 2 to get the true lcm
      of <katex-span expr="84" />. This method is very quick and easy for a human to do, and applied here enables us to
      calculate the answer for our original problem by hand by combining the factorizations of each number:
    </p>
    <table>
      <colgroup>
        <col class="skinny" />
        <col class="wide" />
      </colgroup>
      <tr>
        <th><katex-span expr="n" /></th>
        <th>prime factorization</th>
      </tr>
      <tr>
        <th><katex-span expr="1" /></th>
        <th><katex-span expr="1" /></th>
      </tr>
      <tr>
        <th><katex-span expr="2" /></th>
        <th><katex-span expr="2^1" /></th>
      </tr>
      <tr>
        <th><katex-span expr="3" /></th>
        <th><katex-span expr="3^1" /></th>
      </tr>
      <tr>
        <th><katex-span expr="4" /></th>
        <th><katex-span expr="2^2" /></th>
      </tr>
      <tr>
        <th><katex-span expr="5" /></th>
        <th><katex-span expr="5^1" /></th>
      </tr>
      <tr>
        <th><katex-span expr="6" /></th>
        <th><katex-span expr="2^1 * 3^1" /></th>
      </tr>
      <tr>
        <th><katex-span expr="7" /></th>
        <th><katex-span expr="7^1" /></th>
      </tr>
      <tr>
        <th><katex-span expr="8" /></th>
        <th><katex-span expr="2^3" /></th>
      </tr>
      <tr>
        <th><katex-span expr="9" /></th>
        <th><katex-span expr="3^3" /></th>
      </tr>
      <tr>
        <th><katex-span expr="10" /></th>
        <th><katex-span expr="2^1 * 5^1" /></th>
      </tr>
      <tr>
        <th><katex-span expr="11" /></th>
        <th><katex-span expr="11^1" /></th>
      </tr>
      <tr>
        <th><katex-span expr="12" /></th>
        <th><katex-span expr="2^2 * 3^1" /></th>
      </tr>
      <tr>
        <th><katex-span expr="13" /></th>
        <th><katex-span expr="13^1" /></th>
      </tr>
      <tr>
        <th><katex-span expr="14" /></th>
        <th><katex-span expr="2^1 * 7^1" /></th>
      </tr>
      <tr>
        <th><katex-span expr="15" /></th>
        <th><katex-span expr="3^1 * 5^1" /></th>
      </tr>
      <tr>
        <th><katex-span expr="16" /></th>
        <th><katex-span expr="2^4" /></th>
      </tr>
      <tr>
        <th><katex-span expr="17" /></th>
        <th><katex-span expr="17^1" /></th>
      </tr>
      <tr>
        <th><katex-span expr="18" /></th>
        <th><katex-span expr="2^1 * 3^2" /></th>
      </tr>
      <tr>
        <th><katex-span expr="19" /></th>
        <th><katex-span expr="19^1" /></th>
      </tr>
      <tr>
        <th><katex-span expr="20" /></th>
        <th><katex-span expr="2^2 * 5^1" /></th>
      </tr>
    </table>
    <p>
      Stepping through those and taking the highest power of each prime factor should give us our answer:
    </p>
    <katex-div expr="lcm(1,2,3,...,20) = 2^4 * 3^2 * 5^1 * 7^1 * 11^1 * 13^1 * 17^1 * 19^1" />
    <p>
      The above approach is nice and easy for small numbers, but when we work with larger numbers it can be expensive to
      find the prime factorization. Fortunately, there are other ways of finding the lcm that don't require factorizing
      every number.
    </p>
    <h4>Using GCD</h4>
    <p>
      Going back to our 12, 14 example, one may notice that just multiplying the numbers together gets us a multiple, even
      if it isn't the smallest one. This multiple is also clearly related to the factorization of 12 and 14:
    </p>
    <katex-div expr="12 * 14 = 168 = 2 * 84 = 2 * lcm(12, 14)" />
    <p>
      So if we can identify this multiplicative factor, then we can immediately find the lcm through division. If we
      review the factorizations of 12 and 14 from above, it should be clear that the number 2 is special here because it
      is the <em>greatest common divisor</em> (or gcd) of 12 and 14. This yields a nice relationship:
    </p>
    <katex-div expr="lcm(a, b) = \frac{a * b}{gcd(a, b)} = a * \frac{b}{gcd(a, b)} = \frac{a}{gcd(a, b)} * b" />
    <p>
      We could find the gcd using the same style of prime factorization analysis (finding the least common prime powers
      instead), but that still means we need to factor the numbers. To skip over the factorization step, we can instead
      use the Euclidean algorithm to calculate gcd.
    </p>
    <p>
      The Euclidean algorithm is recursive, and works by repeatedly taking remainders of a pair of numbers until we find a
      value which leaves no remainder. It is straightforward to implement:
    </p>
    <highlightjs language="kotlin" :code="gcd" />
    <p>
      And has good average efficiency, coming in at <katex-span expr="O(h)" /> where h is the number of digits in the
      smaller number. Armed with this function, we can easily apply it to our original problem. All we need to do is loop
      between 1 and 20, each time multiplying by the new number and dividing by the gcd. No factorization required!
    </p>
  </div>
</template>

<script>

export default {
  name: 'P005Page',
  components: {
  },
  data() {
    return {
      gcd: `// Returns the greatest common divisor of a and b.
fun gcd(a: Int, b: Int): Int {
  // Make sure the first input is the larger.
  if (a < b) return gcd(b, a)
  if (b == 0) return a
  return gcd(b, a % b)
}`,
    }
  }
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}

table {
  text-align: center;
  table-layout: fixed;
  border: 1px solid;
  border-collapse: collapse;
  margin-bottom: 3rem;
}

.skinny {
  width: 5rem;
}

.wide {
  width: 15rem;
}

th,
td {
  border: 1px solid;
}
</style>

<template>
  <div id='problem'>
    <h2>Largest Palindrome Product</h2>
    <a href="https://projecteuler.net/problem=4">https://projecteuler.net/problem=4</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Divisibility_rule#11">Wiki: divisibility rule for 11</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      We're still well within the range of brute-force here, so that's what we should do first. Once that is done though,
      we can probably do some analysis to reduce the search space.
    </p>
    <h4>Brute force</h4>
    <p>
      We can attack from two directions: (1) multiply numbers and check if the result is palindromic or (2) scan through
      palindromes and see which ones can be made by multiplying two 3-digit numbers. The first one feels more direct, so
      we're going with that.
    </p>
    <highlightjs language="kotlin" :code="code" />
    <p>Where <code>isPalindrome</code> does what you'd expect.</p>
    <h3>Filtering possibilities</h3>
    <p>
      After solving problems I generally go through the solution thread on Project Euler about it, and in this case the
      answers there go way deeper than what I initially thought to do for this problem. You should always read the
      solution thread after solving a problem in Project Euler, they're full of good discussion, great explanations, and
      clever ideas you can apply elsewhere.
    </p>
    <p>
      Credit to the user Begoner for taking the following analysis and going even further with it. With that said, some
      initial bare bones analysis does go a long way here. Some observations:
    </p>
    <h4>We only care about the largest possibilities</h4>
    <p>
      So why bother with the small stuff? Ideally, the leading digit of the palindrome will be 9. This puts our floor at
      900,000 — which allows us to get a lower bound on candidate 3-digit factors: <katex-span
        expr="\lceil 900000 / 1000 \rceil = 900" />.
    </p>
    <p>
      Additionally, if the leading digit is 9 then so is the trailing digit (because... palindromes). Therefore, the
      numbers we're multiplying together must be able to yield a units digit of 9. This means that our trailing digits
      cannot be even, nor 5. These facts alone reduce our search space from 900*900 pairs to 40*40.
    </p>
    <h4>Divisibility by 11</h4>
    <p>
      The rule for divisibility by 11 is that the difference between the sums of alternating digits in a number must be
      divisible by 11. Since palindromes are... palindromic, any palindrome with an even number of digits will always have
      the same digits alternating even and odd spots. Therefore, any palindrome with an even number of digits will be
      divisible by 11.
    </p>
    <p>
      Therefore our desired palindrome is divisible by 11. Since 11 is prime, one of our 3-digit numbers must also be
      divisible by 11! Between this and focusing only on numbers between 900 and 1000 ending in an odd digit, there are
      only a handful of remaining possibilities to check.
    </p>
    <p>
      In the event that the answer does not have 9 as its leading digit we'll have to adjust, but we can apply the same
      logic to reduce our search space if needed.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P004Page',
  components: {
  },
  data() {
    return {
      code: `var best = -1
for (i in 101..999) for j in (i..999) {
  val x = i*j
  if (x > best && isPalindrome(x)) best = x
}

// More functional-style approach
(101..999).flatMap { a -> (a..999).map { b -> a * b} }
    .filter { isPalindrome(it) && it < 1_000_000 }
    .max()`
    }
  }
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}

table {
  text-align: center;
  table-layout: fixed;
  width: 30%;
  border: 1px solid;
  border-collapse: collapse;
  margin-bottom: 3rem;
}

th,
td {
  border: 1px solid;
}</style>

<template>
  <div id='problem'>
    <h2>Path Sum: Two Ways</h2>
    <a href="https://projecteuler.net/problem=81">https://projecteuler.net/problem=81</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Shortest_path_problem">Wiki: shortest path problem</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      More dynamic programming, this time it is the classic problem of finding minimal cost of traversing a graph. In this
      case we can define the subproblem like "what is the minimal cost to traverse the matrix from <katex-span
        expr="(0, 0)" /> to <katex-span expr="(i, j)" />?", define the cost of a matrix entry as <katex-span
        expr="c(i, j)" />, and the minimal cost of a path to <katex-span expr="(i, j)" /> as <katex-span
        expr="M(i, j)" />. Since we can only go down or to the right, we can express <katex-span expr="M(i, j)" /> wholly
      in terms of the cost matrix and related paths:
    </p>
    <katex-div expr="M(i, j) = c(i, j) + min(M(i-1, j), M(i, j-1))" />
    <p>
      This should be clear since any path reaching <katex-span expr="(i, j)" /> must go through either <katex-span
        expr="(i-1, j)" /> or <katex-span expr="(i, j-1)" />. With that relationship defined, we can read along the rows
      of the matrix in order and calculate minimal paths to each point as we do so. We then just need to be careful to
      handle calculating the cost for points on the left and top edge of the matrix.
    </p>
    <h3>Shortest path problem</h3>
    <p>
      The algorithm described above is isomorphic to applying Dijkstra's algorithm for solving the shortest path problem.
      This is because the matrix can be transformed into a directed graph where each entry in the matrix is a node with
      edges pointing to the matrix entries right and below the current entry/node. Dijkstra's algorithm generalizes better
      to graphs without such nice structures, and is essential for other problems.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P081Page',
  components: {},
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

<template>
  <div id='problem'>
    <h2>Singular Integer Right Triangles</h2>
    <a href="https://projecteuler.net/problem=75">https://projecteuler.net/problem=75</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Pythagorean_triple#Generating_a_triple">Wiki: generating a Pythagorean
          triple</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      The main idea here has already been mentioned in <a href="/projects/euler/9">problem 9</a> and <a
        href="/projects/euler/39">problem 39</a> — we can directly generate the primitive Pythagorean triples using
      either Euclid's formula or by constructing the triple tree via matrix transformations.
    </p>
    <p>
      Either approach works, we just need to take care to include the non-primitive triangles as well by multiplying each
      primitive triangle's sides until the perimeter reaches L. If we use the Euclid's formula we need to be sure we
      exclude <katex-span expr="(m, n)" /> where <katex-span expr="gcd(m, n) = 1" /> to avoid duplication (or we can stick
      the triples in a set and de-dupe).
    </p>
  </div>
</template>

<script>

export default {
  name: 'P075Page',
  components: {},
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

<template>
  <div id='problem'>
    <h2>Arithmetic Expressions</h2>
    <a href="https://projecteuler.net/problem=93">https://projecteuler.net/problem=93</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Reverse_Polish_notation">Wiki: Reverse Polish notation</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      The only real way I know to do this is via brute force, checking all possibilities for all sets of four digits. One
      could use postfix notation (also known as reverse polish notation) to simplify implementing arithmetic precedence.
      Beyond that, it is important to be aware that fractional intermediate results must be accounted for, e.g.
      <katex-span expr="((1 \div 2) + 3) * 4 = 14" />.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P092Page',
  components: {},
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

<template>
  <div id='problem'>
    <h2>Even Fibonacci Numbers</h2>
    <a href="https://projecteuler.net/problem=2">https://projecteuler.net/problem=2</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Fibonacci_sequence">Wiki: Fibonacci sequence</a></li>
      <li><a href="https://en.wikipedia.org/wiki/Recursion_(computer_science)">Wiki: Recursion (computer science)</a></li>
      <li><a href="https://en.wikipedia.org/wiki/Dynamic_programming">Wiki: dynamic programming</a></li>
      <li><a href="https://en.wikipedia.org/wiki/Golden_ratio">Wiki: golden ratio</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      Four million is, again, a small number. Small enough that this could be solved by hand if one wished, so even a lazy
      implementation will solve it. This is a good opportunity to talk about generating Fibonacci numbers though.
    </p>
    <h3>Ways to calculate <katex-span expr="F(N)"/></h3>
    <h4>Bad: recursively</h4>
    <p>
      It is possible to directly calculate the Fibonacci sequence using a recursive approach, and this may be the first
      instinct for some people:
    </p>
    <highlightjs language="kotlin" :code="basic" />
    <p>
      Unfortunately, this approach is not going to scale sufficiently. While this does use a constant amount of storage,
      the runtime complexity is <katex-span expr="O(2^n)" />. This may be easiest to see by tracing through the call sequence,
      and realizing that it forms a tree (roughly) doubling in width at each level.
    </p>
    <img class="recfib" src="@/assets/RecursiveFibonacciDrawing.png"
      alt="Google Drawing displaying the call tree of the recursive fibonacci function." />
    <p>
      As an aside, the number of calls to <katex-span expr="fib(x)" /> with this approach scales in a familiar way:
    </p>
    <table>
      <tr>
        <th><katex-span expr="n" /></th>
        <th>calls to <katex-span expr="f(1)" /></th>
      </tr>
      <tr>
        <th><katex-span expr="1" /></th>
        <th><katex-span expr="1" /></th>
      </tr>
      <tr>
        <th><katex-span expr="2" /></th>
        <th><katex-span expr="1" /></th>
      </tr>
      <tr>
        <th><katex-span expr="3" /></th>
        <th><katex-span expr="2" /></th>
      </tr>
      <tr>
        <th><katex-span expr="4" /></th>
        <th><katex-span expr="3" /></th>
      </tr>
      <tr>
        <th><katex-span expr="5" /></th>
        <th><katex-span expr="5" /></th>
      </tr>
      <tr>
        <th><katex-span expr="6" /></th>
        <th><katex-span expr="8" /></th>
      </tr>
      <tr>
        <th><katex-span expr="..." /></th>
        <th><katex-span expr="..." /></th>
      </tr>
    </table>
    <h4>Good: memoization</h4>
    <p>
      While naive recursion is inefficient, it should be clear that we can get around this by caching or memoizing the
      intermediate values. That is, when we calculate a term in the sequence for the first time, we store that in a map
      for quick lookup later. Then when we find ourselves making other recursive calls, we first check to see if we've
      found the result before entering the recursion.
    </p>
    <highlightjs language="kotlin" :code="with_cache" />
    <p>
      This is an extremely common pattern and the foundation of many dynamic programming solutions. Keep in mind what data
      structure you're using to cache results, as poor choice of structure may negatively impact performance. In this
      case, we could as easily use an array instead of a map.
    </p>
    <h4>Good: direct iteration</h4>
    <p>
      We can avoid the repetition by instead working our way up from <katex-span expr="fib(0)" /> and <katex-span
        expr="fib(1)" /> to the term we care about. This is the classic way of doing it:
    </p>
    <highlightjs language="kotlin" :code="iterative" />
    <p>
      Taking this approach brings us down to an <katex-span expr="O(n)" /> algorithm for calculating all the Fibonacci
      numbers up to the Nth, which is great if we actually want all those intermediate values.
    </p>
    <h4>Direct calculation</h4>
    <p>
      So what do we do if we <i>don't</i> need or want all the intermediate values? It turns out there is a closed-form
      expression for the Nth term in the Fibonacci sequence (and, in fact, for any linear recurrence with constant
      coefficients). This is known as Binet's formula, and it is expressed in terms of the golden number <katex-span
        expr="\phi" />.
    </p>
    <katex-div expr="F_n = \frac{\phi^\mathit{n} - (1-\phi)^\mathit{n}}{\sqrt{5}}" />
    <p>
      This gives us an explicit process for calculating individual Fibonacci terms without the prior terms! As an example,
      we could apply this knowledge to the problem at hand by identifying which terms in the sequence are even (every third
      one) and only calculating those.
    </p>
    <p>
      Keep in mind — while this is direct, it still requires <katex-span expr="O(N)"/> multiplications. With that in mind,
      it may not practically be faster than iterative construction, and may be limited by floating point precision for
      sufficiently large <katex-span expr="n"/>. Nonetheless, this formula is useful to know — and the method to derive
      it can unlock solutions to other, similar problems requiring direct calculation of particular terms in a sequence.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P002Page',
  components: {
  },
  data() {
    return {
      basic: `// Recursively calculates the Nth Fibonacci number
fun fib(n: Int): Long {
  if (n < 0) error("Invalid Fibonacci index") // usually
  if (n < 2) return 1L
  return fib(n-1) + fib(n-2)
}`,
      with_cache: `// Cache to store results
val fs = mutableMapOf<Int, Long>()

fun fib(n: Int): Long {
  // Check if we have done this before, return if so.
  if (fs.contains(n)) return fs[n]!!

  if (n < 0) error("Invalid Fibonacci index")
  if (n < 2) return 1L
  // Make sure to store the result before returning!
  val x = fib(n-1) + fib(n-2) 
  fs[n] = x
  return x
}`,
      iterative: `// Iteratively calculate the Nth Fibonacci number
var f0 = 1
var f1 = 1
repeat (n-1) {
  val t = f0
  f0 = f1
  f1 = t + f0
}`,
    }
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}

table {
  text-align: center;
  table-layout: fixed;
  width: 30%;
  border: 1px solid;
  border-collapse: collapse;
  margin-bottom: 3rem;
}

th,
td {
  border: 1px solid;
}
</style>

<template>
  <div id='problem'>
    <h2>Diophantine Equation</h2>
    <a href="https://projecteuler.net/problem=66">https://projecteuler.net/problem=66</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Diophantine_equation">Wiki: Diophantine equation</a></li>
      <li><a href="https://en.wikipedia.org/wiki/Pell%27s_equation">Wiki: Pell's equation</a></li>
      <li><a href="https://en.wikipedia.org/wiki/Methods_of_computing_square_roots#Continued_fraction_expansion">Wiki:
          methods of computing square roots — continued fraction expansion</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      A legitimately hard problem. The main trouble is that there doesn't seem to be a way to bound the minimal solution
      until one is found, and we have to consider potentially huge ranges of values for x and y. There is some niche math
      which helps here though. The Diophantine equations here are actually all examples of Pell's equation (linked above).
      This is a common equation and variations of it show up frequently in Project Euler problems. Time to get familiar
      with it.
    </p>
    <h3>Solving Pell's equation</h3>
    <p>
      In a thematic twist, the solution to Pell's equation involves continued fractions. Specifically, one of the pairs of
      numerator and denominator in the sequence of convergents for <katex-span expr="\sqrt{D}" /> is the fundamental
      solution. So this problem is actually closely tied to <a href="/projects/euler/57">problem 57</a> and <a
        href="/projects/euler/65">problem 65</a> — though in this case we are not provided with the expansion and must
      therefore calculate it ourselves.
    </p>
    <p>
      The wiki link above defines the procedure for calculating it, so I won't rehash it fully. The main point here is
      that following that procedure, we can iteratively generate convergents, which we can test to see if they satisfy the
      Pell's equation. Since the numerator and denominator of the convergents always grow, we can be sure that as soon as
      we find an answer it is the minimal one.
    </p>
    <p>
      Beyond that, the only other thing to note is that we should skip <katex-span expr="D" /> which are themselves
      square, since they are obviously solved by the solution <katex-span expr="(x, y) = (\sqrt{D}, 1)" /> and trying to
      calculate the continued fraction will lead to a bad time.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P066Page',
  components: {
  },
  data() {
    return {
      multiline: ``
    }
  }
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

<template>
  <div id='problem'>
    <h2>Maximum Path Sum I</h2>
    <a href="https://projecteuler.net/problem=18">https://projecteuler.net/problem=18</a>
    <h3>Resources</h3>
    <ul>
      <li><a
          href="https://en.wikipedia.org/wiki/Dynamic_programming#Dijkstra's_algorithm_for_the_shortest_path_problem">Wiki:
          dynamic programming</a></li>
      <li><a href="https://en.wikipedia.org/wiki/Dijkstra%27s_algorithm">Wiki: Dijkstra's algorithm</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      The explicit note politely saying "you're welcome to brute force this but you'll be screwed later" is pretty
      amusing. Might as well talk about the "clever method" now, which is a fundamental graph theory algorithm.
    </p>
    <h3>General approach</h3>
    <canvas id="dijkstra" width="575" height="575"></canvas>
    <p>
      The above animation shows an efficient way to solve this, working through the first 9 rows.
    </p>
    <p>
      The key insight is that we don't need to directly trace every possible path. Instead, we can build up paths from the
      root by keeping track of the largest total we've seen at each point in the triangle. That is what is happening in
      the above animation. If you've been doing these problems in order, this may seem reminiscent of calculating the rows
      of Pascal's triangle from <a href="/projects/euler/15">problem 15</a>. Though in this case we're considering the
      pairs of values from above, and combining the larger with the current value rather than just summing the above pair.
    </p>
    <p>
      This is a classic dynamic programming approach. We're identifying the optimal answer to a sub-problem, and using
      that result to compute the next step. Thus saving us the effort of considering every possible path through the
      sub-problem again in subsequent steps.
    </p>
    <h3>Graph theory</h3>
    <p>
      Notably, with some minor tweaks we can also treat this problem as an example of Dijkstra's algorithm. If we reframe
      the problem as traversing a graph trying to find the maximum cost path, and apply a greedy heuristic to choose which
      edge to check next, then the algorithm maps nicely. Dijkstra's algorithm should be one of the first things you
      consider when you see a graph and have to find the max/min path through it.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P018Page',
  components: {
  },
  data() {
    return {
      padding: 5,
      rectDim: 50,
      triangle: [
        [75],
        [95, 64],
        [17, 47, 82],
        [18, 35, 87, 10],
        [20, 4, 82, 47, 65],
        [19, 1, 23, 75, 3, 34],
        [88, 2, 77, 73, 7, 63, 67],
        [99, 65, 4, 28, 6, 16, 70, 92],
        [41, 41, 26, 56, 83, 40, 80, 70, 33],
      ],
      rowIdx: 0,
      colIdx: 0,
      state: "none",
      boxes: null,
      wait: 5,
    }
  },
  mounted() {
    const canvas = document.getElementById("dijkstra");
    this.resetCanvas(canvas);
    setInterval(this.draw, 700);
  },
  methods: {
    initCanvas: function (c) {
      var ctx = c.getContext("2d");
      ctx.clearRect(0, 0, 575, 575);
      ctx.font = "20px Courier New";
      ctx.textAlign = "center";

      var x = 10;
      var y = x;
      let boxes = []
      for (let row = 0; row < this.triangle.length; row++) {
        x = 27.5 * (this.triangle.length - row) + 10;
        boxes.push([]);
        for (let col = 0; col < this.triangle[row].length; col++) {
          let r = new Path2D();
          let coords = { x: x + this.padding, y: y + this.padding };
          r.rect(coords.x, coords.y, this.rectDim, this.rectDim);
          ctx.stroke(r);
          let num = this.triangle[row][col];
          ctx.strokeText(num.toString(), coords.x + 25, coords.y + 32, this.rectDim);
          boxes[boxes.length - 1].push({ rect: r, contents: num, coords: coords })
          x += this.rectDim + this.padding;
        }
        x = 10;
        y += this.rectDim + this.padding;
      }
      return boxes;
    },
    resetCanvas: function (canvas) {
      this.boxes = this.initCanvas(canvas);
      this.rowIdx = 0;
      this.colIdx = 0;
      this.state = "none";
      this.wait = 5;
    },
    updateBox: function (ctx, box, color, content) {
      ctx.clearRect(box.coords.x - 1, box.coords.y - 1, this.rectDim + 2, this.rectDim + 2);
      ctx.fillStyle = color;
      ctx.fill(box.rect);
      ctx.strokeText(content.toString(), box.coords.x + 25, box.coords.y + 32, this.rectDim);
    },
    highlightCurrent: function (ctx, color) {
      // highlight the first prime
      let b = this.boxes[this.rowIdx][this.colIdx];
      this.updateBox(ctx, b, color, b.contents)
      this.state = "current";
    },
    highlightAncestors: function (ctx) {
      // No ancestors to highlight in the first box, just move on.
      if (this.rowIdx + this.colIdx == 0) {
        this.highlightCurrent(ctx, 'lightgreen');
        this.state = "none";
        this.rowIdx = 1;
        return;
      }
      this.state = "current-and-ancestors";
      if (this.colIdx > 0) {
        let b = this.boxes[this.rowIdx - 1][this.colIdx - 1];
        this.updateBox(ctx, b, 'lightblue', b.contents)
      }
      if (this.colIdx != this.triangle[this.rowIdx - 1].length) {
        let b = this.boxes[this.rowIdx - 1][this.colIdx];
        this.updateBox(ctx, b, 'lightblue', b.contents)
      }
    },
    addAndUpdate: function (ctx) {
      let b = this.boxes[this.rowIdx][this.colIdx];
      let bigger = 0;
      if (this.colIdx < this.triangle[this.rowIdx - 1].length) {
        let upright = this.boxes[this.rowIdx - 1][this.colIdx];
        this.updateBox(ctx, upright, 'lightgreen', upright.contents);
        bigger = upright.contents;
      }
      if (this.colIdx > 0) {
        let upleft = this.boxes[this.rowIdx - 1][this.colIdx - 1]
        bigger = Math.max(bigger, upleft.contents);
        this.updateBox(ctx, upleft, 'lightgreen', upleft.contents);
      }
      b.contents += bigger;
      this.boxes[this.rowIdx][this.colIdx] = b;
      this.updateBox(ctx, b, 'lightgreen', b.contents);
      if (this.colIdx == this.triangle[this.rowIdx].length - 1) {
        this.rowIdx++;
        this.colIdx = 0;
      } else {
        this.colIdx++;
      }
      if (this.rowIdx == this.triangle.length) {
        this.state = "wait";
      } else {
        this.state = "none";
      }
    },
    waitAndReset: function (canvas) {
      if (this.wait > 0) {
        this.wait--;
      } else {
        this.resetCanvas(canvas);
      }
    },
    draw: function () {
      const canvas = document.getElementById("dijkstra");
      let ctx = canvas.getContext("2d");
      switch (this.state) {
        case "none":
          this.highlightCurrent(ctx, 'salmon');
          break;
        case "current":
          this.highlightAncestors(ctx);
          break;
        case "current-and-ancestors":
          this.addAndUpdate(ctx);
          break;
        default:
          this.waitAndReset(canvas);
          break;
      }
    },
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

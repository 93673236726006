<template>
  <div id='problem'>
    <h2>Ordered Fractions</h2>
    <a href="https://projecteuler.net/problem=71">https://projecteuler.net/problem=71</a>
    <h3>Thoughts</h3>
    <p>
      We obviously don't want to test every single fraction in the range. So how can we narrow it down?
    </p>
    <p>
      It should be clear that for a given denominator, there is only one numerator which will yield a fraction closest to
      <katex-span expr="\frac{3}{7}" />. Call the numerator and denominator <katex-span expr="n" /> and <katex-span
        expr="d" /> respectively. If we aim for equality, then <katex-span
        expr="\frac{3}{7} = \frac{n}{d} \implies n = \frac{3d}{7}" />. Since we want the greatest value less than
      <katex-span expr="\frac{3}{7}" />, we can take the floor of this to solve for the best value of <katex-span
        expr="n" /> to check: <katex-span expr="n\prime = \Big\lfloor \frac{3d}{7} \Big\rfloor" />.
    </p>
    <p>
      With that knowledge, we can then iterate over the possible denominators, and only need to reduce the final fraction
      if there is a common factor between numerator and denominator. Reducing the fraction means dividing by the gcd, so
      the final answer will be <katex-span expr="n\prime\prime = \frac{n\prime}{gcd(n\prime, d)}" />.
    </p>
    <p>
      As an addendum, there is an even more complete solution overview available once the problem is solved which nicely
      goes into limiting the range to check after finding some solution — something I hadn't originally considered.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P071Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

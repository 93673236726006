<template>
  <div id='problem'>
    <h2>Double-base Palindromes</h2>
    <a href="https://projecteuler.net/problem=36">https://projecteuler.net/problem=36</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Binary_number#Decimal_to_Binary">Wiki: converting from decimal to
          binary</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      Limit of 1M is again quite nice. Seems like we need some functions to check for palindromes.
    </p>
    <h3>Checking if a number is a palindrome</h3>
    <p>
      If we're representing numbers as strings, then comparing the string to its reversed string is sufficient. If we're
      storing numbers, then the fastest thing I'm aware of is to directly construct the reversed number and check
      equality:
    </p>
    <highlightjs language="kotlin" :code="reverse" />
    <p>
      That code assumes that the input is in base 10, it can be adapted to other bases (or to take a base) easily.
    </p>
    <h3>Base 2 conversion</h3>
    <p>
      The wiki link in the resources above tells us how to convert from decimal to binary. It is worth noting that there
      isn't anything special about base 2 for that algorithm, it will work equally well for other bases. It is also simple
      to implement, and can be expressed iteratively or recursively.
    </p>
    <h3>Restricting possibilities</h3>
    <p>
      As with prior problems, looping through the possible range is more than sufficient. And again (there's a theme here)
      if we want to be clever, we can try to restrict the set of numbers we need to check by directly generating the
      palindromes.
    </p>
    <p>
      In this case, we can note the fact that the base 2 palindrome always ends in a 1 — so the base 10 palindrome must
      be odd, and therefore end in one of 1, 3, 5, 7, 9. Furthermore, the palindromes are... palindromic, so setting the
      first (up to) 3 digits encodes the remaining digits. That leaves us with 5*10*10 ways of making five or six digit
      palindromes, 5*10 ways of three or four digits, and 5 ways of making one or two digit palindromes (enumerated in the
      problem description). So if we choose to take that road, there are only <katex-span
        expr="500*2 + 50*2 + 5*2 = 1110" /> palindromes in range we need to consider.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P036Page',
  components: {
  },
  data() {
    return {
      reverse: `fun reverseInt(x: Int): Int {
  var rx = 0
  var t = x
  while (t > 0) {
    rx *= 10
    rx += t % 10
    t /= 10
  }
  return rx
}`
    }
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

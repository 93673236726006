<template>
  <div id='problem'>
    <h2>Non-abundant Sums</h2>
    <a href="https://projecteuler.net/problem=23">https://projecteuler.net/problem=23</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Divisor_function">Wiki: divisor function (sigma)</a></li>
      <li><a href="https://leetcode.com/problems/two-sum/">LeetCode: Two Sum</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      Another application of the ideas from <a href="https://projecteuler.net/problem=21">problem 21</a> for calculating
      the sum of factors, though in this case we want to know the numbers where sum of factors is larger than the number
      itself.
    </p>
    <h3>Pairing abundant numbers</h3>
    <p>
      The main thing to note here is that we need to identify which numbers can or cannot be expressed as the sum of
      abundant numbers. So generally speaking, there are two ways to go about this.
    </p>
    <p>
      The first approach is to take the set of things we want to pair, and pair them all off. If we have a small set of
      these candidates then directly considering them is cheap and quick, and we find our answer by summing all the
      numbers outside the resulting set.
    </p>
    <p>
      The second approach goes the opposite direction. Take all the candidate numbers that might be the sum of two
      abundant numbers, and for each one of them loop through the abundant numbers and see if we can find a pair that sums
      to it. This is equivalent to the popular "Two Sum" problem that comes up in interviews (and much less often in real
      life...) which we can apply here by sticking the abundant numbers in a hashmap and looking up the differences.
    </p>
    <p>
      Given the low limit in this problem description, both approaches are sufficient. In general, choosing between these
      two is a matter of how many times you need to repeat the sub-problem of finding a pair that sums appropriately, how
      big the sets we're working with are, and whether there are any heuristics one can use to narrow down the candidates.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P023Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

<template>
  <div id='problem'>
    <h2>Pandigital Multiples</h2>
    <a href="https://projecteuler.net/problem=38">https://projecteuler.net/problem=38</a>
    <h3>Thoughts</h3>
    <p>
      And we're back to the fun problems. As usual we can make this easier on ourselves by analyzing the possibilities.
      Since 918273645 is provided, we know the answer is larger than that, which means the answer starts with a 9. So how
      many digits can be in the number we want to multiply? Let's call the starting number x.
    </p>
    <p>
      Suppose x has two digits. Then 2x has three digits, as does 3x, as does 4x. This means that if we concatenate them,
      we will have either an eight-digit number if we include only 1x, 2x, and 3x, but an eleven-digit number for
      1x2x3x4x. We need nine digits, so this won't work.
    </p>
    <p>
      Repeat that argument for three digits. Then 2x and 3x have four digits, so we're screwed again.
    </p>
    <p>
      However, when x is four digits then 2x is five digits, so we can concatenate them and get a nine-digit number. And
      it should be obvious that if x has five digits we're immediately at ten digits after one concatenation, so we know
      for sure that x is a four digit number.
    </p>
    <p>
      Furthermore, x must be greater than 9182 (based on the given example), and less than 9500 — otherwise 2x will start
      with "19" and we'll have two nines in our answer. But if x starts "94__" then 2x is either "188__" or "189__",
      either duplicating the 8 or 9. Similarly, we cannot include a 1 in x because 2x always starts with a 1.
    </p>
    <p>
      So at this point, x starts with a nine, which is followed by a two or three. 2x must start with one then eight,
      implying that the pandigital is of the form:
    </p>
    <katex-div expr="9 [2, 3] \_ \_ 1 8 \_ \_ \_" />
    <p>
      Where we still have to place 2,3,4,5,6,7 in the 6 empty slots.
    </p>
    <p>
      If we include a 5 in x, then 2x will contain either a 0 or 1, so the 5 must appear in the 2x part of the
      pandigital. And 2x is even, so it must end in one of 2, 4, 6 — implying that x ends in 6, 2, 3, or 7 respectively.
      So the restrictions tighten a little further to:
    </p>
    <katex-div expr="9 [2, 3] [2,3,4,6,7] [2,3,6,7] 1 8 [4,5,6,7] \_ [2,4,6]" />
    <p>
      At this point I just started trying the possibilities as the restrictions are very limiting. We can also note a
      couple of pairings to narrow it down further: if 7 is in 2x, then it must be in the same digit's place as 3
      appears in x. And the 5 in 2x must be paired with a 2 or 7 in x, with a 6 or 7 to the right of that in x.
    </p>
    <p>
      Of course, from a coding perspective it is easier to just loop from 9236 to 9376, test the possibilities, and call
      it a day.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P038Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

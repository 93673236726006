<template>
  <div id='problem'>
    <h2>Combinatoric Selections</h2>
    <a href="https://projecteuler.net/problem=53">https://projecteuler.net/problem=53</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Combination">Wiki: Combination</a></li>
      <li><a href="https://en.wikipedia.org/wiki/Binomial_coefficient">Wiki: binomial coefficient</a></li>
      <li><a href="https://en.wikipedia.org/wiki/Pascal%27s_triangle">Wiki: Pascal's triangle</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      Nice of them to introduce the notation for <katex-span expr="\binom{n}{r}" /> (read as "n choose r") and the
      associated formulas!
    </p>
    <h3>Pascal's Triangle</h3>
    <p>
      We went over the main ideas here in <a href="/projects/euler/15">problem 15</a>: the binomial coefficients form
      Pascal's triangle. So for this problem instead of manually calculating each term directly, we can take advantage of
      the triangle and construct it layer by layer. And to avoid overflow, whenever a value exceeds a million, we can mark
      all subsequent additions using that term as one million too.
    </p>
    <p>
      If we want to be extra clever we could also stop halfway through each row since the triangle is symmetrical (it
      should be clear that <katex-span expr="\binom{n}{k}" /> equals <katex-span expr="\binom{n}{n-k}" />), though the
      problem is simple enough there is little need to worry about this.
    </p>
    <p>
      Additionally, one could ignore the overflow concerns by using big integers. Even better, as soon as we hit a
      million, any results depending on that will also exceed one million, so we can stop calculating lower rows if we
      know how to add them up. Either way, pretty straightforward.
    </p>
    <h3>Efficiently computing <katex-span expr="\binom{n}{k}" /></h3>
    <p>
      Since we'll be doing it a lot, it is worth pointing out that we don't have to fully multiply out each part of the
      factorials here. In fact, we need to do at most <katex-span expr="k-1" /> multiplication and division operations,
      and we can keep the intermediate products relatively small by alternating multiplication and division. The simplest
      form of <katex-span expr="n" /> choose <katex-span expr="k" /> looks like:
    </p>
    <katex-div expr="\binom{n}{k} = \frac{n!}{k!(n-k)!}" />
    <p>
      For simplicity we can suppose that <katex-span expr="k \leq n-k" />. If it isn't we just flip the following anyway.
    </p>
    <katex-div
      expr="\frac{n!}{k!(n-k)!} = \frac{n(n-1)(n-2) \dots 2 * 1}{k(k-1)(k-2) \dots 2*1 * (n-k)(n-k-1) \dots 2*1}" />
    <br>
    <katex-div
      expr="=\frac{n(n-1)(n-2) \dots (n-k+1)(n-k)(n-k-1)\dots *2*1}{k(k-1)(k-2)\dots 2*1 * (n-k)(n-k-1)\dots *2*1}" />
    <br>
    <katex-div expr="=\frac{n(n-1)(n-2) \dots (n-k+2)(n-k+1)}{k(k-1)(k-2)\dots 2*1}" />
    <br>
    <katex-div expr="=\frac{n}{1} * \frac{n-1}{2} * \frac{n-2}{3} \dots \frac{n-k+2}{k-1} *\frac{n-k+1}{k}" />
    <p>
      Which we can simply implement (this does still run a high risk of overflow for any reasonably sized <katex-span
        expr="(n,k)" />):
    </p>
    <highlightjs language="kotlin" :code="nck" />
    <p>
      As far as I know this is the minimum possible approach using multiplication. And we can be assured that each of the
      divisions will go evenly into the product so far — since we're dividing the product of x consecutive numbers in the
      numerator by x, at least one of the integers in the product must be a multiple of x. No risk of fractional/decimal
      intermediate results here.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P053Page',
  components: {
  },
  data() {
    return {
      nck: `fun nChooseK(n: Long, k: Long): Long {
  // TODO: input validation goes here...
  var p = 1
  for (i in 1..k) {
    // TODO: check to avoid overflow before multiplying...
    p *= (n-i+1)
    p /= i
  }
  return p
}`
    }
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

<template>
  <div id='problem'>
    <h2>Square Digit Chains</h2>
    <a href="https://projecteuler.net/problem=92">https://projecteuler.net/problem=92</a>
    <h3>Thoughts</h3>
    <p>
      Same idea as <a href="/projects/euler/14">problem 14</a> about the Collatz conjecture. In this case we don't need to
      cache chain length, and can instead cache what the terminal state is. Technically we only need to store up through
      <katex-span expr="7*9^2 = 567" />, since the max sum of digits comes from <katex-span expr="10^7-1" />.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P092Page',
  components: {},
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

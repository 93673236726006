<template>
  <div id='problem'>
    <h2>Truncatable Primes</h2>
    <a href="https://projecteuler.net/problem=37">https://projecteuler.net/problem=37</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Truncatable_prime">Wiki: truncatable prime</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      I'm not sure how to prove there are only 11, except through proof by exhaustion. The central idea is that we can
      construct truncatable primes by adding digits to known truncatable primes (since a truncatable prime necessarily
      truncates to truncatable primes). As far as generating them, we can infer a few restrictions:
    </p>
    <ol>
      <li>The leading digit must itself be prime (2, 3, 5, 7)</li>
      <li>Even numbers and five cannot appear in the middle of the number (leaving 1,3,7,9)</li>
    </ol>
    <p>
      Unfortunately, we cannot construct the truncatables one digit at a time. For example, if we tried to prepend with 9
      by itself, we will never get a truncatable number because 9 is composite. However, 19 is a valid prime, so
      prepending with 19 (or 79) may yield a truncatable prime. This means if we want to construct them directly, we need
      to be able to add more than one digit at a time to the start of our number.
    </p>
    <p>
      I found this problem pretty annoying, and in practice I decided to guess an upper bound then use a sieve to get
      primes and then check which ones fit the criteria. This worked fine, and is what I'd recommend doing.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P037Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

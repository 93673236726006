<template>
  <div id='problem'>
    <h2>Product-Sum Numbers</h2>
    <a href="https://projecteuler.net/problem=88">https://projecteuler.net/problem=88</a>
    <h3>Thoughts</h3>
    <p>
      This is currently the hardest problem (by rating and number of people who have solved it) in the first 100. I don't
      think it is terribly hard to describe an approach that works, so I'm guessing that people get stuck on the
      implementation. The "obvious" way to go about this is to loop over all the numbers up to some limit, and for each
      number construct the prime factorization, then use that to build the factorizations.
    </p>
    <p>
      This is pretty complicated, and it can be easy to get the factorization construction down, so we should look for a
      better way. However, it is important to understand why the factorizations matter and how we can use them, so let's
      look at an example:
    </p>
    <h3>General procedure</h3>
    <katex-div expr="24=2^3*3" />
    <p>
      From this prime factorization we can build the following factorizations:
    </p>
    <katex-div expr="\Set{\Set{2,12}, \Set{2,2,6}, \Set{2,2,2,3}, \Set{2,3,4}, \Set{3,8}, \Set{4, 6}, \Set{24}}" />
    <p>
      Note that for each of those factorizations except the last one, the sum of the factors is less than the product.
      This follows pretty easily from the fact that <katex-span expr="a+b \leq a*b" /> whenever <katex-span
        expr="a, b > 1" />. So in any case where the factorization isn't the trivial one, because the product is greater
      than the sum we can create a product-sum tuple of factors by appending 1s until the sum matches our number. E.g.
      <katex-span expr="N=24" /> is a product-sum number with <katex-span expr="k=12" /> using the first factorization
      above:
    </p>
    <katex-div expr="12*2*1*1*1*1*1*1*1*1*1*1 = 12+2+1+1+1+1+1+1+1+1+1+1" />
    <p>
      Repeating this for the other factorizations, we find that <katex-span expr="24" /> is a product-sum number for each
      <katex-span expr="k \in \Set{12, 17, 19, 18, 15, 16}" />. So now we have a procedure for going from factorizations
      to the set of <katex-span expr="k" /> for which <katex-span expr="N" /> is a product-sum number.
    </p>
    <h3>Bounding the range</h3>
    <p>
      The procedure is all well and good, but we need to know what the maximum <katex-span expr="N" /> is that we'd need
      to check for any given <katex-span expr="k" />. Otherwise, how will we know that we've found a minimum solution? To
      figure out an upper bound, we want to find a number in terms of <katex-span expr="k" /> which is always
      a product-sum number with set size <katex-span expr="k" />.
    </p>
    <p>
      From the above procedure, we can see that a product-sum number set must always have at least two factors greater
      than 1. So a solution will consist of those two factors, along with <katex-span expr="k-2" /> ones. The worst case
      sum of the two other factors occur when one is as small as possible and one is as large as possible, which would
      mean that the smaller non-one factor is a two. Call the other factor <katex-span expr="x" />. Then:
    </p>
    <katex-div expr="2*x*1*\cdots*1 = x + 2 + 1 + \cdots + 1 = x + 2 + (k-2)" />
    <katex-div expr="\implies 2x = x + 2 + k-2" />
    <katex-div expr="\implies x = k" />
    <p>
      Which means that in the worst-case, the min product-sum number for <katex-span expr="k" /> is <katex-span
        expr="2k" />, so we only have to check the factorizations of numbers up to two times the limit (which is to say,
      <katex-span expr="24000" />).
    </p>
    <h3>Inverting the approach</h3>
    <p>
      Finding the prime factorizations isn't bad (use a sieve-like approach), but writing code to go from prime
      factorization to factorizations is a little complex. Ideally, we'd like to skip this step. So what can we do?
    </p>
    <p>
      Looking at the high-level idea, the only thing we really need are sets of factors, and for those factors to multiply
      to some product under our limit. If we've got such a set, then we can immediately find the <katex-span expr="k" />
      corresponding to the set. So rather than generating all prime factorizations then transforming them, we should
      strive to directly construct sets of numbers which yield products below our limit. This is a much simpler matter
      that can be done in a few ways — it could be done with a queue/stack, but I'm fond of recursion:
    </p>
    <highlightjs :code="recursiveminproductsums" />
    <p>
      In this snippet, <i>mins</i> is a list where <i>mins[k]</i> is the minimal solution found so far for <katex-span
        expr="k" />. The main idea is that we can generate all possible sets by multiplying the sets we've currently
      checked by each number greater than or equal to the largest factor in our set, and we don't need to track the set
      itself: the largest factor, product, sum, and number of factors is sufficient. Running this code with appropriate
      inputs and bounds takes about 30ms on my computer for the original problem's bounds.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P088Page',
  components: {},
  data() {
    return {
      recursiveminproductsums: `private fun buildMinProdSumNums(
    lim: Int, f: Int, p: Int, s: Int, factorCount: Int) {
  val k = p - s + factorCount
  if (k <= lim && mins[k] > p) mins[k] = p
  (max(f, 2)..2 * lim / p).forEach { f1 ->
    buildMinProdSumNums(lim, f1, p * f1, s + f1, factorCount + 1)
  }
}`
    }
  }
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

<template>
  <div id='problem'>
    <h2>Pentagon Numbers</h2>
    <a href="https://projecteuler.net/problem=44">https://projecteuler.net/problem=44</a>
    <h3>Thoughts</h3>
    <p>
      Building a hashset of the pentagonal numbers up to some reasonable bound is the simplest way to go about this. Then
      we can just do an <katex-span expr="O(n^2)" /> loop over the pairs of pentagonal numbers and check against the
      hashset. A more thorough proof that the answer is minimal is hard. I suppose we could go about it by finding an
      answer, and then checking up to the point where the gap between consecutive pentagonal numbers is larger than that.
      But eh.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P044Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

<template>
  <div id='problem'>
    <h2>Reciprocal Cycles</h2>
    <a href="https://projecteuler.net/problem=26">https://projecteuler.net/problem=26</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Repeating_decimal">Wiki: repeating decimal</a></li>
      <li><a href="https://en.wikipedia.org/wiki/Modular_arithmetic">Wiki: modular arithmetic</a></li>
      <li><a href="https://en.wikipedia.org/wiki/Multiplicative_order">Wiki: multiplicative order</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      Quick demo accepting inputs between 1 and 999:
    </p>
    <div>
      <label for="denInput">Denominator:</label>
      <input v-model.number="denominator" placeholder="" id="denInput" />
    </div>
    <button @click="repetitionLength = getRepetendLength(denominator)">Get Repetend Length</button>
    <textarea readonly v-model="repetitionLength"></textarea>
    <p>
      Cool problem with some depth behind it. Definitely worth taking a look through the linked wiki page, in particular
      reading the "Decimal expansion and recurring sequence" and "Other properties of repetend lengths" sections.
    </p>
    <h3>Long division</h3>
    <p>
      The most direct way I know to solve this is to calculate the repeating sequence for each fraction. So the question
      is, how do we know when the sequence is repeating?
    </p>
    <p>
      The sequence is repeating when we have some substring of the decimal representation that appears repeatedly (duh).
      This means we can check for repetition by looking at the trailing digits of the decimal (that we've found so far),
      counting back different lengths, and seeing if the substring prior to that is the exact same. This works, but we can
      simplify the check by thinking about how we generate the decimal from the fraction.
    </p>
    <p>
      In practice, what we're doing is long division. At each step in the division, we are left with a remainder. These
      remainders dictate the next digit in the division. That means that if we get the same remainder at different steps
      in the division, it will lead to the same digit being produced. Which means that as soon as we hit a repeated
      remainder, the pattern begins repeating. So if we want the length of the repeating part, we can simply count how
      many divisions occur between repeated remainders.
    </p>
    <h3>Modular Arithmetic</h3>
    <p>
      Focusing on the remainders allows us to rethink this problem as a series of transitions between the remainders. For
      example, suppose we want to find the repetend of <katex-span expr="\frac{1}{7}" />. Then each step of the division
      corresponds to multiplying by 10 and converting the result modulo 7, like the following sequence of operations:
    </p>
    <katex-div expr="1 * 10 = 10 = 1*7 + 3 \equiv 3 \mod 7" />
    <katex-div expr="3 * 10 = 30 = 4*7 + 2 \equiv 2 \mod 7" />
    <katex-div expr="2 * 10 = 20 = 2*7 + 6 \equiv 6 \mod 7" />
    <katex-div expr="6 * 10 = 60 = 8*7 + 4 \equiv 4 \mod 7" />
    <katex-div expr="4 * 10 = 40 = 5*7 + 5 \equiv 5 \mod 7" />
    <katex-div expr="5 * 10 = 50 = 7*7 + 1 \equiv 1 \mod 7" />
    <katex-div expr="\cdots" />
    <p>
      Where we loop once we hit a number mod 7 that we've already found. Note that when we express the intermediate result
      as <katex-span expr="ax+b" /> the sequence of <katex-span expr="a" />'s is exactly the repetend (142857). In this
      case since we're in base 7, multiplying by 10 is the same as multiplying by 3:
    </p>
    <katex-div expr="1 * 3 = 3 \equiv 3 \mod 7" />
    <katex-div expr="3 * 3 = 9 \equiv 2 \mod 7" />
    <katex-div expr="2 * 3 = 6 \equiv 6 \mod 7" />
    <katex-div expr="6 * 3 = 18 \equiv 4 \mod 7" />
    <katex-div expr="4 * 3 = 12 \equiv 5 \mod 7" />
    <katex-div expr="5 * 3 = 15 \equiv 1 \mod 7" />
    <katex-div expr="\cdots" />
    <p>
      Formulating the problem this way gives us a few nice insights.
    </p>
    <ol>
      <li>
        All <katex-span expr="n = 2^a5^b" /> have a terminating decimal (since at least one of either <katex-span
          expr="10^a" /> or <katex-span expr="10^b" /> is congruent to <katex-span expr="0 \mod n" />).
      </li>
      <li>
        Since there are n-1 nonzero numbers modulo n, the repetend length is at most n-1.
      </li>
      <li>
        Repetend length can only get to n-1 when n and 10 are relatively prime.
      </li>
      <li>
        Repetend length therefore depends on the relation between 10 and the prime factors of n.
      </li>
      <li>
        Repetend length is equal to the multiplicative order of 10 mod n, a.k.a <katex-span expr="ord_n(10)" />.
      </li>
    </ol>
    <p>
      These are key insights that will help with future problems. But for this problem the main points are (1), (2), and
      (3). Putting those together, we can quickly see that to optimally solve the problem we should skip all multiples of
      2 or 5, we should find repetend lengths starting from 999 on down, and we can stop as soon as we find a number whose
      repetend length is one less than that number — since either that is the answer, or we will already have found a
      repetend longer than it, and all smaller numbers must have shorter repetends.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P026Page',
  components: {
  },
  data() {
    return {
      denominator: null,
      repetitionLength: null,
    }
  },
  methods: {
    getRepetendLength(x) {
      if (x < 1 || isNaN(x)) return "Please input a positive integer.";
      if (x > 1000) return "Please keep input less than 1000."
      if (x == 1) return "Terminating decimal.";
      const m = new Map();
      let remainder = 1;
      let digit = 1;
      let zero = false;
      while (remainder > 0) {
        if (remainder === x) return "" + (1.0 / x) + "\n" + 1;
        if (remainder < x) {
          if (zero) {
            m.set(remainder, digit)
            digit++
          }
          remainder *= 10;
          zero = true;
        } else {
          remainder = remainder % x;
          if (m.has(remainder)) {
            return "Repeating decimal: " + (1.0 / x).toString() + "\nRepetend length is: " + (digit - m.get(remainder));
          } else {
            m.set(remainder, digit);
          }
          digit++;
          zero = false;
        }
      }
      return "Terminating decimal:\n" + (1.0 / x);
    }
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}

input {
  font-family: inherit;
  font-size: inherit;
  width: 5rem;
  height: 2rem;
  text-align: center;
}

button {
  font-family: inherit;
  font-size: inherit;
  width: 20rem;
}

textarea {
  font-family: inherit;
  font-size: inherit;
  width: 20rem;
  height: 6rem;
  border: none;
  text-align: center;
  resize: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
</style>

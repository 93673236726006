<template>
  <div id='problem'>
    <h2>XOR decryption</h2>
    <a href="https://projecteuler.net/problem=59">https://projecteuler.net/problem=59</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Frequency_analysis">Wiki: frequency analysis</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      We do a little crypto as a treat.
    </p>
    <p>
      Apparently the encrypted text was swapped out since I first did this problem, which I approve of since the original
      text was taken from the Bible. Which was a weird/uncomfortable choice — it should always have been something
      mathematically relevant.
    </p>
    <h3>Frequency attack</h3>
    <p>
      There are a few ways to go about this, but I took the classical approach of doing a frequency attack: count up the
      most common encrypted characters, match them up to the most common English characters, and substitute. In this case
      it is straightforward since we know the key is length 3. That allows us to group the encrypted text into chunks of 3
      and analyze the frequencies of the first, second, and third characters in each triple independently.
    </p>
    <p>
      Classically many encryption schemes omit the space character, though this puzzle does not. With that in mind, space
      is the first character we should check, then moving on to ETAO and friends if that fails. Kotlin has some nice
      utilities which made grouping things up to get the most common char in each position easy:
    </p>
    <highlightjs language="kotlin" :code="grouping" />
    <p>
      And from there we XOR each with the space character to get our first guess at the key. Repeat with next most common
      characters if something fails. There are more sturdy ways to attack this problem, but this is simple and works.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P059Page',
  components: {
  },
  data() {
    return {
      grouping: `val tokenizedChunks = cipher.split(",").map { it.toInt() }.chunked(3)
val mostCommonEncryptedCharPerTripleEntry = (0..2).map { idx ->
  tokenizedChunks.map { it[idx] }
    .groupingBy { it }
    .eachCount()
    .maxBy { it.value }
    .key
}`
    }
  }
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

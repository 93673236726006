<template>
  <div id='problem'>
    <h2>Path Sum: Four Ways</h2>
    <a href="https://projecteuler.net/problem=83">https://projecteuler.net/problem=83</a>
    <h3>Thoughts</h3>
    <p>
      The logical conclusion after the <a href="/projects/euler/81">prior</a> <a href="/projects/euler/82">two</a>
      problems. Everything I said about problem 82 still applies here: changing the starting condition and the neighbors
      to check is sufficient.
    </p>
    <p>
      The following visualization demonstrates the matrix traversal using that algorithm on the sample input, finding the
      minimal path sum to each entry in the matrix starting from the top-left. Again, an optimal solution would stop as
      soon as we hit the bottom-right, but this continues in order to fully demonstrate how the algorithm works.
    </p>
    <canvas id="dijkstra" width="625" height="320"></canvas>
  </div>
</template>

<script>

export default {
  name: 'P083Page',
  components: {},
  data() {
    return {
      rectDim: 50,
      padding: 5,
      animationStatus: {
        state: "initial",
        costs: [],
        minPaths: [],
        neighbors: [],
        wait: 0,
        heap: [],
        done: [],
        transitions: null,
      },
      costs: [
        [131, 673, 234, 103, 18],
        [201, 96, 342, 965, 150],
        [630, 803, 746, 422, 111],
        [537, 699, 497, 121, 956],
        [805, 732, 524, 37, 331]
      ]
    };
  },
  mounted() {
    const canvas = document.getElementById("dijkstra");
    this.reset(canvas);
    setInterval(this.draw, 750);
  },
  methods: {
    // Returns an array with two elements: an array of boxes with costs, and
    // an array of boxes with minimal paths in them.
    initCanvas: function (c) {
      var ctx = c.getContext("2d");
      ctx.clearRect(0, 0, 625, 320);
      ctx.font = "20px Courier New";
      ctx.textAlign = "center";

      ctx.strokeText("Costs", 150, 25, 100);
      ctx.strokeText("MinPathSum", 475, 25, 150);

      var x = 10;
      var y = 30;
      let costBoxes = []
      let minPathBoxes = []
      for (let row = 0; row < this.costs.length; row++) {
        costBoxes.push([]);
        minPathBoxes.push([]);
        for (let col = 0; col < this.costs[row].length; col++) {
          // Initialize cost box
          let r = new Path2D();
          let coords = { x: x + this.padding, y: y + this.padding };
          r.rect(coords.x, coords.y, this.rectDim, this.rectDim);
          ctx.stroke(r);
          let num = this.costs[row][col];
          ctx.strokeText(num.toString(), coords.x + 25, coords.y + 32, this.rectDim);
          costBoxes[row].push({ rect: r, num: num, coords: coords });

          // Initialize minPath box
          // Initialize cost box
          let mcr = new Path2D();
          let mcrCoords = { x: x + this.padding + 325, y: y + this.padding };
          mcr.rect(mcrCoords.x, mcrCoords.y, this.rectDim, this.rectDim);
          ctx.stroke(mcr);
          if (row == 0 && col == 0) {
            ctx.strokeText(num.toString(), mcrCoords.x + 25, mcrCoords.y + 32, this.rectDim);
          } else {
            ctx.strokeText("∞", mcrCoords.x + 25, mcrCoords.y + 32, this.rectDim);
          }
          minPathBoxes[row].push({ rect: mcr, num: num, coords: mcrCoords });

          x += this.rectDim + this.padding;
        }
        x = 10;
        y += this.rectDim + this.padding;
      }
      return [costBoxes, minPathBoxes];
    },
    reset: function (canvas) {
      let boxes = this.initCanvas(canvas);
      this.animationStatus = {
        state: "initial",
        costs: boxes[0],
        minPaths: boxes[1],
        neighbors: [],
        wait: 0,
        heap: [[0, 0]],
        done: [],
        transitions: new Map(),
      };
    },
    highlightMin: function (ctx, color) {
      let currMin = this.animationStatus.heap[0];
      // highlight the first prime
      let mBox = this.animationStatus.minPaths[currMin[0]][currMin[1]];
      ctx.clearRect(mBox.coords.x, mBox.coords.y, this.rectDim, this.rectDim);
      ctx.fillStyle = color;
      ctx.fill(mBox.rect);
      ctx.strokeText(mBox.num.toString(), mBox.coords.x + 25, mBox.coords.y + 32, this.rectDim);
      this.animationStatus.done.push([currMin[0], currMin[1]])
      this.animationStatus.state = "lit-min";
    },
    highlightNeighbors: function (ctx) {
      let currMin = this.animationStatus.heap[0];
      let row = currMin[0];
      let col = currMin[1];
      let neighborIndices = [
        [row, col - 1],
        [row - 1, col],
        [row + 1, col],
        [row, col + 1],
      ];
      for (const nind of neighborIndices) {
        if (this.arrayContainsArray(this.animationStatus.done, nind) ||
          nind[0] < 0 ||
          nind[0] >= this.costs.length ||
          nind[1] >= this.costs.length ||
          nind[1] < 0) {
          continue;
        }
        let mBox = this.animationStatus.minPaths[nind[0]][nind[1]];
        ctx.clearRect(mBox.coords.x, mBox.coords.y, this.rectDim, this.rectDim);
        ctx.fillStyle = 'salmon';
        ctx.fill(mBox.rect);
        if (mBox.num == this.animationStatus.costs[nind[0]][nind[1]].num) {
          ctx.strokeText("∞", mBox.coords.x + 25, mBox.coords.y + 32, this.rectDim);
        } else {
          ctx.strokeText(mBox.num.toString(), mBox.coords.x + 25, mBox.coords.y + 32, this.rectDim);
        }
        this.animationStatus.neighbors.push(nind);
      }
      this.animationStatus.state = "lit-neighbors";
    },
    highlightNeighborCost: function (ctx) {
      if (this.animationStatus.neighbors.length == 0) {
        this.highlightMin(ctx, "green");
        this.animationStatus.done.push(this.animationStatus.heap.shift());
        this.animationStatus.heap.sort((a, b) => this.animationStatus.minPaths[a[0]][a[1]].num - this.animationStatus.minPaths[b[0]][b[1]].num);
        if (this.animationStatus.heap.length == 0) {
          this.animationStatus.state = "full";
        } else {
          this.animationStatus.state = "initial";
        }
      } else {
        let nind = this.animationStatus.neighbors[0];
        let costBox = this.animationStatus.costs[nind[0]][nind[1]];
        ctx.clearRect(costBox.coords.x, costBox.coords.y, this.rectDim, this.rectDim);
        ctx.fillStyle = 'salmon';
        ctx.fill(costBox.rect);
        ctx.strokeText(costBox.num.toString(), costBox.coords.x + 25, costBox.coords.y + 32, this.rectDim);
        this.animationStatus.state = "lit-cost";
      }
    },
    updateNeighborMPS: function (ctx) {
      let nind = this.animationStatus.neighbors.shift();
      let costBox = this.animationStatus.costs[nind[0]][nind[1]];
      ctx.clearRect(costBox.coords.x, costBox.coords.y, this.rectDim, this.rectDim);
      ctx.stroke(costBox.rect);
      ctx.strokeText(costBox.num.toString(), costBox.coords.x + 25, costBox.coords.y + 32, this.rectDim);
      let mpsBox = this.animationStatus.minPaths[nind[0]][nind[1]];
      ctx.clearRect(mpsBox.coords.x, mpsBox.coords.y, this.rectDim, this.rectDim);
      ctx.fillStyle = "salmon";
      ctx.fill(mpsBox.rect);
      let currMin = this.animationStatus.heap[0];
      if (mpsBox.num == costBox.num || mpsBox.num > costBox.num + this.animationStatus.minPaths[currMin[0]][currMin[1]].num) {
        mpsBox.num = costBox.num + this.animationStatus.minPaths[currMin[0]][currMin[1]].num;
        this.animationStatus.minPaths[nind[0]][nind[1]] = mpsBox;
        this.animationStatus.transitions[JSON.stringify(nind)] = JSON.stringify(currMin);
      }
      ctx.strokeText(mpsBox.num.toString(), mpsBox.coords.x + 25, mpsBox.coords.y + 32, this.rectDim);
      if (!this.arrayContainsArray(this.animationStatus.heap, nind)) {
        this.animationStatus.heap.push(nind);
      }
      this.animationStatus.state = "lit-neighbors";
    },
    highlightBestPath: function (ctx) {
      let ids = [4, 4];
      let route = [ids];
      while (this.animationStatus.transitions[JSON.stringify(ids)] != null) {
        ids = JSON.parse(this.animationStatus.transitions[JSON.stringify(ids)]);
        route.push(ids);
      }
      for (let row = 0; row < 5; row++) {
        for (let col = 0; col < 5; col++) {
          if (this.arrayContainsArray(route, [row, col])) {
            let costBox = this.animationStatus.costs[row][col];
            ctx.clearRect(costBox.coords.x, costBox.coords.y, this.rectDim, this.rectDim);
            ctx.fillStyle = "salmon";
            ctx.fill(costBox.rect);
            ctx.strokeText(costBox.num.toString(), costBox.coords.x + 25, costBox.coords.y + 32, this.rectDim);
          } else {
            let minPathBox = this.animationStatus.minPaths[row][col];
            ctx.clearRect(minPathBox.coords.x, minPathBox.coords.y, this.rectDim, this.rectDim);
            ctx.stroke(minPathBox.rect);
            ctx.strokeText(minPathBox.num.toString(), minPathBox.coords.x + 25, minPathBox.coords.y + 32, this.rectDim);
          }
        }
      }
      this.animationStatus.state = "done";
    },
    waitAndReset: function (canvas) {
      if (this.animationStatus.wait < 10) {
        this.animationStatus.wait++;
      } else {
        this.reset(canvas);
      }
    },
    draw: function () {
      const canvas = document.getElementById("dijkstra");
      let ctx = canvas.getContext("2d");
      switch (this.animationStatus.state) {
        case "initial":
          this.highlightMin(ctx, "aqua");
          break;
        case "lit-min":
          this.highlightNeighbors(ctx);
          break;
        case "lit-neighbors":
          this.highlightNeighborCost(ctx);
          break;
        case "lit-cost":
          this.updateNeighborMPS(ctx);
          break;
        case "full":
          this.highlightBestPath(ctx);
          break;
        default:
          this.waitAndReset(canvas);
          break;
      }
    },
    arrayContainsArray: function (xs, a) {
      for (const x of xs) {
        if (x.length === a.length && x.every((val, index) => val === a[index])) return true;
      }
      return false;
    }
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}

canvas {
  border: 1px solid #000000;
}
</style>

<template>
  <div id='sordid-history'>
    <h3>Current Work</h3>
    <h4 class='labor'>Duolingo</h4>
    <p class='deets'>
      Nothing I can publicly talk about yet.
    </p>
    <h3>Prior Work</h3>
    <h4 class='labor'>Google Maps</h4>
    <p class='deets'>
      Categorizing businesses within Google Maps. Mostly worked on ML model development, in particular building a 
      <a href="https://en.wikipedia.org/wiki/Generalized_additive_model">GAM</a> for multi-class classification.
      Modeling work was supplemented with heuristic-driven cleanups and coordinating manual review of high-priority
      businesses (think healthcare and political entities).
    </p>
    <h4 class='labor'>Cloud Code for IntelliJ</h4>
    <p class='deets'>
      <strike>Open source</strike> tools for K8s and Google Cloud Platform in the IDE. Most of my work revolved around
      the cluster management workflow, IntelliJ UI (please don't ask me to write more Swing), testing, and dependency
      management.
    </p>
    <h4 class='labor'>Google Supply Chain</h4>
    <p class='deets'>
      Tracking and retiring assets in Google datacenters. Developed pipelines of Map-Reduce jobs to collect records of
      which parts are reporting from datacenters, then reconciled them with financial records. Over time took on a greater
      role in project management, working closely with accounting and legal teams to ensure SOX compliance.
    </p>
    <h4 class='labor'>AWS Payments</h4>
    <p class='deets'>
      Payment instrument management, payment execution, and payment processing for AWS accounts. Developed additional
      tools enabling customer service to execute one-off transactions and split payments for assisting customers with
      particular payment
      requirements.
    </p>
    <h4 class='labor'>Amazon Supply Chain — Simulation</h4>
    <p class='deets'>
      Intern work. Adding forecasting parameters and print-on-demand to warehouse simulations. I'm pretty sure this got
      deleted within a year of my internship ending, as with many Amazon intern projects.
    </p>
    <h3>Tech stuff</h3>
    <h4 class='labor'>Languages</h4>
    <p class='deets'>
      Primary languages: Kotlin, Java, C++
    </p>
    <p class='deets'>
      Some experience: Python, Ruby, Haskell, SML;NJ, JS
    </p>
    <h4 class='labor'>Cloud experience</h4>
    <p class='deets'>AWS + GCP</p>
    <h3>Hobbies</h3>
    <br>
    <p class='deets'>
      Baking (RIP my sourdough starter), reading (mostly spec fic), math, climbing (primarily bouldering), soccer,
      running, video games.
    </p>
    <br>
    <p class='deets'>
      I'm also interested in education. My dad was a teacher, and I've previously taught both math (to the SC ARML
      team) and CS to high schoolers (<a href="https://www.microsoft.com/en-us/teals">TEALS</a>).
    </p>
  </div>
</template>

<script>

export default {
  name: 'ResumePage',
  components: {
  },
}
</script>

<style scoped>
div#sordid-history {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}

h4.labor {
  margin-top: 1.5rem;
  color: dimgray;
}

p.deets {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  color: dimgray;
  text-align: center;
}
</style>

<template>
  <div id='problem'>
    <h2>Pandigital Prime</h2>
    <a href="https://projecteuler.net/problem=41">https://projecteuler.net/problem=41</a>
    <h3>Thoughts</h3>
    <p>
      Whether we start from the pandigitals or use a sieve to get the primes, this one is pretty straightforward. The only
      interesting thing to do is eliminate ranges we need to check.
    </p>
    <p>
      Let's consider what happens if n is a nine-digit number. Then the sum of the digits is 45, which is divisible by 3,
      so it is not prime. Similarly, if n is an eight-digit number the digit sum is 36 and we fail again. So right off the
      bat the highest possible number is 7654321. Knowing this eliminates most of the range and means we can use a sieve
      if we want to quickly get all the primes up to that threshold.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P041Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

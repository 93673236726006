<template>
  <div id='problem'>
    <h2>Odd Period Square Roots</h2>
    <a href="https://projecteuler.net/problem=64">https://projecteuler.net/problem=64</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Methods_of_computing_square_roots#Continued_fraction_expansion">Wiki:
          methods of computing square roots</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      There's something to be said for a problem that tells you exactly how to solve it.
    </p>
    <p>
      The explanation here is intimidating compared to the brevity of all the problems before, and hides that this is
      actually a pretty easy problem. The provided summary of the procedure doesn't help with the illusion of complexity
      — I had to trace through it a few times before understanding what's happening. The more verbose explanation in the
      wiki above is a little better, though it is worth puzzling through the explanation in the problem description to see
      why it works.
    </p>
    <p>
      Once the method to compute the continued expansion is done, this problem is very mechanical. We could be smart and
      eliminate some values as potential candidates, but given how low the limit is here the benefit is minimal.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P064Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

<template>
  <div id='problem'>
    <h2>Summation of Primes</h2>
    <a href="https://projecteuler.net/problem=10">https://projecteuler.net/problem=10</a>
    <h3>Thoughts</h3>
    <p>
      Oh look it's the perfect case for a prime sieve.
    </p>
    <h3>Sieving</h3>
    <p>
      We already discussed this in <a href="/projects/euler/7">the writeup for problem 7</a>, so I won't repeat that.
    </p>
    <h3>Lucy_Hedgehog solution</h3>
    <p>
      I won't copy it here, but once you finish this problem <em>PLEASE</em> go read the solution by user Lucy_Hedgehog in
      the discussion thread. It is extremely insightful, and a very clever application of dynamic programming to come up
      with a sub-linear (!!!) algorithm for solving this. Strongly recommended reading. As an aside, double-click the
      hearts in the top-right of the discussion thread to sort by most kudos. Quite handy.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P010Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}

table {
  text-align: center;
  table-layout: fixed;
  border: 1px solid;
  border-collapse: collapse;
  margin-bottom: 3rem;
}

.skinny {
  width: 5rem;
}

.wide {
  width: 15rem;
}

th,
td {
  border: 1px solid;
}
</style>

<template>
  <div id='problem'>
    <h2>Cuboid Route</h2>
    <a href="https://projecteuler.net/problem=86">https://projecteuler.net/problem=86</a>
    <h3>Unfolding the cuboid</h3>
    <p>
      First things first: what is the shortest route around a cuboid? Let's say the sides have lengths <katex-span
        expr="x \leq y \leq z" />. Any path connecting diagonally opposite points must traverse two sides of the cuboid,
      so to visualize the route the spider takes we can "unfold" the cuboid and see that the spider is traversing the
      hypotenuse of a right triangle:
    </p>
    <img id="cuboid" src="@/assets/unfolded_cuboid.png"
      alt="Two rectangles patched together showing the spider's route on a cuboid." />
    <p>
      So the shortest route is this hypotenuse, but which sides do we need to add together to make one leg of the
      triangle? In the diagram above, since one leg has length <katex-span expr="z" /> and the other has length
      <katex-span expr="x + y" /> the hypotenuse has length:
    </p>
    <katex-div
      expr="\sqrt{\vphantom{X^{2^2}} \smash{(x+y)^2 + z^2}} = \sqrt{\smash{x^2 + 2xy + y^2 + z^2} \vphantom{X^{2^2}}}" />
    <p>
      If we swap <katex-span expr="x + y" /> to either <katex-span expr="x + z" /> or <katex-span expr="y + z" />, the
      second square root will be the same except for replacing <katex-span expr="2xy" /> with <katex-span expr="2xz" />
      and <katex-span expr="2yz" /> respectively. Clearly the first of these is the least since we've defined <katex-span
        expr="z" /> to be the longest side, therefore the arrangement in the diagram depicts the shortest possible path.
    </p>
    <h3>Counting Pythagorean triangles</h3>
    <p>
      Once we know that we're looking for Pythagorean triples, we have to figure out how to tally them up. There are a
      couple of ways of going about this.
    </p>
    <h4>Pure brute force</h4>
    <p>
      The most obvious approach is to iterate over all triples <katex-span expr="(x, y, z)" /> up to <katex-span
        expr="M" /> and count up the ways that work. This algorithm is <katex-span expr="O(M^3)" /> so it won't scale very
      well, but it is viable so long as the answer is small.
    </p>
    <highlightjs :code="brute" />
    <h4>Clever brute force</h4>
    <p>
      Rather than checking every possible <katex-span expr="(x, y, z)" />, we can take advantage of the fact that we're
      looking for Pythagorean triples and instead define <katex-span expr="a = x+y" /> then look for pairs <katex-span
        expr="(a, z)" /> via brute force. But to do this, we need to be able to count the number of valid <katex-span
        expr="(x, y)" /> pairs that we can combine to make <katex-span expr="a" />.
    </p>
    <p>
      Since we've defined <katex-span expr="z" /> to be the longest side, we have two cases to consider: whether
      <katex-span expr="a \leq z" /> or <katex-span expr="a > z" />. When <katex-span expr="a \leq z" /> it is trivially
      true that <katex-span expr="z" /> is the longest side, so we can split <katex-span expr="a" /> like so:
    </p>
    <katex-div expr="[1, a-1], [2, a-2], ..., [floor\Big(\frac{a}{2}\Big), ceil\Big(\frac{a}{2}\Big)]" />
    <p>
      For a total of <katex-span expr="floor\Big(\frac{a}{2}\Big)" /> pairs of <katex-span expr="(x,y)" />. However, when
      <katex-span expr="a > z" /> we need to ensure that <katex-span expr="z" /> remains the longest side, so our pairs
      become:
    </p>
    <katex-div expr="[a-z, z], [a-z + 1, z-1], ..., [floor\Big(\frac{a}{2}\Big), ceil\Big(\frac{a}{2}\Big)]" />
    <p>
      Which gives us <katex-span expr="floor\Big(\frac{a}{2}\Big) - (a-z-1)" /> pairs of <katex-span expr="(x,y)" />. So
      in either case once we've found a Pythagorean triple with legs <katex-span expr="(a, z)" /> it is <katex-span
        expr="O(1)" /> to count the cuboids that can be made forming those legs, which brings us down to an <katex-span
        expr="O(M^2)" /> algorithm.
    </p>
    <highlightjs :code="brute2" />
    <p>
      While the code stub above is two for-loops, it may be easier to write this as a for-loop wrapped by a while loop to
      allow incremental checking if we've reached the solution and can stop. Depends how you feel about break statements.
    </p>
    <h4>Iterate over Pythagorean Triples</h4>
    <p>
      Rather than going with brute force and checking for valid triples, we could instead generate all the primitive
      Pythagorean triangles, loop over them and their multiples, and perform the same calculation described above to
      count the valid cuboids. We've already discussed how to generate the triples in a couple of ways in prior problems
      (like <a href="/projects/euler/39">#39</a> or <a href="/projects/euler/75">#75</a>), so we can re-use that logic.
    </p>
    <p>
      This is the fastest way, but there is a bit of complexity here. Consider the Pythagorean triple <katex-span
        expr="(5, 12, 13)" />. When <katex-span expr="M=10" />, we can define <katex-span expr="a=12" /> but cannot have
      <katex-span expr="a=5" /> since that would make <katex-span expr="z=12 > M" />. And furthermore, when <katex-span
        expr="M=6" /> we can still use <katex-span expr="a=12" />, but we cannot count the triple <katex-span
        expr="(x, y, z) = (5, 5, 7)" /> in our tally. It's possible to work around this, but correctly tallying up the
      valid cuboid triples does get finicky. Feel free to go down this route if you want the most efficient solution, but
      the clever version of the brute force solution is sufficient for this problem.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P086Page',
  components: {},
  data() {
    return {
      brute: `for (z in 1..m) for (y in 1..z) for (x in 1..y) { /* logic */ }`,
      brute2: `for (z in 2..m) for (a in 2..2*z) if (arePythagoreanLegs(z, a)) {
  if (a <= z) { /* case 1 */ }
  else { /* case 2 */}
}`
    }
  }
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

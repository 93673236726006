<template>
  <div id='problem'>
    <h2>Largest Product in a Grid</h2>
    <a href="https://projecteuler.net/problem=11">https://projecteuler.net/problem=11</a>
    <h3>Thoughts</h3>
    <p>
      I'm not seeing a better solution than brute force. There are clever ways to manage the left/right, up/down, and
      diagonal cases, but there really aren't any special insights required for this one. Moving on.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P011Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}

</style>

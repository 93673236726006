<template>
  <div id='problem'>
    <h2>Permuted Multiples</h2>
    <a href="https://projecteuler.net/problem=52">https://projecteuler.net/problem=52</a>
    <h3>Thoughts</h3>
    <p>
      Another one where the math is minimal... unless you've looked at the repeated decimals of a particular common
      fraction and have already memorized the answer accidentally. I was in this camp, and thus lucked into a no-code
      answer.
    </p>
    <h3>So where do we look?</h3>
    <p>
      If you haven't got the answer memorized already, then it is worth taking a moment to think of what possible answers
      could be. It should be clear immediately that the first digit of the answer must be a 1. If x were to start with a
      digit 2 or greater, then 6x would have an extra digit compared to x, and therefore cannot be a permutation.
    </p>
    <p>
      And since x starts with 1, we can see immediately that each of x, 2x, 3x, 4x, 5x, and 6x must start with different
      digits, so x is comprised of at least 6 distinct digits. Thus x must be at least 6 digits long. So for our solution
      we should start at 123456, run through <katex-span expr="\Big\lfloor \frac{1000000}{6} \Big\rfloor" />, and skip
      every number where the digits aren't unique. If the answer isn't found in that range, we skip to the 7 digit numbers
      and apply similar logic — though we can allow at most one duplicate there — and repeat as needed for 8 digits and
      onwards.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P052Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

<template>
  <div id='problem'>
    <h2>Largest Product in a Series</h2>
    <a href="https://projecteuler.net/problem=9">https://projecteuler.net/problem=9</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Pythagorean_triple">Wiki: Pythagorean triple</a></li>
      <li><a href="https://en.wikipedia.org/wiki/Formulas_for_generating_Pythagorean_triples">Wiki: formulas for
          generating Pythagorean triples</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      The first time I did this I went for the standard brute force approach. Only after reading the overview doc that is
      revealed when you finish the problem did I learn about how one could generate Pythagorean triples. That info is
      crucial for other problems and enables a non-programming solution to this problem, so we'll discuss that approach.
      Honestly, you should just read through the Wiki pages linked above — they are both accessible and information rich.
    </p>
    <h3>Euclid's formula</h3>
    <p>
      Euclid's formula allows us to generate Pythagorean triples from any pair of different positive integers m and n:
    </p>
    <katex-div expr="a = m^2 - n^2, b = 2mn, c = m^2 + n^2" />
    <p>
      Every primitive Pythagorean triple can be generated by substituting different values for m and n into those
      equations , though not every m and n will yield a primitive triple. This forms the foundation of most methods for
      generating all triples. In our case though, we don't need to exploit the generation. Since we know what we want a,
      b, and c to total, we can exploit this formula to directly identify the answer.
    </p>
    <katex-div expr="a + b + c = 1000, a = m^2 - n^2, b = 2mn, c = m^2 + n^2" />
    <br>
    <katex-div expr="\implies (m^2 - n^2) + 2mn + m^2 + n^2 = 1000" />
    <br>
    <katex-div expr="\implies 2m^2 + 2mn = 1000" />
    <br>
    <katex-div expr="\implies m(m + n) = 500" />
    <p>
      And since we know that <katex-span expr="m > n" />, we just need to consider the factors of 500 (1, 2, 4, 5, 10,
      <em>20</em>, <em>25</em>, 50, 100, 125, 250, 500) to get our answer.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P009Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}

table {
  text-align: center;
  table-layout: fixed;
  border: 1px solid;
  border-collapse: collapse;
  margin-bottom: 3rem;
}

.skinny {
  width: 5rem;
}

.wide {
  width: 15rem;
}

th,
td {
  border: 1px solid;
}
</style>

import { createApp, h } from 'vue'
import App from './App.vue'
// Check theme options at https://highlightjs.org/static/demo/.
import 'highlight.js/styles/github-dark-dimmed.css'
import hljs from 'highlight.js/lib/core';
import kotlin from 'highlight.js/lib/languages/kotlin';
import hljsVuePlugin from "@highlightjs/vue-plugin";
import katex from 'katex/dist/katex.js'
// import katex from 'katex/dist/katex.min.css'
import router from './router'

hljs.registerLanguage('kotlin', kotlin)

let app = createApp(App)

app.component('katex-span', {
  props: ['expr'],
  computed: { math() { return katex.renderToString(this.expr, {output: "mathml"}); }, },
  render() { return h('span', {innerHTML: this.math}); },
});

app.component('katex-div', {
  props: ['expr'],
  computed: { math() { return katex.renderToString(this.expr, {output: "mathml"}); }, },
  render() { return h('div', {innerHTML: this.math}); },
});

app.use(hljsVuePlugin).use(router).mount('#app')
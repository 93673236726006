<template>
  <div id='problem'>
    <h2>Cubic Permutations</h2>
    <a href="https://projecteuler.net/problem=62">https://projecteuler.net/problem=62</a>
    <h3>Thoughts</h3>
    <p>
      Going from number to permutations and checking if any permutations are cubes seems like a massive hassle. Much
      easier is to calculate the primes sequentially, then convert the cubes into strings, sort the characters, and stick
      those in a map. That way we can track the different permutations and we only have to check each cube once. Only
      thing to remember is to return the SMALLEST cube in the set of permutations.
    </p>
    <p>
      It's possible that this would lead to a false solution if there were a case of a cube with 6+ permutations before
      the first one with exactly 5, but this seems pretty unlikely. At any rate, if this is a concern we can just iterate
      past the first solution we find until we check all cubes with that many digits to be sure it is valid.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P062Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

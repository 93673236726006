<template>
  <div id='problem'>
    <h2>Multiples of 3 or 5</h2>
    <a href="https://projecteuler.net/problem=1">https://projecteuler.net/problem=1</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Triangular_number">Wiki: Triangular numbers</a></li>
      <li><a href="https://en.wikipedia.org/wiki/Inclusion%E2%80%93exclusion_principle">
          Wiki: Inclusion-exclusion principle
        </a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      Oh look, it's fizz buzz.
    </p>
    <p>
      The first problem is, unsurprisingly, the most straightforward. There is an obvious solution
      where one loops through the numbers 1 to 100 and adds up the relevant ones, and a slightly
      less obvious solution using sum of terms and the Principle of Inclusion and Exclusion.
    </p>
    <h3>Naive Solution</h3>
    <p>
      We want the sum of natural numbers below 1000 which are divisible by 3 or 5. 1000 is a tiny
      number, so we can easily compute this directly by looping and keeping a running total.
    </p>
    <highlightjs language="kotlin" :code="solution" />
    <h4>Performance</h4>
    <p>
      Since we loop over each number in range once, this approach runs in <katex-span expr="O(N)" />
      time. Storage is also only <katex-span expr="O(1)" />. Not shabby at all, though we can do better.
    </p>
    <h3>Direct calculation</h3>
    <p>
      Rather than looping over everything, one may note that we can instead sum the numbers
      directly. In our case, we want the sum of the multiples of 3:
    </p>
    <katex-div expr="3 + 6 + 9 + ... + 999" />
    <p>
      And the sum of the multiples of 5:
    </p>
    <katex-div expr="5 + 10 + 15 + ... + 995" />
    <p>
      So how can we sum these quickly? It should be clear that we can factor out the common
      multiples (three and five respectively) to get a simpler sequence:
    </p>
    <katex-div expr="3 + 6 + 9 + ... + 999 = 3 * (1 + 2 + 3 + ... + 333)" />
    <p>
      So our problem reduces to finding the sum of consecutive numbers. This summation is extremely
      common, and famously leads to the Triangular numbers when starting from 1. The abbreviated
      result is:
    </p>
    <katex-div expr='1 + 2 + 3 + ... + n = \displaystyle\sum_{i=1}^n i = \frac{n * (n+1)}{2}' />
    <p>
      Which can be proved trivially — consider: how many numbers are there? what if we paired the
      first and last? This is also a great chance to practice proof by induction.
    </p>
    <p>Applying this formula to compute the first sum yields:</p>
    <katex-div expr='3 * \frac{333 * (333+1)}{2} = 3 * 333 * 167 = 999 * 167 = 166833' />
    <p>
      However, if we just do this for 3 and 5 we'll double count numbers which appear in both
      sequences — so any numbers which are multiples of <katex-span expr="3*5=15" /> are counted
      twice!
    </p>
    <p>
      So what we need is a way to remove the shared elements. We can quickly do so by repeating our
      approach to summing multiples of 3 and 5 for 15, then subtracting this sum from the total!
    </p>
    <p>
      Stepping back, what we really want is the union of two sets (multiples of 3 and 5):
    </p>
    <katex-div expr="A \coloneqq \lbrace x \in \N, x < 1000 : x \mod 3 \equiv 0 \rbrace" />
    <katex-div expr="B \coloneqq \lbrace x \in \N, x < 1000 : x \mod 5 \equiv 0 \rbrace" />
    <p>
      In adding these sets together, we included the intersection (multiples of 15) twice:
    </p>
    <katex-div expr="A \bigcap B \coloneqq \lbrace x \in \N, x < 1000 : x \mod 15 \equiv 0 \rbrace" />
    <p>
      So to get the proper union from the sets <katex-span expr="A" /> and <katex-span expr="B" />,
      we add them together and remove the intersection. This is exactly the 2-set case for the
      inclusion-exclusion principle (or Principle of Inclusion-Exclusion, abbreviated as PIE).
      Formally:
    </p>
    <katex-div expr="A \bigcup B = A + B - A \bigcap B" />
    <p>
      This is an extremely useful result, which generalizes nicely to other set problems. Many
      harder PE problems require less obvious applications of this idea.
    </p>
    <h4>Performance</h4>
    <p>
      <katex-span expr="O(1)" /> time and <katex-span expr="O(1)" /> space. It doesn't get better
      than that.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P001Page',
  components: {
  },
  data() {
    return {
      solution: `// Iterative approach:
var s = 0
for (i in 1 until 1000) {
  // Note: OR since either condition is sufficient.
  if (i % 3 == 0 || i % 5 == 0) {
    s += i
  }
}
// Functional approach:
(1..999).filter { it % 3 == 0 || it % 5 == 0}.sum()`
    }
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}

</style>

<template>
  <div id='problem'>
    <h2>Convergents of <katex-span expr="e" /></h2>
    <a href="https://projecteuler.net/problem=65">https://projecteuler.net/problem=65</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Continued_fraction#Infinite_continued_fractions_and_convergents">Wiki:
          continued fraction — infinite continued fractions and convergents</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      Flashback to <a href="/projects/euler/57">problem 57</a>. As with that one we can take advantage of the recursive
      process for calculating the convergents. That is, when we define the successive numerators as <katex-span
        expr="n_k" />, denominators as <katex-span expr="d_k" />, and the terms in the continued fraction as <katex-span
        expr="e_k" />, the following relationships hold (see wiki link above):
    </p>
    <katex-div expr="n_{k+1} = e_{k+1} * n_k + n_{k-1}" />
    <katex-div expr="d_{k+1} = e_{k+1} * d_k + d_{k-1}" />
    <p>
      And again we don't care about the denominators, so we only need the first of those. The <katex-span expr="e_k" />
      are well-defined after the first one:
    </p>
    <katex-div expr="e_k =
\begin{cases}
  \frac{2*k}{3} &\quad\text{if } k \equiv 0 \mod 3\\
  1             &\quad\text{if } k \not \equiv 0 \mod 3 \\
\end{cases}" />
    <p>
      So the implementation for this problem is quite compact. Do note that the values will scale rapidly, so we will need
      to use big integers or some equivalent.
    </p>
    <h3>Direct calculation</h3>
    <p>
      The convergent approach above is straightforward and provides the nice benefit of giving us all the intermediate
      convergents on problems where we need that (like in #57). However, since we know the <katex-span expr="e_k" /> we
      can also directly calculate the value using them by working from bottom to top. Let's walk through the example of
      the 6th convergent.
    </p>
    <katex-div expr="2 + \cfrac{1}{1 + \cfrac{1}{2 + \cfrac{1}{1 + \cfrac{1}{1 + \cfrac{1}{4}}}}}" />
    <p>
      We work our way from <katex-span expr="\frac{1}{4}" /> on up:
    </p>
    <katex-div
      expr="= 2 + \cfrac{1}{1 + \cfrac{1}{2 + \cfrac{1}{1 + \cfrac{1}{\frac{5}{4}}}}} = 2 + \cfrac{1}{1 + \cfrac{1}{2 + \cfrac{1}{1 + \frac{4}{5}}}}" />
    <katex-div
      expr="= 2 + \cfrac{1}{1 + \cfrac{1}{2 + \cfrac{1}{\frac{9}{5}}}} = 2 + \cfrac{1}{1 + \cfrac{1}{2 + \frac{5}{9}}}" />
    <katex-div expr="= 2 + \cfrac{1}{1 + \cfrac{1}{\frac{23}{9}}} = 2 + \cfrac{1}{1 + \frac{9}{23}}" />
    <katex-div expr="= 2 + \cfrac{1}{\frac{32}{23}} = 2 + \frac{23}{32} = \frac{64+23}{32} = \frac{87}{32}" />
    <p>
      While this only allows us to calculate the <katex-span expr="n^{th}" /> term, it is convenient in that we only need
      to keep track of the numerator and denominator of the "bottom" fraction throughout. Ultimately this winds up being
      just as efficient for this problem as using the recurrence — both require <katex-span expr="O(n)" /> additions and
      multiplications, while needing constant memory.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P065Page',
  components: {
  },
  data() {
    return {
      multiline: ``
    }
  }
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

<template>
  <div id='problem'>
    <h2>Digit Fifth Powers</h2>
    <a href="https://projecteuler.net/problem=30">https://projecteuler.net/problem=30</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Narcissistic_number">Wiki: narcissistic number</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      "Find the sum of all" implies that there is a finite set of numbers satisfying the condition, so we should start by
      figuring out what the range of possible solutions is.
    </p>
    <h3>Restricting the answer range</h3>
    <p>
      It should be obvious that for a k-digit number, the max sum of digit fifth powers occurs when all the digits are 9s.
      We can use that knowledge to determine an upper bound.
    </p>
    <table>
      <tr>
        <th># digits</th>
        <th>max number</th>
        <th>sum of fifth powers</th>
      </tr>
      <tr>
        <td><katex-span expr="2" /></td>
        <td><katex-span expr="99" /></td>
        <td><katex-span expr="118098" /></td>
      </tr>
      <tr>
        <td><katex-span expr="3" /></td>
        <td><katex-span expr="999" /></td>
        <td><katex-span expr="177147" /></td>
      </tr>
      <tr>
        <td><katex-span expr="4" /></td>
        <td><katex-span expr="9999" /></td>
        <td><katex-span expr="236196" /></td>
      </tr>
      <tr>
        <td><katex-span expr="5" /></td>
        <td><katex-span expr="99999" /></td>
        <td><katex-span expr="295245" /></td>
      </tr>
      <tr>
        <td><katex-span expr="6" /></td>
        <td><katex-span expr="999999" /></td>
        <td><katex-span expr="354294" /></td>
      </tr>
    </table>
    <p>
      Since six 9s sum to a smaller total than 999999, we are therefore sure we don't need to check any numbers larger
      than 354294 (which really means 299999 since we're maximizing 9s).
    </p>
    <p>
      Since 299999 is a pretty small number we can proceed with brute force from here. Other numbers can be eliminated
      with some analysis (since we require certain digits to be large/small for the fifth digit sums to fall within
      certain ranges), but there is little benefit to doing so given the range restriction we already have.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P030Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}

table {
  text-align: center;
  table-layout: fixed;
  /* width: 30%; */
  border: 1px solid;
  border-collapse: collapse;
  margin-bottom: 3rem;
}

th,
td {
  border: 1px solid;
}
</style>

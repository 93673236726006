<template>
  <div id='problem'>
    <h2>Counting Fractions</h2>
    <a href="https://projecteuler.net/problem=72">https://projecteuler.net/problem=72</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Euler%27s_totient_function">Wiki: Euler's totient function</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      Continuing on the theme from <a href="/projects/euler/71">problem 71</a> we now have to count the total number of
      fractions. Ideally we don't want to check every possible pair of numerator and denominator, so we need to be a
      little more efficient here.
    </p>
    <h3>Reduced Fractions</h3>
    <p>
      We want to find every fraction which is already in reduced form. If a fraction is not in reduced form, then reducing
      it would yield a fraction present elsewhere in our set (since doing so will yield a smaller numerator and
      denominator). A fraction is in reduced form once all common factors have been removed from the numerator and
      denominator. Then that means that fractions are only in reduced form when the numerator and denominator are
      relatively prime!
    </p>
    <p>
      So the problem reduces to finding the number of numerators relatively prime to each denominator. This is exactly the
      totient function we've seen in the last few problems! And as discussed in <a href="/projects/euler/70">problem
        70</a>, we can efficiently find totients for the numbers in a range using a modified sieve approach like so:
    </p>
    <highlightjs :code="sieve" />
  </div>
</template>

<script>

export default {
  name: 'P072Page',
  components: {
  },
  data() {
    return {
      sieve: `val totes = Array(1_000_001) { it }
for (i in 2 until totes.size) {
  if (totes[i] == i) {
    for (j in i until totes.size step i) {
      totes[j] = totes[j] / i * (i-1)
    }
  }
}`
    }
  }
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

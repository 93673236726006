<template>
  <div id='problem'>
    <h2>Su Doku</h2>
    <a href="https://projecteuler.net/problem=96">https://projecteuler.net/problem=96</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Sudoku">Wiki: Sudoku</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      One of the most popular puzzles in the world. Turns out that a recursive backtracking approach is sufficient for
      solving all of the input puzzles, so once we figure out how to parse them there isn't much else to do here. I'm not
      an expert on Sudoku and there are better resources for solving them programmatically out there, so on we go.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P096Page',
  components: {},
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

<template>
  <div id='problem'>
    <h2>Distinct Prime Factors</h2>
    <a href="https://projecteuler.net/problem=47">https://projecteuler.net/problem=47</a>
    <h3>Thoughts</h3>
    <p>
      Nice problem! I think this is the first example of twisting the sieving algorithm to serve another purpose. This can
      be brute forced, but the sieve solution is more interesting.
    </p>
    <h3>Modified sieve</h3>
    <p>
      The main idea when using the sieve of Eratosthenes is that when we find a prime, we "eliminate" all of its
      multiples. But if we take a step back, what we're really doing during the elimination step is marking off all the
      numbers which are multiples of the prime — meaning that the primes is a factor. So what if instead of marking them
      off, we record other information?
    </p>
    <p>
      We can use such a modified approach to identify every multiple of each prime, thereby building a list of the
      distinct prime factors of each number. So instead of storing booleans in our sieve, we can store the prime factors
      as lists or sets of integers. This immediately solves the problem at hand, and allows us to get prime factorizations
      for a swath of numbers as rapidly as we possibly can.
    </p>
    <highlightjs language="kotlin" :code="factors" />
  </div>
</template>

<script>

export default {
  name: 'P047Page',
  components: {
  },
  data() {
    return {
      factors: `// Sieve to get all distinct prime factors of numbers < limit
val sieve = arrayListOf<MutableList<Int>>(limit)
for (i in sieve.indices) { sieve[i] = mutableListOf<Int>()}
for (i in 2 until limit) {
  if (sieve[i].empty) {
    for (j in i until limit step i) {
      sieve[j].add(i)
    }
  }
}
`
    }
  }
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>

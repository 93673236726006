<template>
  <div id='problem'>
    <h2>Champernowne's Constant</h2>
    <a href="https://projecteuler.net/problem=40">https://projecteuler.net/problem=40</a>
    <h3>Resources</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Champernowne_constant">Wiki: Champernowne constant</a></li>
    </ul>
    <h3>Thoughts</h3>
    <p>
      Honestly a bit easier to do by hand. 1M digits isn't that much, so you could construct the sting directly and index
      into it if you wish. This is also a decent one to do by hand — we can easily calculate the length of a block of
      numbers in the range <katex-span expr="[10^k, 10^{k+1})" />, and use that to jump ahead by chunks of <katex-span
        expr="k * 9 * 10^{k}" />. Whenever a block would include the next digit we want, we can quickly find what number
      in the sequence contains the digit since all the numbers in that block have length k. Taking that approach means we
      can avoid explicitly iterating through the numbers or cramming them in a string.
    </p>
  </div>
</template>

<script>

export default {
  name: 'P040Page',
  components: {
  },
}

</script>

<style scoped>
div#problem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  text-align: left;
  font-size: 1.5rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25rem;
  max-width: 90ch;
}
</style>
